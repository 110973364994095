import { SaleAddress } from '@property-folders/contract';
import { addIfNotEmpty } from './addIfNotEmpty';
import { toTitleCase } from './toTitleCase';

export function stringifySaleAddress(sp?: Pick<SaleAddress, 'streetAddr_parts'>) {
  if (!sp) {
    return '';
  }

  let addressString = '';
  const address = sp.streetAddr_parts;

  addressString = addIfNotEmpty(addressString, toTitleCase(address?.UnitNumber), ' ');
  addressString = addIfNotEmpty(addressString, toTitleCase(address?.LotNumber), ' Lot ');
  addressString = addIfNotEmpty(addressString, toTitleCase(address?.StreetNumber), ' ');
  addressString = addIfNotEmpty(addressString, toTitleCase(address?.StreetName), ' ');
  addressString = addIfNotEmpty(addressString, toTitleCase(address?.Suburb), ', ');
  addressString = addIfNotEmpty(addressString, address?.State, ' ', );
  addressString = addIfNotEmpty(addressString, address?.Postcode, ' ');

  return addressString.trim();
}
