import { buildClauseAndAnnexureDiffDisplay } from '@property-folders/common/util/pdfgen/sections/buildClauseAndAnnexureDiffDisplay';
import { ExtraFormCode } from '@property-folders/contract';
import {
  sectionTitles as st, subsectionTitles,
  subsectionTitles as sub
} from '@property-folders/common/util/pdfgen/doc-constants/sale-contract';
import { renderIfVariation } from '@property-folders/common/util/pdfgen/sections/variations/renderIfVariation';
import { variationItem } from '@property-folders/common/util/pdfgen/variations/variation-li';
import { partySection } from '@property-folders/common/util/pdfgen/sections/vendorSection';
import { vendorAddressChangeIfSameAs } from '@property-folders/common/util/variations/vendorAddressChangeIfSameAs';
import * as PdfGen from '@property-folders/common/util/pdfgen';
import { contractGstSection } from '@property-folders/common/util/pdfgen/sections/contractGst';
import { contractPriceSection } from '@property-folders/common/util/pdfgen/sections/contract-price';
import { contractSettlementSection } from '@property-folders/common/util/pdfgen/sections/contractSettlement';
import {
  cgtSection,
  chattelsSection,
  encroachSection,
  exclusionSection,
  gstWithholdingSection,
  notiWorksSection,
  poolSection,
  tenantSection, vendorWorksSection
} from '@property-folders/common/util/pdfgen/sections/particularsSection';
import { Predicate } from '@property-folders/common/predicate';
import { variationWrapContentInPage } from '@property-folders/common/util/pdfgen/variations/outer-skeleton';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { buildSignatureSection } from '@property-folders/common/util/pdf-worker/buildSignatureSection';
import { topLevelItemTextGen } from '@property-folders/common/util/pdfgen/formatters/topLevelItemTextGen';
import { specialConditionTextGen, subItemTextGen } from '@property-folders/common/util/pdfgen/formatters/subItemTextGen';
import { formatBI } from '@property-folders/common/util/pdfgen/formatBI';
import {
  financeSubsection,
  purchaserSaleSubsection
} from '@property-folders/common/util/pdfgen/sections/contractSpecialConditions';
import { LegalJurisdiction, chattelsOptionsContract } from '@property-folders/common/util/pdfgen/constants';
import { isVariationPresent } from '../../../variations/isVariationPresent';
import { bold, formatAct, ol, quote, stack } from '../../formatters/clauses';
import type { Content } from 'pdfmake/interfaces';
import type { PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';

const thisFormCode = ExtraFormCode.SCV_ContractOfSaleVariation;
const title = FormTypes[thisFormCode].label;

export function residentialSalesContractVariation({
  clausesId,
  signers,
  snapshotHistory,
  changeSet,
  property,
  propertyRaw,
  annexures,
  lastSignedSnapData,
  objects,
  brand
}: PdfWorkerDocumentDefinition) {
  const extraContent: Content[] = [];
  buildClauseAndAnnexureDiffDisplay(`clausesByFamily.[${clausesId}].clauses`, snapshotHistory, changeSet, propertyRaw, annexures, extraContent);

  const previousInstance = snapshotHistory?.instanceList?.[snapshotHistory.instanceList.length - 1];

  const signatureSection = buildSignatureSection(signers, previousInstance);

  // The section titles are all upper case, but we want this text to be 'normal' as it is also
  // used in other places.
  let vendorPartyLabel = st.vendors.title.toLocaleLowerCase();
  vendorPartyLabel = vendorPartyLabel[0].toLocaleUpperCase() + vendorPartyLabel.slice(1);
  let purchaserPartyLabel = st.purchasers.title.toLocaleLowerCase();
  purchaserPartyLabel = purchaserPartyLabel[0].toLocaleUpperCase() + purchaserPartyLabel.slice(1);

  const variationContent = [
    renderIfVariation(() => {
      return variationItem(topLevelItemTextGen(st.vendors),
        partySection(
          st.vendors.num,
          Object.values(property?.vendors) ?? [],
          property?.saleAddrs,
          property?.primaryVendor,
          vendorPartyLabel,
          true,
          false,
          'vendors',
          true
        ),
      );
    },
    [['vendors'], ['primaryVendor']],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(topLevelItemTextGen(st.purchasers),
        partySection(
          st.purchasers.num,
          Object.values(property?.purchasers) ?? [],
          property?.saleAddrs,
          property?.primaryPurchaser,
          purchaserPartyLabel,
          true,
          true,
          'purchasers',
          true
        ),
      );
    },
    [['purchasers'], ['primaryPurchaser']],
    changeSet,
    property && lastSignedSnapData && vendorAddressChangeIfSameAs(property, lastSignedSnapData)
    ),
    renderIfVariation(() => {
      return variationItem(topLevelItemTextGen(st.properties),
        PdfGen.propertySection({
          itemNo: st.properties.num,
          addresses: Object.values(property?.saleAddrs) ?? [],
          titles: property?.saleTitles ?? [],
          titleDivision: property?.titleDivision ?? {},
          proposedLots: property?.proposedLots,
          isVariation: true,
          annexures
        }),
      );
    },
    [['saleAddrs'], ['saleTitles'], ['titleDivision']],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(topLevelItemTextGen(st.gstSale),
        contractGstSection(st.gstSale.num, property?.vendorGst, property?.contractPrice, true)
      );
    },
    [
      ['contractPrice', 'inputTaxed'],
      ['contractPrice', 'gstIncluded'],
      ['contractPrice', 'marginApplied']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(topLevelItemTextGen(st.price),
        contractPriceSection(st.price.num, property?.vendorGst, property?.contractPrice, { isVariation: true })
      );
    },
    [
      ['contractPrice', 'purchasePrice'],
      ['contractPrice', 'purchaseGst'],
      ['contractPrice', 'deposit'],
      ['contractPrice', 'depositPayAt'],
      ['contractPrice', 'depositDateOther'],
      ['contractPrice', 'inputTaxed'],
      ['contractPrice', 'gstIncluded']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(topLevelItemTextGen(st.settlement),
        contractSettlementSection(st.settlement.num, property?.contractSettlement, property?.sale, true)
      );
    },
    [
      ['contractSettlement']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(subItemTextGen(st.particulars, sub.inclusions),
        chattelsSection(property?.chattels, chattelsOptionsContract, true, subsectionTitles.inclusions)
      );
    },
    [
      ['chattels']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(subItemTextGen(st.particulars, sub.exclusions),
        exclusionSection(property?.exclusions, true, subsectionTitles.exclusions)
      );
    },
    [
      ['exclusions']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(subItemTextGen(st.particulars, sub.encroach),
        encroachSection({ enable: property?.encroachEnable, content: property?.encroach, isVariation: true })
      );
    },
    [
      ['encroach'],
      ['encroachEnable']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(subItemTextGen(st.particulars, sub.notiWorks),
        notiWorksSection({ enable: property?.notiWorksEnable, content: property?.notiWorks, isVariation: true })
      );
    },
    [
      ['notiWorks'],
      ['notiWorksEnable']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.vendorWorks),
        vendorWorksSection({ enable: property?.vendorWorksEnable , content: property?.vendorWorks, isVariation: true })
      );
    },
    [
      ['vendorWorks'],
      ['vendorWorksEnable']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(subItemTextGen(st.particulars, sub.cgtWith),
        cgtSection(property?.cgtEnable, property?.cgtPerson, false, true)
      );
    },
    [
      ['cgtEnable'],
      ['cgtPerson']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(subItemTextGen(st.particulars, sub.gstWith),
        gstWithholdingSection({ gstWithholdEnable: property?.gstWithholdEnable }, { isVariation: true, contractMode: true })
      );
    },
    [
      ['gstWithholdEnable']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(subItemTextGen(st.particulars, sub.pool),
        poolSection(
          property?.pool?.present,
          property?.pool?.complyState,
          property?.pool?.nonComplyWorks, { contractMode: true, isVariation: true }
        )
      );
    },
    [
      ['pool']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return variationItem(subItemTextGen(st.particulars, sub.tenant),
        tenantSection(property?.saaTenant, property?.tenantsCollect, false, true)
      );
    },
    [
      ['saaTenant', 'tenantEnable']
    ],
    changeSet
    ),
    renderIfVariation(() => {
      return property?.contractSpecial?.financeRequired !== false
        ? variationItem(specialConditionTextGen(st.specialConditions, sub.financeSpecial),
          financeSubsection(property?.contractSpecial, false, { addresses: property.saleAddrs, titles: property.saleTitles })
        )
        : { text: formatBI(`The Clause ${quote(bold(sub.financeSpecial))} included within the ${bold(st.specialConditions.title)} is deleted.`) };
    },
    [
      ['contractSpecial','financeRequired'],
      ['contractSpecial','financeDeadline'],
      ['contractSpecial','financeDeadlineTime'],
      ['contractSpecial','financeAmount'],
      ['contractSpecial','financeInterestRate'],
      ['contractSpecial','financeProperties']
    ],
    changeSet,
    ()=>{
      if (property?.contractSpecial?.financeRequired) {
        return isVariationPresent([
          ['saleAddrs'],
          ['saleTitles']
        ], changeSet);
      }
      return false;
    }
    ),
    renderIfVariation(() => {
      return property?.contractSpecial?.purchaserSaleRequired !== false
        ? variationItem(specialConditionTextGen(st.specialConditions, sub.purchaserPropertySaleSpecial),
          purchaserSaleSubsection(property?.contractSpecial, {})
        )
        : { text: formatBI(`The Clause ${quote(bold(sub.purchaserPropertySaleSpecial))} included within the ${bold(st.specialConditions.title)} is deleted.`) };
    },
    [
      ['contractSpecial','purchaserSaleRequired'],
      ['contractSpecial','saleSettleAlsoRequired'],
      ['contractSpecial','salePropertySingleLine'],
      ['contractSpecial','purchaserSaleContractDeadline'],
      ['contractSpecial','purchaserSaleSettlementDeadline'],
      ['contractSpecial','purchaserSaleMinimumPrice']
    ],
    changeSet
    ),
    ...extraContent
  ].filter(Predicate.isNotNullish);

  const electronicSigningTerms = stack(
    'The Parties acknowledge and agree to:',
    ol(
      'the signing by a party of this Variation and any other documents by digital or electronic signatures; and',
      `all communications relating to the sale of the Property (including the service of any notices under the Act and service of the Form 1) by way of electronic means via the party’s email address or mobile phone number specified in the Contract or varied by this Variation or as otherwise provided to the other party from time to time, in accordance with the ${formatAct(LegalJurisdiction.SouthAustralia, 'ElectronicCommunicationsAct2000')}.`
    )
  );

  const counterpartsTerms = 'This Variation may be executed in any number of counterparts, and by a party on separate counterparts. All counterparts taken together constitute one and the same instrument.';

  return variationWrapContentInPage(
    variationContent,
    objects?.agencyLogoImage,
    title,
    brand,
    signatureSection,
    propertyRaw,
    snapshotHistory,
    FormTypes[thisFormCode],
    signers,
    {
      alternativeElectronicSigningTerms: electronicSigningTerms,
      alternativeCounterpartsTerms: counterpartsTerms,
      clause4ConsiderationText: 'The parties acknowledge and agree that this Variation is entered into in consideration of the mutual commitments contained in this Variation, any Deposit paid by the Purchaser under the Contract, and the ongoing obligations between the parties pursuant to the Contract as varied by this Variation.'
    }
  );
}
