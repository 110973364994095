export const baseReaformsCost = 88;
export const discountReaformsCost = 66;
export const baseEpfCost = 467.50;
export const discountEpfCost = 330;

export function reaformsDiscountForEpf (epfEnable: boolean) {
  return {
    originalCost: epfEnable? baseReaformsCost : undefined,
    cost: epfEnable? discountReaformsCost: baseReaformsCost
  };
}

export const epfBundlePrice = reaformsDiscountForEpf(true).cost + discountEpfCost;
export const reaformsStandalonePrice = baseReaformsCost;