import { DocumentFieldType } from '../document-field';

const brandContentConfig: Record<string, DocumentFieldType> = {
  foregroundColour: { _type: 'string', _subtype: 'colour' },
  backgroundColour: { _type: 'string', _subtype: 'colour' }
};
export const entitySettings: DocumentFieldType = {
  _type: 'Map',
  entityId: { _type: 'number' },
  entityUuid: { _type: 'UUID' },
  form1: {
    _type: 'Map',
    company: { _type: 'string' },
    address: { _type: 'string' },
    serviceFaxOrEmail: { _type: 'string' },
    serviceAddress: { _type: 'string' },
    serviceAddressIsRla: { _type: 'boolean' }
  },
  brand: {
    _type: 'Map',
    email: {
      _type: 'Map',
      button: {
        _type: 'Map',
        ...brandContentConfig
      },
      content: {
        _type: 'Map',
        ...brandContentConfig
      }
    },
    signing: {
      _type: 'Map',
      button: {
        _type: 'Map',
        ...brandContentConfig
      },
      remoteCompletion: {
        _type: 'Map',
        borderColour: { _type: 'string', _subtype: 'colour' }
      }
    },
    form: {
      _type: 'Map',
      lineColour: { _type: 'string', _subtype: 'colour' },
      headingColour: { _type: 'string', _subtype: 'colour' }
    },
    portal: {
      _type: 'Map',
      highlightColour: { _type: 'string', _subtype: 'colour' }
    },
    purchaserRegistration: {
      _type: 'Map',
      page: {
        _type: 'Map',
        ...brandContentConfig
      },
      stripe: {
        _type: 'Map',
        ...brandContentConfig
      },
      headerImageS3Uri: { _type: 'string' }
    }
  },
  dobTemplates: {
    _type: 'Array',
    _label: 'Disclosure of Benefits Templates',
    _children: {
      _type: 'Map',
      id: { _type: 'UUID' },
      name: { _type: 'string' },
      modified: { _type: 'number' },
      items: {
        _type: 'Array',
        _label: 'Disclosure of Benefit',
        _children: {
          _type: 'Map',
          id: { _type: 'UUID' },
          nature: { _type: 'string' },
          source: { _type: 'string' },
          amount: { _type: 'string' },
          recipient: { _type: 'string' }
        }
      }
    }
  }
};
