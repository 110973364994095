import { PdfWorkerDocumentDefinition } from '../../../pdf/pdf-worker-types';
import * as PdfGen from '../../index';
import logoSocietyOfAuctioneersAndAppraisersSaInc from '@property-folders/common/assets/logo-society-of-auctioneers-and-appraisers-sa-inc.js';
import reaformsLogo from '@property-folders/common/assets/reaforms-logo.js';
import transparentPixel from '@property-folders/common/assets/transparent-pixel.js';
import { sectionTitles as st } from '@property-folders/common/util/pdfgen/doc-constants/purchase-offer';
import { partySection } from '../../sections/vendorSection';
import { SigningAuthorityType } from '@property-folders/contract';
import { contractSettlementSection } from '../../sections/contractSettlement';
import {
  offerAdditionalConditionsSection, offerSpecialConditionsSection
} from '../../sections/contractSpecialConditions';
import { buildSignatureSection } from '../../../pdf-worker/buildSignatureSection';
import { offerPriceSection } from '../../sections/contract-price';
import { bold, formatAct, ol, quote, stack } from '../../formatters/clauses';
import { LegalJurisdiction } from '../../constants';
import { formatBI } from '../../formatBI';

export function residentialPurchaseOffer({ property, objects, brand, signers, formInstance, annexures }: PdfWorkerDocumentDefinition) {
  const useCoverPage = false;
  // note: if we want other land types they might be encoded here.
  const title = 'Notice of Offer to Purchase Residential Land';
  const iWe = property?.purchasers?.length
    // note: not sure how we count purchasers here.
    // is it number of purchaser records, or people/representatives?
    ?  property.purchasers.length > 1 || [
      SigningAuthorityType.directors2,
      SigningAuthorityType.directorSecretary
    ].includes(property.purchasers.at(0)?.authority as SigningAuthorityType)
      ? 'We'
      : 'I'
    : 'I';

  let purchaserPartyLabel = st.purchasers.title.toLocaleLowerCase();
  purchaserPartyLabel = purchaserPartyLabel[0].toLocaleUpperCase() + purchaserPartyLabel.slice(1);
  // workaround: empty string makes pdfmake unhappy, using <space> instead
  const salesAgentName = property?.agent?.length
    ? property.agent[0].company || ' '
    : ' ';

  const signatureSection = buildSignatureSection(signers, formInstance);

  return ({
    pageSize: 'A4',
    pageOrientation: 'portrait',
    images: {
      agencyLogo: objects?.agencyLogoImage || transparentPixel,
      societyAuctioneersLogo: logoSocietyOfAuctioneersAndAppraisersSaInc
    },
    content: [
      { stack: PdfGen.documentTitle(title, 1, brand, false), margin: [0, 0, 0, PdfGen.fieldsSpacing] },
      {
        text: `(${formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingRegulations2010', { noItalics: true, noLegalJurisdiction: true })} Regulation 20)`,
        style: 'importantSectionText',
        alignment: 'center',
        margin: [0, 0, 0, PdfGen.fieldsSpacing * 2]
      },
      PdfGen.itemSubsection({
        subsectionTitle: undefined, titleLineContent: undefined, subsectionContent: PdfGen.spaceStackLinesSideEffect([{
          text: formatBI(`Note: This is not a contract of sale document. Both the purchaser and vendor must sign a contract of sale document before this offer becomes legally binding. An offer may be withdrawn at any time before signing a contract of sale document. If you do enter into a contract of sale, it is advisable to check section 5 of the ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994', { noLegalJurisdiction: true })} regarding any cooling-off rights that you may have and how to exercise them.`),
          style: 'noticeToPurchaser'
        },{
          text: formatBI(`${bold('Attention Sales Agent:')} ${salesAgentName}`),
          style: 'importantSectionText'
        },{
          text: formatBI(`${iWe} the Purchaser wish to make the following ${bold(`best and final offer (${quote('Offer')})`)} to purchase the Property detailed below upon the terms of the Greatforms standard ${quote('Contract of Sale - Residential')} (${quote(bold('Contract'))}).`),
          style: 'importantSectionText'
        },{
          ...stack(
            'I acknowledge and agree that:',
            ol(
              'if this Offer is accepted and subject to the above notice, I will be required to enter into and execute a Contract with the Vendor in these terms;',
              `${iWe} may be one of several parties making offers to purchase the Property for the Vendor to consider; and`,
              formatBI(`in accordance with Regulation 31A of the ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingRegulations2010')}, the Form 1 - ${quote('Vendor’s Statement')} in relation to the Property may be served on me by email, to the address specified in this Offer.`)
            )
          ),
          style: 'importantSectionText'
        }])
      }),
      ...PdfGen.propertySection({
        itemNo: st.properties.num,
        addresses: Object.values(property?.saleAddrs ?? []),
        titles: property?.saleTitles ?? [],
        titleDivision: property?.titleDivision ?? {},
        // don't think we need this to be set for offers
        annexures
      }),
      ...partySection(
        st.purchasers.num,
        Object.values(property?.purchasers ?? []),
        property?.saleAddrs,
        property?.primaryPurchaser,
        purchaserPartyLabel,
        false,
        true,
        'purchasers'
      ),
      ...offerPriceSection(st.price.num, property?.contractPrice),
      ...contractSettlementSection(st.settlement.num, property?.contractSettlement, property?.sale, undefined, true),
      ...offerSpecialConditionsSection(st.specialConditions.num, property?.contractSpecial, !!property?.offerAdditionalConditions),
      ...offerAdditionalConditionsSection(st.additionalConditions.num, property?.offerAdditionalConditions),
      {
        unbreakable: false,
        stack: [
          { text: PdfGen.format('\n\n*EXECUTED* as an agreement') },
          ...signatureSection
        ]
      }
    ],
    pageMargins: (currentPage: number) => PdfGen.standardPageMargins(currentPage, useCoverPage),
    footer: (currentPage: number, pageCount: number) => PdfGen.standardFooter(currentPage, title, useCoverPage, pageCount),
    header: (currentPage: number) => PdfGen.standardHeader(currentPage, title, useCoverPage, reaformsLogo, brand.agencyContact)
  });
}
