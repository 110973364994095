import {
  PropertiesRecentResult, PropertiesSearchQueryParams,
  PropertiesSearchResult,
  PutPropertyFileRefParams,
  PutPropertyFileRefResult
} from '@property-folders/contract/rest/property';
import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';
import { useInfiniteQuery } from '@tanstack/react-query';

import { cloneSimple } from '../util/cloneSimple';
import { LookupPropertiesQueryParams, Maybe } from '@property-folders/contract';
import { Lookups } from './lookups';

export class Properties {
  public static async search(params: PropertiesSearchQueryParams): Promise<PropertiesSearchResult> {
    const qs = LinkBuilder.buildQueryString(params);
    return (await WrappedFetch.json(LinkBuilder.restApi(`/properties?${qs}`))) || {
      items: [],
      totalMatching: 0,
      pageSize: params.pageSize || 0,
      pageNumber: params.pageNumber || 1
    };
  }

  public static lookup(params: LookupPropertiesQueryParams) {
    return Lookups.lookupProperties(params);
  }

  public static async getRecent(): Promise<Maybe<PropertiesRecentResult>> {
    return WrappedFetch.json(LinkBuilder.restApi('/properties/recent'));
  }

  public static async putFileRef(propertyId: string, fileId: string, params: PutPropertyFileRefParams): Promise<Maybe<PutPropertyFileRefResult>> {
    const qs = LinkBuilder.buildQueryString(params.query);
    return WrappedFetch.json(LinkBuilder.restApi(`/properties/${propertyId}/file-refs/${fileId}?${qs}`), {
      method: 'PUT'
    });
  }

  public static async getFile(propertyId: string, fileId: string, defaultContentType: string) {
    const result = await WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/files/${fileId}`));
    return {
      data: await result.blob(),
      contentType: result.headers.get('content-type') || defaultContentType
    };
  }

  public static useSearchInfiniteQuery(defaultParams: PropertiesSearchQueryParams) {
    return useInfiniteQuery(
      ['properties', 'search', defaultParams.searchTerm, defaultParams.showArchived, defaultParams.showStarred],
      async context => {
        return await Properties.search(context.pageParam || defaultParams);
      },
      {
        getNextPageParam: (lastPage, _) => {
          if (!lastPage.items[0]?.id) {
            return undefined;
          }

          const p = cloneSimple(defaultParams);
          p.pageNumber = lastPage.pageNumber + 1;
          return p;
        }
      }
    );
  }
}

