import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';
import { ListCategoriesForAgent, ListFormsForAgent } from '@property-folders/contract/rest/forms';

export class FormsApi {
  static async listFormsForAgent() {
    const apiResult = await WrappedFetch.json<ListFormsForAgent>(LinkBuilder.restApi('/forms'));

    return {
      results: apiResult?.results ?? []
    };
  }

  static async listCategoriesForAgent() {
    const apiResult = await WrappedFetch.json<ListCategoriesForAgent>(LinkBuilder.restApi('/forms/categories'));

    return {
      hasMultipleProducts: apiResult?.hasMultipleProducts ?? false,
      results: apiResult?.results ?? []
    };
  }
}
