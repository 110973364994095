import { CachedParty, FileRef, TransactionMetaData } from '@property-folders/contract';
import { MasterRootKey } from '@property-folders/contract/yjs-schema/property';
import * as Y from 'yjs';
import { bind } from 'immer-yjs';

export function getCachedPartyCanonicalId(name?: string, commsId?: string) {
  if (!(name && commsId)) return undefined;

  const canonicalName = name
    .toLowerCase()
    .replace(/\s+/, ' ')
    .trim();
  const canonicalEmail = commsId
    .toLowerCase()
    .trim();

  return `n:${canonicalName};e:${canonicalEmail}`;
}

/** Cache operates on root Meta only. */
export class PartyCacheYjsDal {
  metaRootKey: string;
  constructor(
    private doc: Y.Doc
  ) { this.metaRootKey = MasterRootKey.Meta;}

  private get metaBinder() {
    return bind<TransactionMetaData>(this.doc.getMap(this.metaRootKey));
  }

  public getParty(name?: string, commsId?: string): CachedParty | undefined {
    const canonicalId = getCachedPartyCanonicalId(name, commsId);
    if (!canonicalId) return undefined;

    const state = this.metaBinder.get();
    if (!state.partyCache) return undefined;

    return state.partyCache.find(p => p.canonicalId === canonicalId);
  }

  public static findCachedPartyInPartyCache(partyCache?: CachedParty[], name?: string, commsId?: string) {
    if (!partyCache?.length) return undefined;

    const canonicalId = getCachedPartyCanonicalId(name, commsId);
    if (!canonicalId) return undefined;

    return partyCache.find(p => p.canonicalId === canonicalId);
  }

  public static setPartyImagesInState(state: TransactionMetaData, name?: string, commsId?: string, signature?: FileRef, initials?: FileRef): boolean {
    if (!(signature || initials)) return false;
    const canonicalId = getCachedPartyCanonicalId(name, commsId);
    if (!canonicalId) return false;

    if (!state.partyCache) {
      state.partyCache = [];
    }

    const match = state.partyCache.find(p => p.canonicalId === canonicalId);
    if (match) {
      if (initials && initials.id !== match.images.initials?.id) {
        match.images.initials = initials;
      }
      if (signature && signature.id !== match.images.signature?.id) {
        match.images.signature = signature;
      }
    } else {
      state.partyCache.push({
        canonicalId,
        images: {
          initials,
          signature
        }
      });
    }
    return true;
  }
}
