import {
  AdminFeeType,
  ItemiserItem,
  NoneSetOrRange,
  PaymentTermsType,
  PressBudgetType,
  ProfessionalFeeType,
  UpliftType
} from '@property-folders/contract';
import { accRendOpts, boolYesNoOpts, payMethodOpts } from '../constants';
import { Predicate } from '../../../predicate';
import {
  fieldFocus,
  freeTextArea,
  itemSubsection,
  itemSection,
  lineIfEmpty,
  optionsText,
  singleFieldTable,
  spaceStackLinesSideEffect,
  twoFieldTable, fieldsSpacing, generateCheckboxRows, drawUnderline
} from '..';
import { FieldPlaceholderStyle } from '../standards';

import type { FieldPlaceholderStyleValue } from '../standards';

function proFeeNoMode (
  mode: NoneSetOrRange | undefined,
  lower: string | undefined,
  upper: string | undefined,
  texts: {none: string, set: string, rangeSuffix: string},
  fieldPlaceholder: FieldPlaceholderStyleValue
) {
  const rangeMode = mode === NoneSetOrRange.Range;
  const setMode = mode === NoneSetOrRange.Set;

  const RangeStyle = rangeMode ? 'content' : undefined;
  const SetStyle = setMode ? 'content' : undefined;

  return {
    stack: spaceStackLinesSideEffect([
      generateCheckboxRows([
        {
          other: false,
          selectMatch: NoneSetOrRange.Set.toString(),
          label: {
            text: [
              { text: texts.set + ' ', style: SetStyle },
              { text: setMode && lower ? lower : drawUnderline(fieldPlaceholder), style: 'content' },
              ' (inc GST)'
            ]
          }
        },
        {
          other: false,
          selectMatch: NoneSetOrRange.Range.toString(),
          label: {
            text: [
              { text: 'Range ', style: RangeStyle },
              { text: rangeMode && lower ? lower : drawUnderline(fieldPlaceholder), style: 'content' },
              { text: ' (inc GST) to ', style: RangeStyle },
              { text: rangeMode && upper ? upper : drawUnderline(fieldPlaceholder), style: 'content' },
              { text: ' '+texts.rangeSuffix }

            ]
          }
        }
      ], Predicate.isNotNullish(mode) ? [mode.toString()] : [], 1)
    ])
  };
}

function rangeOneLiner (
  lower: string | undefined,
  upper: string | undefined,
  texts: {prefix: string, suffix: string, lowerSuffix?: string;},
  fieldPlaceholder: FieldPlaceholderStyleValue,
) {
  return {
    text: [
      { text: texts.prefix + ': ' },
      { text: lower ? lower : drawUnderline(fieldPlaceholder), style: 'content' },
      { text: ` ${texts.lowerSuffix ?? ''}to ` },
      { text: upper ? upper : drawUnderline(fieldPlaceholder), style: 'content' },
      { text: ' '+texts.suffix }
    ]
  };
}

function proFeeSection(professFee?: ProfessionalFeeType, uplift?: UpliftType, isVariation?: boolean) {
  const { fixed, fixedUpper, commis, commisUpper, fixedMode, commissionMode  } = professFee || {};
  const sectionItems = [{
    text: 'The Vendor will pay the agreed Professional Fees to the Agent on the terms for services and/or Commissions as set out below and in accordance with General Condition 9.',
    lineHeight: 1.2
  }];

  const fixedNoneOrUnset = !fixedMode || fixedMode === NoneSetOrRange.None;
  const commisNoneOrUnset = !commissionMode || commissionMode === NoneSetOrRange.None;

  const bothUnset = fixedNoneOrUnset && commisNoneOrUnset;

  if (commissionMode === NoneSetOrRange.Range) {
    sectionItems.push(
      rangeOneLiner(commis, commisUpper, {
        prefix: 'Commission',
        suffix: '(inc GST) at Vendor’s discretion, to be determined at time of Sale, and to be calculated on the total sale price of the Property.',
        lowerSuffix: '(inc GST) '
      },
      FieldPlaceholderStyle.Percentage)
    );
  } else if (commissionMode === NoneSetOrRange.Set) {
    sectionItems.push({
      text: [
        'Commission: ',
        { text: commis ? commis : drawUnderline(FieldPlaceholderStyle.Percentage), style: 'content' },
        ' (inc GST)'
      ]
    });
  } else if (bothUnset) {
    sectionItems.push(
      { text: 'Commission' },
      proFeeNoMode(commissionMode, commis, commisUpper, { none: 'No Commission', set: 'Set %', rangeSuffix: '(inc GST) at Vendor’s discretion' }, FieldPlaceholderStyle.Percentage),
    );
  }

  if (fixedMode === NoneSetOrRange.Range) {
    sectionItems.push(
      rangeOneLiner(fixed, fixedUpper, {
        prefix: 'Fixed Fee of',
        suffix: '(inc GST) at Vendor’s discretion, to be determined at the earlier of time of Sale, or expiry of the Term.',
        lowerSuffix: '(inc GST) '
      },
      FieldPlaceholderStyle.Price)
    );
  } else if (fixedMode === NoneSetOrRange.Set) {
    sectionItems.push({
      text: [
        'Fixed Fee: ',
        { text: fixed ? fixed : drawUnderline(FieldPlaceholderStyle.Price), style: 'content' },
        ' (inc GST)'
      ]
    });
  } else if (bothUnset) {
    sectionItems.push(
      { text: 'Fixed Fee' },
      proFeeNoMode(fixedMode, fixed, fixedUpper, { none: 'No Fixed Fee', set: 'Set Amount', rangeSuffix: '(inc GST) at Vendor’s discretion' }, FieldPlaceholderStyle.Price)
    );
  }

  return itemSubsection({
    subsectionContent: spaceStackLinesSideEffect(sectionItems),
    bookmark: ['subsection-profess-fee', ...fieldFocus('professFee')],
    isVariation
  });
}

const modeEnabledTypes = [NoneSetOrRange.Set, NoneSetOrRange.Range];

function upliftSection(uplift?: UpliftType, professFee?: ProfessionalFeeType, isVariation?: boolean) {
  const { enable, thresh, portion, strike, percentageOrFixed, fixed } = uplift || {};
  const { commissionMode, fixedMode } = professFee??{};
  const sectionItems = [];

  const fixedEnabled = modeEnabledTypes.includes(fixedMode);
  const commissionEnabled = modeEnabledTypes.includes(commissionMode);

  const bothEnabled = fixedEnabled && commissionEnabled;

  const feeString = fixedEnabled && !commissionEnabled
    ? 'Commission'
    : commissionEnabled && !fixedEnabled
      ? 'Fixed Fee'
      : 'Fixed Fee or Commission';

  sectionItems.push({
    text: [
      'Should the total sale price of the Property exceed ',
      lineIfEmpty(enable ? thresh : '', FieldPlaceholderStyle.Price),
      ' (‘',
      { text: 'Agreed Higher Sales Threshold', bold: true },
      '’)',
      ' then in consideration of the work done and the sale price achieved by the Agent, an additional Commission will be payable by the Vendor to the Agent, being an additional ',
      lineIfEmpty(enable ? (percentageOrFixed ? portion : fixed) : '', percentageOrFixed ? FieldPlaceholderStyle.Percentage : FieldPlaceholderStyle.Price),
      ' (inc GST)',
      percentageOrFixed
        ? ' calculated and payable only on the amount achieved above the Agreed Higher Sales Threshold.'
        : ', irrespective of by how much the sale price exceeds the Agreed Higher Sales Threshold.',
      '\n\n',
      'This Commission Uplift is in addition to the '+(bothEnabled ? 'Fixed Fee and Commission' : feeString)+' agreed above. ',
      percentageOrFixed ? '\n\n' : '',
      percentageOrFixed ? { italics: true, text: 'By example if the Agreed Higher Sales Threshold is set at $500,000 and the Contract sale price is $600,000 then the Commission Uplift (if @ 20%) is 20% of the increase ($100,000) – namely $20,000 (inc GST), in addition to any '+feeString+' agreed above.' } : ''
    ].filter(Predicate.isTruthy),
    lineHeight: 1.2,
    margin: [0, fieldsSpacing, 0, 0]
  });

  return itemSubsection({
    subsectionTitle: 'Commission Uplift',
    titleLineContent: undefined,
    subsectionContent: Predicate.boolFalse(enable) && Predicate.boolFalse(strike) ? [{ text: 'Not applicable' }] : spaceStackLinesSideEffect(sectionItems),
    unbreakable: undefined,
    bookmark: ['subsection-uplift', ...fieldFocus('uplift')],
    otherStackAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    titleOverrideAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    isVariation
  });
}

function payTermsSection(payTerm?: PaymentTermsType, isVariation?: boolean) {
  const { enable: enablePrint, strike, actualTerms, marketingPaid, method, methodOther, rendFreq, surcharge } = payTerm || {};
  const sectionItems = [];
  if (Predicate.truthyOrUndefined(enablePrint) || strike) {
    sectionItems.push(
      singleFieldTable({
        fieldName: 'Approved marketing budget paid in advance',
        fieldValue: optionsText(marketingPaid, boolYesNoOpts),
        isVariation
      }),
      twoFieldTable({
        fieldName: 'Method of payment',
        fieldValue: method === 'other' && methodOther ? methodOther : optionsText(method, payMethodOpts),
        fieldName2: 'Accounts rendered',
        fieldValue2: optionsText(rendFreq, accRendOpts),
        _: undefined,
        fieldColons: [true, false],
        isVariation
      })
    );
    (!method || (method === 'other' && !methodOther)) && sectionItems.push(
      singleFieldTable({ fieldName: 'Other payment method', fieldValue: '', isVariation }));
    sectionItems.push(
      freeTextArea({ title: undefined, content: actualTerms, linesIfEmpty: actualTerms ? '' : 'Payment Terms' }),
      { text: [
        'A surcharge of ',
        lineIfEmpty(surcharge, FieldPlaceholderStyle.Percentage),
        ' will apply to all payments authorised to recover back charges and merchant fees.'
      ], lineHeight: 1.5 }
    );
  }
  return itemSubsection({
    subsectionTitle: 'Terms of payment',
    titleLineContent: undefined,
    subsectionContent: Predicate.boolFalse(enablePrint) && Predicate.boolFalse(strike) ? [{ text: 'Not applicable' }] :  spaceStackLinesSideEffect(sectionItems),
    unbreakable: undefined,
    bookmark: ['subsection-pay-terms', ...fieldFocus('payTerm')],
    otherStackAttributes: Predicate.boolFalse(enablePrint) && strike ? { decoration: 'lineThrough' } : undefined,
    titleOverrideAttributes: Predicate.boolFalse(enablePrint) && strike ? { decoration: 'lineThrough' } : undefined,
    isVariation
  });
}

export function professionalFeeSection(
  itemNo: number,
  marketingFees?: ItemiserItem[],
  pressBudget?: PressBudgetType,
  searchFees?: ItemiserItem[],
  professFee?: ProfessionalFeeType,
  uplift?: UpliftType,
  adminFee?: AdminFeeType,
  saleMethod?: string,
  auctionFee?: string,
  payTerm?: PaymentTermsType,
  isVariation?: boolean
) {
  const sectionItems = [];
  sectionItems.push(proFeeSection(professFee, uplift, isVariation));
  sectionItems.push(upliftSection(uplift, professFee, isVariation));
  // sectionItems.push(payTermsSection(payTerm, isVariation));
  return itemSection({
    itemNo: itemNo,
    itemTitleParam: 'Professional Fee'.toUpperCase(),
    bookmark: 'bookmark_fees_professional',
    stackContent: sectionItems,
    isVariation
  });
}
