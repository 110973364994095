import { LinkBuilder } from '../util/LinkBuilder';
import { WrappedFetch } from '../client-api/wrappedFetch';
import { FormItem } from '@property-folders/contract/rest/forms';

export interface ListSubscriptionFormsResponse {
  results: FormItem[]
}

export type LogoType = 'Logo' | 'PurchaserRegistrationImage';

export class AjaxPhp {
  static async login(email: string, password: string) {
    const fdata = new FormData();
    fdata.append('email', email.toLowerCase());
    fdata.append('password', password);
    try {
      const response = await fetch(`${LinkBuilder.ajax}?action=login`, {
        method: 'post',
        body: fdata
      });
      const body = await response.json();

      if (!body?.success) {
        console.log('login failure', body);
        return false;
      }

      return true;
    } catch (err: unknown) {
      console.error('login failure', err);
      return false;
    }
  }

  static async loginAs(userId: number) {
    const response = await fetch(`${LinkBuilder.ajax}?action=loginas&UserID=${userId}`, {
      method: 'post'
    });

    return response.status === 200;
  }

  static async extendSession() {
    try {
      const response = await fetch(`${LinkBuilder.ajax}?action=extendsession`, {
        method: 'post'
      });
      const body = await response.json();

      if (!body?.success) {
        console.log('session extension failure', body);
        return false;
      }
      return true;
    } catch (err: unknown) {
      console.error('session extension failure', err);
      return false;
    }
  }

  static listSubscriptionForms(entityId: string | number, withClauses = false) {
    entityId = String(entityId);

    return WrappedFetch.json<ListSubscriptionFormsResponse>(`${LinkBuilder.legacyApi(`/form/entity/${entityId}?withClauses=${withClauses}`)}`);
  }

  static async loginWithCode(code: string) {
    return WrappedFetch.json<{ success: boolean }>(`${LinkBuilder.ajax}?action=login&code=${code}`);
  }

  static async archiveDocument(id: number | string) {
    return WrappedFetch.json<{ success: boolean }>(`${LinkBuilder.ajax}?action=archivedocument&DocumentID=${id}`);
  }

  static async unarchiveDocument(id: number | string) {
    return WrappedFetch.json<{ success: boolean }>(`${LinkBuilder.ajax}?action=unarchivedocument&DocumentID=${id}`);
  }

  static async generatePdf(id: number | string, download?: boolean) {
    return WrappedFetch.json<{success: boolean; link: string; name: string}>(
      LinkBuilder.ajaxRequest({
        action: 'generatepdf',
        DocumentID: id,
        Download: !!download
      })
    );
  }

  static generatePdfUrl(id: number | string) {
    return LinkBuilder.reaformsFromRoot(`/tools/GeneratePDF.php?DocID=${id}`, true);
  }

  static generateAuditPdfUrl(id: number | string) {
    return LinkBuilder.ajaxRequest({ action: 'generateauditpdf', DocumentID: id });
  }

  static async renameDocument({ id, newName }: { id: number | string, newName: string }) {
    return WrappedFetch.json<{ success: boolean; }>(LinkBuilder.ajaxRequest({
      action: 'renamedocument',
      DocumentID: id,
      renamename: newName
    }));
  }

  static async assignAgentToDocument({ documentId, agentId }: {documentId: number | string, agentId: number | string}) {
    return WrappedFetch.json<{success: boolean}>(LinkBuilder.ajaxRequest({
      action: 'assignagent',
      DocumentID: documentId,
      assign_available: agentId
    }));
  }

  static async uploadLogo({
    entityUuid,
    type,
    data
  }: {
    entityUuid: string,
    type: LogoType,
    data: Blob
  }) {
    const fdata = new FormData();
    fdata.append('LogoUpload', data);
    return WrappedFetch.json<{ success: boolean, url: string, urlLegacy: string }>(LinkBuilder.ajaxRequest({
      action: 'uploadlogo',
      EntityUUID: entityUuid,
      Type: type
    }), {
      method: 'post',
      body: fdata
    });
  }

  static async removeLogo({
    entityUuid,
    type
  }: {
    entityUuid: string,
    type: LogoType
  }) {
    return WrappedFetch.json<{ success: boolean }>(LinkBuilder.ajaxRequest({
      action: 'removelogo',
      EntityUUID: entityUuid,
      Type: type
    }), {
      method: 'post'
    });
  }

  static async getLogoUrl({
    entityUuid,
    type
  }: {
    entityUuid: string,
    type: LogoType
  }) {
    return WrappedFetch.json<{ success: boolean, url: string }>(LinkBuilder.ajaxRequest({
      action: 'getlogourl',
      EntityUUID: entityUuid,
      Type: type
    }), {
      method: 'post'
    });
  }

  static async getEntitySettingsInfo({
    entityUuid,
    signal
  }: {
    entityUuid: string,
    signal: AbortSignal
  }) {
    return WrappedFetch.json<{ success: boolean, urlLogo: string, urlPurchaserHeader: string, entityId: number }>(LinkBuilder.ajaxRequest({
      action: 'getentitysettingsinfo',
      EntityUUID: entityUuid
    }), {
      method: 'post',
      signal
    });
  }
}
