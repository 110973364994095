import {
  AdminFeeType,
  Annexure,
  AuctionFeeSwitches,
  DisclosureOfBenefitsType,
  FormCode,
  ItemiserItem,
  MarketingFeesOptions,
  MarketingTemplate,
  MarketingTemplateItem,
  MaterialisedPropertyData,
  PressBudgetType
} from '@property-folders/contract';
import {
  adminFeeDefaultMessage,
  auctionFeeDefaultMessage,
  expenditurePeriods,
  pressBeginOpts,
  pressEndOpts
} from '../constants';
import { rayWhiteMarketingTemplateHeader } from '../../../assets/raywhite';
import { Predicate } from '../../../predicate';
import {
  knownPair,
  arbitraryFieldsRowTable,
  fieldBoolCheckboxes,
  fieldFocus,
  fieldLabel,
  freeTextArea,
  generateCheckboxInlineTextLine,
  generateCheckboxRows,
  itemiser,
  itemSection,
  itemSubsection,
  singleFieldTable,
  spaceStackLinesSideEffect,
  confineWidthWithColumn,
  itemiserGeneric,
  noborder,
  insertIf,
  bookmarkAnchor, fieldsSpacing, generateCheckboxRowsV2
} from '..';
import { mapOptsForCheckbox } from '../display-transformations';
import { subsectionTitles } from '../doc-constants/sales-agency-agreement';
import { A4_WIDTH, MARGIN_ITEM_LEFT, MARGIN_LEFT, MARGIN_RIGHT, mmToPoints } from '../measurements';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { flatMap, groupBy } from 'lodash';

import { canonicalisers } from '../../formatting';
import { checkbox, checkboxChecked } from '../../../assets/checkbox';
import { formatBI } from '../formatBI';
import { FieldPlaceholderStyle } from '../standards';

// Not sure why needs to return array rather than use a variable, but this works
const notApplicableText = () => [{ text: 'Not applicable', margin: [0, fieldsSpacing, 0, 0] }];

export function marketingCostSection(data: {
  marketingFees?: ItemiserItem[],
  marketingFeesOptions?: MarketingFeesOptions,
  formCode?: FormCode,
  annexures?: Annexure[]
}, isVariation?: boolean) {
  const {
    marketingFeesOptions,
    marketingFees,
    formCode = FormCode.RSAA_SalesAgencyAgreement,
    annexures = []
  } = data;

  const sectionItems = [];
  const annexureRef = marketingFeesOptions?.externalFeeScheduleAnnexureRef?.['family_'+formCode];
  const { denormalisedTotal, noFees } = marketingFeesOptions ?? {};
  const denormalisedTotalCanon = canonicalisers.audWithNegative(denormalisedTotal);
  const denormalisedTotalNumber = denormalisedTotalCanon.valid ? denormalisedTotalCanon.canonical : 0;
  const considerTableComplete = typeof denormalisedTotalNumber === 'number' && denormalisedTotalNumber > 0;
  if (noFees) {
    sectionItems.push(generateCheckboxRows(['No marketing costs to be charged by the agent'], ['No marketing costs to be charged by the agent']));
  } else if (annexureRef) {
    const foundAnnexure = annexures?.find(annex=>annex.id === annexureRef);
    if (foundAnnexure) {
      sectionItems.push({ text: formatBI(`Refer *-*Annexure ${foundAnnexure.label}*-* - ${foundAnnexure.name}`), style: 'sectionText' });
    }
  }

  if (sectionItems.length === 0) {
    if (!considerTableComplete) {
      sectionItems.push(generateCheckboxRows([
        'No marketing costs to be charged by the agent',
        'Refer Annexure for Marketing costs'
      ], [], 1));
    }
    sectionItems.push(itemiser(marketingFees, 'APPROVED AMOUNT (inclusive of GST)', considerTableComplete ? 0 : undefined));
  }

  return itemSubsection({
    subsectionTitle: subsectionTitles.marketingCosts,
    subsectionContent: [{ stack: spaceStackLinesSideEffect(sectionItems, 1 ), margin: [0,fieldsSpacing,0,0] }],
    bookmark: ['subsection-marketing-fees', ...fieldFocus('marketingFees')],
    isVariation
  });
}

export function rayWhiteMarketingTemplateSection(marketingTemplate: MarketingTemplate, marketingFees?: MarketingTemplateItem[], brand?: EntityBrandFormConfig, isVariation = false) {
  const sectionItems = [];
  const textGrey = '#808080';

  if (!isVariation) {
    sectionItems.push({
      image: rayWhiteMarketingTemplateHeader,
      width: A4_WIDTH - MARGIN_LEFT - MARGIN_RIGHT - MARGIN_ITEM_LEFT,
      margin: [0, 2, 0, 0]
    });

    sectionItems.push(
      {
        table: {
          headerRows: 1,
          body: [[{
            stack: [
              {
                text: (marketingTemplate?.title || 'Your Marketing Package'),
                fontSize: 22,
                color: textGrey,
                margin: [0, 0, 0, 4]
              },
              {
                text: marketingTemplate?.subTitle || '',
                fontSize: 15,
                color: textGrey
              }
            ],
            fillColor: '#D9D9D9',
            fillOpacity: 0.85
          }]]
        },
        layout: {
          defaultBorder: false
        },
        relativePosition: { x: 15, y: -85 }
      },
    );
  }

  const sections = groupBy(marketingFees, f => f.section);
  let idx = 0;
  const variationMargin = isVariation ? 54 : 0;
  function marketingItemSummation (acc: number, cv: MarketingTemplateItem) {
    const canonObject = canonicalisers.audWithNegative(cv?.itemCost);
    const newValue = cv?.enable ? (typeof canonObject.canonical === 'number' ? canonObject.canonical : 0) : 0;
    return acc + newValue;
  }
  sectionItems.push(...flatMap(sections, (item, section) => {
    const sectionTotalNumeric = item.reduce(marketingItemSummation, 0);
    const sectionTotal = canonicalisers.audWithNegative(sectionTotalNumeric.toString()).display;
    return [{
      unbreakable: true,
      stack: [
        {
          canvas: [{
            type: 'polyline',
            lineWidth: mmToPoints(1.1),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: A4_WIDTH - MARGIN_LEFT - MARGIN_RIGHT - variationMargin - MARGIN_ITEM_LEFT, y: 0 }],
            lineColor: brand?.lineColour
          }],
          margin: [0, idx++ ? 6 : 0, 0, 0]
        },
        bookmarkAnchor(`subsection-sub-${section.replace(' ', '-')}`),
        {
          table: {
            widths: [10, '*', 80],
            heights: (row: number) => !row ? 22 : 5,
            color: textGrey,
            body: [
              [{
                colSpan: 3,
                columns: [{ text: section, fontSize: 15 }, idx === 1 ? { alignment: 'right', text: marketingTemplate?.agentName || '', fontSize: 12, margin: [0, 3, 0, 0] } : {}],
                border: [0, 0, 0, 0],
                verticalAlign: 'middle',
                color: textGrey
              }, {}, {}],
              ...(item?.filter(i => i.itemDesc)?.map(i => ([
                {
                  svg: i.enable ? checkboxChecked : checkbox,
                  fit: [15, 15],
                  border: [0, 0, 0, 0],
                  margin: [-2, 0, 0, -4]
                },
                {
                  text: i.itemDesc || '',
                  border: [0, 0, 0, 1],
                  margin: [-2, 1, 0, -1],
                  color: textGrey
                },
                {
                  text: i.itemCost || '',
                  border: [0, 0, 0, 1],
                  alignment: 'right',
                  margin: [-2, 1, 0, -1],
                  color: textGrey
                }
              ])) || []),
              [{ text: '', border: noborder }, { text: '', border: noborder }, {
                text: sectionTotal,
                border: noborder,
                fontSize: 12,
                alignment: 'right',
                margin: [0, 3, 0, 0],
                color: textGrey
              }],
              ...insertIf(idx === Object.keys(sections).length, //Grand Total - only show on last section
                [
                  { text: '', border: noborder },
                  { text: 'Total', border: noborder, fontSize: 12, bold: true, margin: [0, 5, 0, 0] },
                  {
                    text: canonicalisers.audWithNegative(marketingFees?.reduce(marketingItemSummation, 0).toString()).display,
                    fontSize: 12,
                    bold: true,
                    margin: [0, 5, 0, 0],
                    alignment: 'right',
                    border: [0, 0, 0, 1],
                    borderColor: ['', '', '', '#000']
                  }]
              )
            ]
          },
          layout: {
            fillColor: (row: number) => !row ? '#D9D9D9' : '#FFFFFF',
            hLineWidth: () => 0.5,
            hLineColor: () => '#D9D9D9'
          }
        },
        bookmarkAnchor(`subsection-sub-${section.replace(' ', '-')}_!END`)
      ]
    }
    ];}));

  return itemSubsection({
    subsectionTitle: subsectionTitles.marketingCosts,
    subsectionContent: sectionItems,
    isVariation: false,
    unbreakable: false
  });
}

export function benefitsSection(data: {benefits?: DisclosureOfBenefitsType}, opts: {isVariation?: boolean}) {
  const { benefits } = data ?? {};
  const { isVariation } = opts ?? {};
  const sectionItems = [];
  sectionItems.push({ text: 'In addition to the Benefits set out in General Condition 8, the Agent discloses to the Vendor the following Benefits:', margin: [0, fieldsSpacing, 0, 0] });
  sectionItems.push(itemiserGeneric(benefits?.benefits, [
    { name: 'itemDesc', width: '*', header: 'Nature of the Benefit' },
    { name: 'itemSource', width: '21%', header: 'Source of the Benefit' },
    { name: 'itemCost', width: '21%', header: 'Amount (or estimated amount or value) of the Benefit', alignment: 'right', display: 'aud' },
    { name: 'itemRecipient', width: '21%', header: 'Name of recipient of the Benefit and capacity* of recipient' }
  ]));
  sectionItems.push({ text: '* Refers to the capacity in which the person receives the Benefit eg as an agent, a financier, mortgage broker, lawyer', italics: true, margin: [0, 10, 0, 0] });
  return itemSubsection({
    subsectionTitle: subsectionTitles.disclosureOfBenefits,
    subsectionContent: Predicate.boolFalse(benefits?.enable)
      ? notApplicableText()
      : spaceStackLinesSideEffect(sectionItems),
    bookmark: ['subsection-disclosure-of-benefits', ...fieldFocus('benefits')],
    isVariation,
    unbreakable: false
  });
}

export function pressSection(pressBudget?: PressBudgetType, isVariation?: boolean) {
  const { strike, enable, start, startOther, expenditure, expPeriod, endAgency, otherEnd, additional } = pressBudget || {};
  const sectionItems = [];
  if (Predicate.truthyOrUndefined(enable) || strike) {
    sectionItems.push(singleFieldTable({
      fieldName: 'In addition to the Vendor’s Marketing Costs set out above?',
      fieldValue: fieldBoolCheckboxes(additional),
      fieldColons: false,
      isVariation
    }));
    sectionItems.push(
      fieldLabel({ fieldName: 'To begin from', isVariation }),
      generateCheckboxRows(mapOptsForCheckbox(pressBeginOpts, startOther), start ? [start] : [], 1)
    );

    sectionItems.push({
      columns: [
        {
          ...arbitraryFieldsRowTable({
            fields: [['Approved expenditure', expenditure]],
            fieldColons: [true, false],
            labelStyleOverride: [undefined, 'fieldFontGeneral'],
            contentStyleOverride: 'fieldFontGeneral',
            isVariation,
            fieldPlaceholder: FieldPlaceholderStyle.Price
          }), width: 'auto'
        },
        { text: ' per', width: 18, fontSize: 12 },
        { ...generateCheckboxInlineTextLine(expenditurePeriods, expPeriod, undefined, 10, { baselineOffset: -1.2 }, { baselineOffset: -3.2 }) }
      ],
      margin: [0, 4, 0, 0]
    });

    sectionItems.push({
      columns: [
        {
          ...knownPair({
            fieldName: 'To end on',
            fieldValue: fieldBoolCheckboxes(endAgency, pressEndOpts.true, `${pressEndOpts.false}:`),
            fieldColons: true,
            isVariation
          })?.[0], width: 'auto'
        },
        {  margin: [0, 5.5, 0, 0], text: Predicate.boolFalse(endAgency) ? otherEnd : '​         ', decoration: 'underline', decorationCombine: true }
      ]
    });
  }

  return itemSubsection({
    subsectionTitle: subsectionTitles.periodicPress,
    subsectionContent: Predicate.boolFalse(enable) && Predicate.boolFalse(strike) ? notApplicableText() : spaceStackLinesSideEffect(sectionItems, 1),
    bookmark: ['subsection-periodic-press', ...fieldFocus('pressBudget')],
    otherStackAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    titleOverrideAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    isVariation
  });
}

export function adminFeeSection(adminFee?: AdminFeeType, isVariation?: boolean) {
  const { enable, strike, setPrice, templatePrice } = adminFee || {};
  const sectionItems = [];
  if (templatePrice === adminFeeDefaultMessage || !templatePrice) {
    sectionItems.push({ text: adminFeeDefaultMessage, margin: [0,fieldsSpacing,0,0] });
  } else {
    templatePrice && sectionItems.push({ stack: [], margin: [0,fieldsSpacing/2, 0,0] }, freeTextArea({
      title: undefined,
      content: templatePrice || '',
      linesIfEmpty: 3
    }));
  }

  sectionItems.push(confineWidthWithColumn(
    arbitraryFieldsRowTable({
      fields: [[[], setPrice], [[{ text: '(inc GST)', style: 'sectionText' }], []]],
      fieldColons: [false, false],
      fieldPlaceholder: FieldPlaceholderStyle.Price
    }), 195));

  return itemSubsection({
    subsectionTitle: subsectionTitles.adminFee,
    subsectionContent: Predicate.boolFalse(enable) && Predicate.boolFalse(strike) ? notApplicableText() : spaceStackLinesSideEffect(sectionItems),
    bookmark: 'subsection-admin-fee',
    otherStackAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    titleOverrideAttributes: Predicate.boolFalse(enable) && strike ? { decoration: 'lineThrough' } : undefined,
    isVariation
  });
}

export function auctionFeeSection(
  data: {
    saleMethod: string | undefined,
    auctionFee: string | undefined,
    auctionFeeSwitches: AuctionFeeSwitches | undefined
  },
  opts?: {isVariation?: boolean}) {
  const { isVariation } = opts ?? {};
  const { auctionFee, saleMethod, auctionFeeSwitches } = data ?? {};
  const { applicable, strike, feeDesc } = auctionFeeSwitches ?? {};
  const sectionItems = [];
  const definitelyNotAuction = (saleMethod && saleMethod !== 'auction') || (applicable === false && !strike);
  const emptyFields = !applicable;
  if (!definitelyNotAuction) {
    sectionItems.push({
      text: (applicable && feeDesc) || auctionFeeDefaultMessage,
      margin: [0, fieldsSpacing, 0, 0]
    });
    sectionItems.push(confineWidthWithColumn(
      arbitraryFieldsRowTable({
        fields: [[[], emptyFields ? '' : auctionFee], [[{ text: '(inc GST)', style: 'sectionText' }], []]],
        fieldColons: [false, false],
        isVariation,
        fieldPlaceholder: FieldPlaceholderStyle.Price
      }), 195));
  }

  return itemSubsection({
    subsectionTitle: subsectionTitles.auctionFee,
    subsectionContent: definitelyNotAuction ? notApplicableText() : spaceStackLinesSideEffect(sectionItems),
    bookmark: ['subsection-auction-fee', ...fieldFocus('auctionFee', 'auctionFeeSwitches')],
    otherStackAttributes: Predicate.boolFalse(applicable) && Predicate.truthyOrEmpty(strike) ? { decoration: 'lineThrough' } : undefined,
    titleOverrideAttributes: Predicate.boolFalse(applicable) && Predicate.truthyOrEmpty(strike) ? { decoration: 'lineThrough' } : undefined,
    isVariation
  });
}

export function transactionFee({ feeAmount, isVariation }: { feeAmount?: string; isVariation?: boolean }) {
  const subsectionContent = spaceStackLinesSideEffect([
    {
      text: 'The Greatforms Transaction Fee as specified below is payable to enable the Agent to utilise Greatforms’ premium legal documentation, compliance and signing platform on behalf of the Vendor.' ,
      margin: [0, fieldsSpacing, 0, 0]
    },
    confineWidthWithColumn(
      feeAmount != undefined
        ? arbitraryFieldsRowTable({
          fields: [[[], feeAmount], [[{ text: '(inc GST)', style: 'sectionText' }], []]],
          fieldColons: [false, false],
          isVariation,
          fieldPlaceholder: FieldPlaceholderStyle.Price
        })
        : arbitraryFieldsRowTable({
          fields: [[[], 'Nil'], [[], []]],
          fieldColons: [false, false],
          isVariation,
          fieldPlaceholder: FieldPlaceholderStyle.Price
        }),
      200
    )
  ],);

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.reaformsTransactionFee,
      subsectionContent,
      unbreakable: undefined,
      bookmark: ['subsection-transaction-fee', 'field_focus_transactionFee'],
      isVariation: false
    });
}

// This subsection should never be varied
export function form1HandlingSubsection(data: {searches: MaterialisedPropertyData['form1AndSearches']}) {
  const { whoPays, whoSearches } = data?.searches ?? { whoPays: 'vendor', whoSearches: undefined };
  const subsectionContent = [
    {
      text: 'Under the Act, the Vendor is required to serve a Form 1 on the Purchaser, and the Agent (or a person authorised to act on behalf of the Agent) must ensure the prescribed enquiries (searches) are made in relation to the Property and certify the Form 1.',
      margin: [0, fieldsSpacing, 0, 0]
    }
  ];

  if (whoPays === 'agent') {
    const checks = generateCheckboxRowsV2([
      { isSelected: whoSearches === 'eckermanns', label: 'Eckermann Property Forms to order searches and contact Vendor regarding Form 1 preparation, at Agent’s cost; OR' },
      { isSelected: whoSearches === 'agent', label: 'Agent to order searches and prepare Form 1, at Agent’s cost.' }
    ], { isNumbered: true, columns: 1 });
    subsectionContent.push(checks);
  } else {
    const checks = generateCheckboxRowsV2([
      { isSelected: whoSearches === 'eckermanns', label: 'Eckermann Property Forms to order searches and contact Vendor regarding Form 1 preparation, at Vendor’s cost;' },
      { isSelected: whoSearches === 'vendor', label: 'Vendor to arrange their own searches and Form 1 preparation, at Vendor’s cost; OR' },
      { isSelected: whoSearches === 'agent', label: 'Agent to arrange searches and Form 1 preparation, at Vendor’s cost. ' }
    ], { isNumbered: true, columns: 1 });
    subsectionContent.push(checks);
  }

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.form1AndSearches,
      subsectionContent: spaceStackLinesSideEffect(subsectionContent),
      unbreakable: undefined,
      bookmark: ['subsection-form1AndSearches-fee', 'field_focus_form1AndSearches.whoSearches'],
      isVariation: false
    });
}

export function feeSection(
  itemNo: number,
  marketingTemplate: MarketingTemplate,
  marketingFees?: MarketingTemplateItem[],
  pressBudget?: PressBudgetType,
  adminFee?: AdminFeeType,
  saleMethod?: string,
  auctionFee?: string,
  benefits?: DisclosureOfBenefitsType,
  brand?: EntityBrandFormConfig,
  reaformsTransactionFeeAmount?: string,
  otherData?: {
    form1AndSearches?: MaterialisedPropertyData['form1AndSearches']
    marketingFeesOptions?: MarketingFeesOptions,
    annexures: Annexure[],
    formCode: FormCode,
    auctionFeeSwitches: AuctionFeeSwitches | undefined
  },
  opts?: {isVariation?: boolean}
) {
  const { marketingFeesOptions, formCode, annexures, auctionFeeSwitches } = otherData ?? {};
  const { isVariation } = opts ?? {};
  const sectionItems = [];
  sectionItems.push({ text: 'In accordance with General Conditions 6, 7 and 11, the following Marketing and Other Costs are authorised and payable by the Vendor, regardless of whether the Property is sold by the Agent or not.', margin: [0, 0, 0, fieldsSpacing] });
  sectionItems.push(marketingTemplate?.enable ? rayWhiteMarketingTemplateSection(marketingTemplate, marketingFees, brand) : marketingCostSection({ marketingFees, marketingFeesOptions, formCode, annexures }, isVariation));
  sectionItems.push(pressSection(pressBudget, isVariation));
  sectionItems.push(adminFeeSection(adminFee, isVariation));
  sectionItems.push(auctionFeeSection({ saleMethod, auctionFee, auctionFeeSwitches }, { isVariation }));
  sectionItems.push(transactionFee({ feeAmount: reaformsTransactionFeeAmount }));
  sectionItems.push(form1HandlingSubsection({ searches: otherData?.form1AndSearches }));
  sectionItems.push(benefitsSection({ benefits }, { isVariation }));

  return itemSection({
    itemNo: itemNo,
    itemTitleParam: 'Marketing and Other Costs'.toUpperCase(),
    bookmark: 'bookmark_fees',
    stackContent: sectionItems,
    isVariation,
    pageBreakBefore: marketingTemplate?.enable || false
  });
}
