import {
  AuthorityParty,
  ContentType,
  MaterialisedPropertyData,
  SigningPartySourceType,
  TransactionMetaData
} from '@property-folders/contract';
import { SignatureSection } from '@property-folders/common/util/pdfgen/sections/signatureSection';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { Binder } from 'immer-yjs/src/immer-yjs';
import { FormUtil } from '@property-folders/common/util/form';
import { Maybe } from '@property-folders/common/types/Utility';
import {
  propertyFolderMaskDataNotRelevant,
  transformPropertyFolderDataForDisplay
} from '@property-folders/common/util/pdfgen/display-transformations';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import { DiffCollection } from '@property-folders/common/util/form/DiffCollection';
import { prepareData, saaCoverPage } from '@property-folders/common/util/pdfgen/definitions/documents/saa-cover-page';
import { PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import {
  residentialSalesAgreement
} from '../documents/residential-sales-agreement';
import { blobTob64 } from '@property-folders/common/util/dataExtract';
import { CustomObjects, DefinitionMode, IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { FormTypes } from '../../../../yjs-schema/property/form';
import { BelongingEntityMeta } from '../../../../redux-reducers/entityMeta';

export async function agencyLogoImageToString(image?: string | Blob) {
  if (!image) return undefined;
  if (typeof image === 'string') return image;
  if (!image.size) return undefined;

  const contentType = image.type || ContentType.Png;
  return `data:${contentType};base64,${await blobTob64(image)}`;
}

export class ResidentialSalesAgencyAgreementPdfDefinitionProvider implements IPdfDefinitionProvider {
  constructor(
    private dataBinder: Maybe<Binder<MaterialisedPropertyData>>,
    private metaBinder: Maybe<Binder<TransactionMetaData>>,
    private formCode: string,
    private formId: string,
    private debounce: boolean,
  ) { }

  shouldDebounce(): boolean {
    return this.debounce;
  }

  async getCoverPageDefinitionForPdfWorker(
    brand: EntityBrandFormConfig,
    objects?: CustomObjects
  ): Promise<any> {
    return {
      ...prepareData({
        agencyLogoImage: await agencyLogoImageToString(objects?.agencyLogoImage) ?? '',
        brand: brand,
        completedAtMs: objects?.completedAtMs,
        dataBinder: this.dataBinder,
        formCode: this.formCode,
        formId: this.formId,
        metaBinder: this.metaBinder
      }),
      formType: 'ResidentialSalesAgreementPDF' };
  }

  async getCoverPage(brand: EntityBrandFormConfig, objects?: CustomObjects): Promise<TDocumentDefinitions> {
    return saaCoverPage({
      agencyLogoImage: await agencyLogoImageToString(objects?.agencyLogoImage),
      brand: brand,
      completedAtMs: objects?.completedAtMs,
      dataBinder: this.dataBinder,
      formCode: this.formCode,
      formId: this.formId,
      metaBinder: this.metaBinder
    });
  }

  async getDefinitionForPdfWorker(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    _changeSet?: DiffCollection,
    _changeOriginal?: MaterialisedPropertyData,
    _snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta,
    opts?: {
      metaOverride: TransactionMetaData
    }
  ): Promise<PdfWorkerDocumentDefinition> {
    const { metaOverride } = opts??{};
    const useCoverPage = false;
    if (!(this.dataBinder && this.metaBinder)) {
      throw new Error('Cannot generate preview, data binders are not initialised');
    }

    const propertyRaw = propertyFolderMaskDataNotRelevant(this.dataBinder.get());
    const meta = metaOverride??this.metaBinder.get();
    const formMetadata = FormUtil.getFormState(this.formCode, this.formId, meta);
    const annexures = FormUtil.getAnnexures(this.formCode, this.formId, meta, { includeRestored: true }) ?? [];

    if (!formMetadata) {
      throw new Error('Cannot generate preview for nonexistent form');
    }

    const property = transformPropertyFolderDataForDisplay(propertyRaw) as MaterialisedPropertyData;

    const partyMap = new Map<SigningPartySourceType, AuthorityParty[]>();
    partyMap.set(SigningPartySourceType.Vendor, property?.vendors??[]);

    const signers = mode === DefinitionMode.Signing
      ? await SignatureSection.buildSignersFromSigningSession(formMetadata, propertyRaw)
      : await SignatureSection.buildSignersForPreview({
        authorityParties: partyMap,
        agents: property.agent?.map(agency=> (agency.salesp || []).map(sp=>({ ...sp, agency }))).flat(),
        propertyData: property,
        formCode: this.formCode,
        memberEntities
      });

    return {
      property,
      brand,
      signers,
      formInstance: undefined,
      objects,
      formType: 'ResidentialSalesAgreementPDF',
      annexures,
      noBoldContentMode: noBoldContentMode??false,
      printTitle: FormTypes[this.formCode]?.printTitle,
      memberEntities
    };
  }

  async getDefinition(
    mode: DefinitionMode,
    brand: EntityBrandFormConfig,
    agencyName: string,
    objects?: CustomObjects,
    changeSet?: DiffCollection,
    changeOriginal?: MaterialisedPropertyData,
    snapshotHistory?: InstanceHistory,
    noBoldContentMode?: boolean,
    memberEntities: BelongingEntityMeta
  ): Promise<TDocumentDefinitions> {
    const definition = await this.getDefinitionForPdfWorker(
      mode,
      brand,
      agencyName,
      objects,
      changeSet,
      changeOriginal,
      snapshotHistory,
      noBoldContentMode,
      memberEntities
    );
    return residentialSalesAgreement(definition);
  }
}
