import transparentPixel from '@property-folders/common/assets/transparent-pixel.js';
import logoSocietyOfAuctioneersAndAppraisersSaInc from '@property-folders/common/assets/logo-society-of-auctioneers-and-appraisers-sa-inc.js';
import * as PdfGen from '@property-folders/common/util/pdfgen';
import { contractConjunctionSummary } from '@property-folders/common/util/pdfgen/sections/contractConjunctionSection';
import { partySection } from '@property-folders/common/util/pdfgen/sections/vendorSection';
import { sectionTitles as st } from '@property-folders/common/util/pdfgen/doc-constants/sale-contract';
import { contractGstSection } from '@property-folders/common/util/pdfgen/sections/contractGst';
import {
  contractPriceSection
} from '@property-folders/common/util/pdfgen/sections/contract-price';
import { contractSettlementSection } from '@property-folders/common/util/pdfgen/sections/contractSettlement';
import { contractParticularsSection } from '@property-folders/common/util/pdfgen/sections/contractParticulars';
import { contractSpecialConditionsSection } from '@property-folders/common/util/pdfgen/sections/contractSpecialConditions';
import { generalConditionsForSaleContract } from '@property-folders/common/util/pdfgen/sections/saleContractGeneralConditions';
import { clausesSection } from '@property-folders/common/util/pdfgen/sections/clausesSection';
import { find } from 'lodash';
import reaformsLogo from '@property-folders/common/assets/reaforms-logo.js';
import { LandType, MaterialisedPropertyData, SaleMethod } from '@property-folders/contract';
import { PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import { buildSignatureSection } from '@property-folders/common/util/pdf-worker/buildSignatureSection';
import { formatBI } from '../../formatBI';
import { documentSubTitle } from '@property-folders/common/util/pdfgen';
import { formatAct } from '../../formatters/clauses';
import { LegalJurisdiction } from '../../constants';

export function residentialSalesContract({ property, propertyRaw, signers, objects, brand, formInstance, clausesId, annexures, options }: PdfWorkerDocumentDefinition) {
  const { templateContractMode } = options;

  const useCoverPage = false;
  const title = 'Contract of Sale' + (property.landType ?? 0 > 0 ? ` - ${Object.keys(LandType).find(k => LandType[k] === property.landType ?? 0)}` : '');

  const signatureSection = buildSignatureSection(signers, formInstance, { contractModeAndData: { saleMethod: property?.sale?.saleMethod } });

  // The section titles are all upper case, but we want this text to be 'normal' as it is also
  // used in other places.
  let vendorPartyLabel = st.vendors.title.toLocaleLowerCase();
  vendorPartyLabel = vendorPartyLabel[0].toLocaleUpperCase() + vendorPartyLabel.slice(1);
  let purchaserPartyLabel = st.purchasers.title.toLocaleLowerCase();
  purchaserPartyLabel = purchaserPartyLabel[0].toLocaleUpperCase() + purchaserPartyLabel.slice(1);

  const auctionMode = (propertyRaw as MaterialisedPropertyData).sale?.saleMethod === SaleMethod.Auction;

  return ({
    pageSize: 'A4',
    pageOrientation: 'portrait',
    ...(templateContractMode
      ? { watermark: { text: 'Template' , opacity: 0.1 } }
      : undefined
    ),
    images: {
      agencyLogo: objects?.agencyLogoImage || transparentPixel,
      societyAuctioneersLogo: logoSocietyOfAuctioneersAndAppraisersSaInc
    },
    content: [
      { stack: PdfGen.documentTitle(title, 1, brand, false), margin: [0, 0, 0, PdfGen.fieldsSpacing] },

      ...contractConjunctionSummary(property?.assocAgents, property?.authAssoc),
      PdfGen.itemSubsection({
        subsectionTitle: undefined, titleLineContent: undefined, subsectionContent: PdfGen.spaceStackLinesSideEffect([
          formatBI(
            `Notice to purchaser:\n\nThis is a contract for the sale of residential land. You may be bound by the terms of this contract if it is signed by both you and the vendor. You should seek independent legal advice if you unsure about the terms contained in this contract. It is advisable to check section 5 of the ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994', { noLegalJurisdiction: true })} regarding any cooling-off rights that you may have and how to exercise them.`,
            undefined,
            'noticeToPurchaser'
          ) as unknown as { [key: string]: any }, {
            text: 'The Vendor agrees to sell and the Purchaser agrees to buy the Property on the terms set out in this Contract.'
          }])
      }),
      documentSubTitle('SCHEDULE'),
      ...PdfGen.propertySection({
        itemNo: st.properties.num,
        addresses: Object.values(property?.saleAddrs ?? []),
        titles: property?.saleTitles ?? [],
        titleDivision: property?.titleDivision ?? {},
        annexures
      }),
      ...partySection(
        st.vendors.num,
        Object.values(property?.vendors ?? []),
        property?.saleAddrs,
        property?.primaryVendor,
        vendorPartyLabel,
        true,
        false,
        'vendors'
      ),
      ...partySection(
        st.purchasers.num,
        Object.values(property?.purchasers ?? []),
        property?.saleAddrs,
        property?.primaryPurchaser,
        purchaserPartyLabel,
        true,
        true,
        'purchasers'
      ),
      ...contractGstSection(st.gstSale.num, property?.vendorGst, property?.contractPrice),
      ...contractPriceSection(st.price.num, property?.vendorGst, property?.contractPrice, { templateContractMode }),
      ...contractSettlementSection(st.settlement.num, property?.contractSettlement, property?.sale),
      ...contractParticularsSection(
        st.particulars.num,
        property?.chattels,
        property?.exclusions,
        property?.saaTenant,
        property?.encroach,
        property?.notiWorks,
        property?.vendorWorks,
        property?.cgtEnable,
        property?.gstWithholdEnable,
        property?.pool,
        property?.contractSchedule?.noForm1NoCoolMatters,
        property?.contractPrice?.depositPayAt,
        property?.encroachEnable,
        property?.notiWorksEnable,
        property?.vendorWorksEnable,
        {
          tenantList: property?.tenantsCollect
        }
      ),
      ...contractSpecialConditionsSection(
        property?.contractSpecial,
        undefined,
        templateContractMode ? undefined : { addresses: property?.saleAddrs, titles: property?.saleTitles },
        { templateAuctionMode: templateContractMode && auctionMode, templateContractMode }

      ),
      ...clausesSection(9, find(property?.clausesByFamily, fam => fam.id === clausesId)?.clauses ?? [], brand, { data: property }),
      ...generalConditionsForSaleContract(brand),
      {
        unbreakable: false,
        stack: [
          { text: PdfGen.format('\n\n*EXECUTED* as an agreement') },
          ...signatureSection
        ]
      }
    ],
    pageMargins: (currentPage: number) => PdfGen.standardPageMargins(currentPage, useCoverPage),
    footer: (currentPage: number, pageCount: number) => PdfGen.standardFooter(currentPage, title, useCoverPage, pageCount, false, undefined, undefined, templateContractMode),
    header: (currentPage: number) => PdfGen.standardHeader(currentPage, title, useCoverPage, reaformsLogo, brand.agencyContact)
  });

}
