import { NoneSetOrRange, ProfessionalFeeType } from '@property-folders/contract';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { generateParentPath, getValueByPath, normalisePathToStrArray } from '../../../../util/pathHandling';

/**Enforces Prescribed Minimum Advertising Price and 10% range on advertised price
 *
 * input only fields: agentEstPrc, vendorPrc
 * input and output fields: advertPrc, advertPrcUpper
 * output only fields: cgtEnable
 */
export const setOneFeeMode: FieldGroupFn = (_fieldId, updatedPath, immerProxy) => {
  const value = getValueByPath(updatedPath, immerProxy, true);
  if (value !== NoneSetOrRange.None) return;

  const pathSegs = normalisePathToStrArray(updatedPath);
  const pathLeaf = pathSegs[pathSegs.length-1];
  const parentSegs = generateParentPath(pathSegs);
  const parentValue = getValueByPath(parentSegs, immerProxy, true) as ProfessionalFeeType;

  const alternatePathLeaf = pathLeaf === 'commissionMode' ? 'fixedMode' : 'commissionMode';

  if (parentValue[alternatePathLeaf] === NoneSetOrRange.None) {
    delete parentValue[alternatePathLeaf];
  }
};
