import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { getPathParentAndIndex, getValueByPath, normalisePathToStrArray } from '../../../../util/pathHandling';
import { Predicate } from '../../../../predicate';

/**Replace path segments with other for array IDs. In place replacement
 *
 * @param template
 * @param replaceSegments
 * @returns
 */
function hydratePathTemplateWithIDs (template: string[], replaceSegments: (string|null)[]) {
  if (template.length !== replaceSegments.length) throw new Error('Paths don\'t match');
  for (const i in template) {
    const replacement = replaceSegments[i];
    if (replacement === null) continue;
    if (!template[i].startsWith('[')) throw new Error('Paths don\'t match definition');
    template[i] = replacement;
  }
  return template;
}

/**Swap lower and upper values
 *
 * If the two paths exist under an array entry, the two paths must be in the same object as
 * properties
 *
 * @param lowerFieldTemplatePath
 * @param upperFiledTemplatePath
 * @returns
 */
const swapRangeValuesIfWrongFactory:
(lowerFieldTemplatePath: string, upperFiledTemplatePath: string, preprocessor?: <T>(input: T)=>number, postprocessor?: <T>(input: number)=>T) => FieldGroupFn =
(lowerFieldTemplatePath, upperFiledTemplatePath, preprocessor)=>
  (fieldId, updatedPath, immerProxy) => {
    const pathSpecifics = normalisePathToStrArray(updatedPath).map(segment=>segment.startsWith('[') ? segment : null);
    let pathLower = normalisePathToStrArray(lowerFieldTemplatePath);
    let pathUpper = normalisePathToStrArray(upperFiledTemplatePath);
    if (pathSpecifics.filter(Predicate.isNotNullish).length) {
      pathLower = hydratePathTemplateWithIDs(pathLower, pathSpecifics);
      pathUpper = hydratePathTemplateWithIDs(pathUpper, pathSpecifics);
    }
    const lowerRaw = getValueByPath(pathLower, immerProxy, true);
    const upperRaw = getValueByPath(pathUpper, immerProxy, true);

    const lowerNum = (preprocessor??parseFloat)(lowerRaw);
    const upperNum = (preprocessor??parseFloat)(upperRaw);

    if (isFinite(lowerNum) && isFinite(upperNum) && lowerNum > upperNum) {
      const { parent: parentLow, indexer: indexerLow } = getPathParentAndIndex(pathLower, immerProxy);
      const { parent: parentHigh, indexer: indexerHigh } = getPathParentAndIndex(pathUpper, immerProxy);
      parentHigh[indexerHigh] = lowerRaw;
      parentLow[indexerLow] = upperRaw;
    }
  };

export const swapFixedFeeRangeIfWrong = swapRangeValuesIfWrongFactory('professFee.fixed', 'professFee.fixedUpper');

export const swapCommissionFeeRangeIfWrong = swapRangeValuesIfWrongFactory(
  'professFee.commis',
  'professFee.commisUpper',
  (input: string)=> parseFloat(`${input}`.replace(/[^0-9-.]/g,''))
);