import {
  ExtractedField,
  ImageEncodedSigningParty,
  ImagePagePlacement,
  ImagePartyType,
  ImagePlacement,
  PageDimension
} from './types';
import {
  arrayAsString,
  PDFArray, PDFButton, PDFCheckBox,
  PDFDict,
  PDFDocument, PDFDropdown, PDFField,
  PDFHexString,
  PDFName,
  PDFNumber,
  PDFObject, PDFOptionList,
  PDFPage,
  PDFRadioGroup,
  PDFRawStream,
  PDFRef, PDFSignature,
  PDFStream,
  PDFString, PDFTextField,
  PDFWidgetAnnotation
} from 'pdf-lib';
import { Predicate } from '../../predicate';
import { inflate } from 'pako';
import { PartyCategory } from '../../yjs-schema/property/form';
import { CoordinateMath } from '../coords';

type Rectangle = ReturnType<PDFPage['getMediaBox']>;

export class PdfInformationExtractor {
  private doc?: PDFDocument;

  private async getDoc() {
    if (this.doc) {
      return this.doc;
    }
    this.doc = await PDFDocument.load(this.data);
    return this.doc;
  }

  constructor(
    public data: Uint8Array | ArrayBuffer
  ) {
  }

  public async getFieldPositions() {
    const doc = await this.getDoc();
    const form = doc.getForm();
    const pageCount = doc.getPageCount();

    // annotations can be references to form fields, or other things.
    // find out which pages annotations are referenced in
    const annotationsToPagesMap: { [key: string]: [PDFRef] } = {};

    for (let idxPage = 0; idxPage < pageCount; idxPage++) {
      const page = doc.getPage(idxPage);
      const rawAnnotations = page.node.Annots();
      if (!rawAnnotations) {
        continue;
      }
      const annotations = rawAnnotations.asArray();
      if (!annotations.length) {
        continue;
      }
      for (const annotation of annotations) {
        const { tag } = annotation as PDFRef;
        if (!tag) {
          continue;
        }
        annotationsToPagesMap[tag] = annotationsToPagesMap[tag] || [];
        annotationsToPagesMap[tag].push(page.ref);
      }
    }

    const results: ExtractedField[] = [];

    for (const field of form.getFields()) {
      // future, figure out if there's a way to map a widget to a page, i.e. page-independent rects.
      // in practice it may not matter, the pdf fields we generate belong in a single location.
      for (const widget of field.acroField.getWidgets()) {
        const fieldPages = annotationsToPagesMap[field.ref.tag] || [widget.P()].filter(Predicate.isNotNull);
        if (!fieldPages?.length) {
          throw new Error('Could not determine page(s) for pdf form field');
        }
        const fieldPageNums = fieldPages.map(page => doc.getPages().findIndex(x => x.ref === page));
        const id = field instanceof PDFRadioGroup
          ? getRadioOptionValue(field, widget)
          : field.getName();
        if (!id) {
          throw new Error('unexpected id');
        }
        results.push({
          id,
          positions: fieldPageNums.map(page => {
            return {
              ...this.getRotationAwareWidgetRect(widget, doc.getPage(page)),
              page
            };
          }),
          _debug: {
            type: getFieldType(field)
          }
        });
      }
    }

    return results;
  }

  /**
   * Return widget coordinates as though they're relative to the "bottom left" of the rotated page (not the page in its upright form)
   * Caller's expecting this sort of coordinate system so it can render buttons based on bottom/left, but it's placing them
   * on a rasterised image of the pdf page which is not rotation aware at all.
   * I hope that made sense...
   */
  private getRotationAwareWidgetRect(widgetAnnotation: PDFWidgetAnnotation, pdfPage: PDFPage): { x: number, y: number, width: number, height: number } {
    const degrees = CoordinateMath.normaliseDegrees(pdfPage.getRotation().angle);
    const widget = widgetAnnotation.getRectangle();
    const page = pdfPage.getSize();

    switch (degrees) {
      case 270:
        return CoordinateMath.absRect({
          y: widget.x,
          x: page.height - widget.y - widget.height,
          width: widget.height,
          height: widget.width
        });
      case 180:
        return CoordinateMath.absRect({
          y: page.height - widget.y - widget.height,
          x: page.width - widget.x - widget.width,
          width: widget.width,
          height: widget.height
        });
      case 90:
        return CoordinateMath.absRect({
          y: page.width - widget.x - widget.width,
          x: widget.y,
          width: widget.height,
          height: widget.width
        });
      case 0:
      default:
        return CoordinateMath.absRect(widget);
    }
  }

  public async getPageDimensions(): Promise<PageDimension[]> {
    const doc = await this.getDoc();
    const pageCount = doc.getPageCount();

    return [...new Array(pageCount).keys()].map(idxPage => {
      const page = doc.getPage(idxPage);
      const box = page.getMediaBox();
      const pageDims = CoordinateMath.normalisePage({ ...box, degrees: page.getRotation().angle });
      const { x, y } = box;
      const { width, height, degrees } = pageDims;
      return degrees === 90 || degrees === 270
        ? { x, y, width: height, height: width }
        : { x, y, width, height };
    });
  }

  public async getImagePagePlacements(): Promise<ImagePagePlacement[]> {
    const doc = await this.getDoc();
    // we'll use this to evaluate and store per-page image names for stream processing purposes
    const documentImageRefs = this.getDocumentImageRefs(doc);
    const documentSignatures = await this.getEncodedSignatureInformation();
    const finalPageToScan= Number(doc.getAuthor());
    const pagesToProcess = finalPageToScan ? doc.getPages()?.slice(0, finalPageToScan) : doc.getPages();

    return pagesToProcess
      .flatMap((page, index) => (
        this.getImagePlacementsForPage(page, documentImageRefs)
          .map(p => ({
            ...p,
            pageIndex: index,
            party: documentSignatures.find(s => s.name === p.name)?.party
          }))
      ));
  }

  public async getEncodedSignatureInformation(): Promise<{
    party: ImageEncodedSigningParty,
    ref: PDFRef,
    name: string
  }[]> {
    const doc = await this.getDoc();
    return doc.context.enumerateIndirectObjects()
      .map(([ref, obj], index) => {
        if (!(obj instanceof PDFStream)) return undefined;
        if (!('dict' in obj && obj.dict instanceof PDFDict)) return undefined;

        const dict = obj.dict;

        if (obj.dict.get(PDFName.Type) !== PDFName.XObject) return undefined;
        if (obj.dict.get(PDFName.of('Subtype')) !== PDFName.of('Image')) return undefined;

        const pixels = getStreamContents(obj);

        // encoded signature images are supposed to 1 byte, but for crystal report reasons sometimes it's safer to go full-size
        // if (pixels.length > 1) return undefined;
        dict.get(PDFName.of('SMask'));
        const colourSpace = doc.context.lookup(dict.get(PDFName.of('ColorSpace')));
        const width = doc.context.lookupMaybe(dict.get(PDFName.of('Width')), PDFNumber);
        const height = doc.context.lookupMaybe(dict.get(PDFName.of('Height')), PDFNumber);
        const name = doc.context.lookupMaybe(dict.get(PDFName.of('Name')), PDFName);
        const bitsPerComponent = doc.context.lookupMaybe(dict.get(PDFName.of('BitsPerComponent')), PDFNumber);
        const filter = doc.context.lookup(dict.get(PDFName.of('Filter')));

        if (!width?.asNumber()) return undefined;
        if (!height?.asNumber()) return undefined;
        if (!bitsPerComponent?.asNumber()) return undefined;
        // not interested in processing jpegs
        if ((filter instanceof PDFName && filter === PDFName.of('DCTDecode'))
          || (filter instanceof PDFArray && filter.asArray().some(f => f === PDFName.of('DCTDecode')))) return undefined;

        const party = this.getEncodedSignatureInformationForImage({
          doc,
          pixels,
          colourSpace
        });

        if (!party) return undefined;

        return {
          party,
          name: name ? name.asString() : `Object${index}`,
          ref
        };
      })
      .filter(Predicate.isNotNull);
  }

  public getNamedObjects(page: PDFPage) {
    const objs: {[key:string]: PDFObject} = {};
    page.node.normalizedEntries().XObject.entries()
      .map(([ref, obj], index) => {

        if (obj instanceof PDFRef) obj = page.doc.context.lookup(obj);
        objs[ref.toString()] = obj;

      })
      .filter(Predicate.isNotNull);
    return objs;
  }

  private getEncodedSignatureInformationForImage({
    doc,
    pixels,
    colourSpace
  }: {
    doc: PDFDocument,
    pixels: Uint8Array,
    colourSpace?: PDFObject,
  }): ImageEncodedSigningParty | undefined {
    const isDeviceRGB = hasName(colourSpace, PDFName.of('DeviceRGB'));
    const isIndexed = hasName(colourSpace, PDFName.of('Indexed'));

    if (isIndexed && isDeviceRGB) {
      return this.getEncodedSignatureInformationForIndexedRgbImage({
        pixels,
        colourIndex: extractColourIndex(doc, colourSpace)
      });
    }

    return undefined;
  }

  private getEncodedSignatureInformationForIndexedRgbImage({
    pixels,
    colourIndex
  }: {
    pixels: Uint8Array,
    colourIndex: ColourIndex
  }): ImageEncodedSigningParty | undefined {
    if (!colourIndex.length) return undefined;
    if (!pixels.length) return undefined;

    const idxHeader = pixels[0];
    const header = colourIndex.at(idxHeader);

    if (!header) return undefined;

    const version = 255 - header.b;
    if (version !== 1) {
      console.log('unexpected version', version);
      return undefined;
    }

    // since we can have non-single-byte signatures we should confirm every pixel is the same.
    if (pixels.some(idxColour => idxColour !== idxHeader)) {
      return undefined;
    }

    // v1 processing
    const type = mapImagePartyTypeToCategory((255 - header.r) as ImagePartyType);
    const number = 255 - header.g;

    if (!type) return undefined;

    return {
      type,
      number
    };
  }

  private getDocumentImageRefs(doc: PDFDocument) {
    return doc.context.enumerateIndirectObjects()
      .map(([ref, obj]) => {
        if (!('dict' in obj && obj.dict instanceof PDFDict)) return undefined;
        if (obj.dict.get(PDFName.Type) !== PDFName.XObject) return undefined;
        if (obj.dict.get(PDFName.of('Subtype')) !== PDFName.of('Image')) return undefined;

        return ref;
      })
      .filter(Predicate.isNotNull);
  }

  private getPageImageNames(page: PDFPage, documentImageRefs: PDFRef[]) {
    const images = this.getPageImageNamesInner(page.node.normalizedEntries().XObject, documentImageRefs);
    return new Set(images);
  }

  private getPageImageNamesInner(object: PDFDict, documentImageRefs: PDFRef[]) {
    if (!object) return [];
    return object.entries().flatMap(([key, value]) => {
      if (value instanceof PDFRef && documentImageRefs.find(ref => ref === value)) return key.toString();
      let obj: PDFObject | undefined = value;
      if (key.toString()==='/Font') return [];
      if (value instanceof PDFRef) obj = object.context.lookup(value);
      if (obj instanceof PDFDict) return this.getPageImageNamesInner(obj as PDFDict, documentImageRefs);
      if (obj instanceof PDFRawStream) return this.getPageImageNamesInner((obj as PDFRawStream)?.dict, documentImageRefs);
    }).filter(Predicate.isNotNull);
  }

  private getImagePlacementFromQData(qData: string[], box: Rectangle): ImagePlacement | undefined {
    if (qData.length !== 2) {
      console.error('Unsupported qData', qData);
      return undefined;
    }

    const matrixValues = qData[0]
      .split(/\s+/)
      .map(s => s.trim())
      .filter(s => !!s);
    const nameResult = /(\/\S+)\s+Do/.exec(qData[1]);
    if (!(nameResult && nameResult[1])) {
      console.error('No object name found in qData', { qData, nameResult });
      throw new Error('No object name found in qData');
    }

    if (matrixValues.length !== 7) {
      console.error('Unexpected matrix', matrixValues);
      throw new Error('Unexpected matrix');
    }
    const [sWidth, _, __, sHeight, sLeft, sBottom] = matrixValues;
    const width = parseInt(sWidth, 10);
    const height = parseInt(sHeight, 10);
    const left = parseInt(sLeft, 10);
    const bottom = parseInt(sBottom, 10);

    return {
      name: nameResult[1],
      width,
      height,
      x: left,
      // bottom is a negative value, so it needs to be adjusted back into a real y value for later usage
      y: box.height + bottom
    };
  }

  private getImagePlacementsForStreamData(data: string, imageNames: Set<string>, box: Rectangle): ImagePlacement[] {
    const qData = this.extractQData(data);
    const imageQData = qData.filter(lines => {
      return lines.some(line => line
        .split(' ')
        .some(token => imageNames.has(token.trim())));
    });

    return imageQData.map(item => {
      return this.getImagePlacementFromQData(item, box);
    })?.filter(Boolean) as ImagePlacement[];
  }

  /**
   * get all sequences of data between starting 'q' and ending 'Q' literals
   */
  private extractQData(decodedStream: string): string[][] {
    const lines = decodedStream.split('\n').map(s => s.trim());
    const result: string[][] = [];
    let current: string[] | undefined = undefined;
    for (const line of lines) {
      switch (line) {
        case 'q':
          current = [];
          break;
        case 'Q':
          if (current) {
            result.push(current);
          }
          current = undefined;
          break;
        default:
          if (current) {
            current.push(line);
          }
          break;
      }
    }

    return result;
  }

  //Merge all sub-streams into the main stream - ignoring any sub-stream references in the doNotMergeList
  //Note - This is not recursive
  private getMergedStream(stream: PDFStream | PDFRawStream, namedObjects: { [x: string]: PDFObject; }, doNotMergeList: Set<string>) {
    const streamContents = getStreamContents(stream);
    const decoded = arrayAsString(streamContents);

    const replaced = decoded.replace(/(\/\S*)\s*Do\W/i, (token, key) => {
      if (doNotMergeList.has(key)) return token;
      const replacement = namedObjects[key];
      if (!(replacement instanceof PDFStream) && !(replacement instanceof PDFRawStream)) return '';
      return arrayAsString(getStreamContents(replacement));
    });

    return replaced;
  }

  private getImagePlacementsForStream(stream: PDFStream | PDFRawStream, imageNames: Set<string>, box: Rectangle, namedObjects: { [x: string]: PDFObject; }) {
    const finalStream = this.getMergedStream(stream, namedObjects, imageNames);
    return this.getImagePlacementsForStreamData(
      finalStream,
      imageNames,
      box
    );
  }

  private getImagePlacementsForPage(page: PDFPage, documentImageRefs: PDFRef[]): ImagePlacement[] {
    const contents = page.node.Contents();
    if (!contents) return [];

    const imageNames = this.getPageImageNames(page, documentImageRefs);
    const namedObjects = this.getNamedObjects(page);
    const box = page.getMediaBox();
    if (!imageNames.size) {
      return [];
    }

    if (contents instanceof PDFStream) {
      return this.getImagePlacementsForStream(contents, imageNames, box, namedObjects);
    }

    return [...new Array(contents.size()).keys()]
      .map(index => contents.lookup(index))
      .flatMap(content => {

        if (!content) return [];
        if (content instanceof PDFRef) content = page.doc.context.lookup(content);
        if (content instanceof PDFStream) {
          return this.getImagePlacementsForStream(content, imageNames, box, namedObjects);
        }
        console.warn('encountered unexpected content', content);
        return [];
      });
  }
}

function hasName(obj: PDFObject | undefined, name: PDFName) {
  if (obj === name) return true;
  if (!(obj instanceof PDFArray)) return false;

  return obj.asArray().some(x => x === name);
}

function extractColourIndex(doc: PDFDocument, colourSpace: PDFObject | undefined) {
  if (!(colourSpace instanceof PDFArray)) return [];
  const items = colourSpace.asArray();
  const lookupRef = items[3]; // PDFReference;
  const colorSpace = doc.context.lookup(lookupRef);
  if (!colorSpace) return [];

  let data: Uint8Array | never[];
  if (colorSpace instanceof PDFStream) {
    data = getStreamContents(colorSpace);
  } else if (colorSpace instanceof PDFHexString) {
    data = new Uint8Array(colorSpace.sizeInBytes());
    colorSpace.copyBytesInto(data, 0);
  } else {
    return [];
  }

  const colourIndex = [];

  for (let i = 0; i < data.length; i += 3) {
    colourIndex.push({ r: data[i], g: data[i + 1], b: data[i + 2] });
  }

  return colourIndex;
}

type ColourIndex = ReturnType<typeof extractColourIndex>;

function getStreamContents(stream: PDFStream) {
  if (stream instanceof PDFRawStream) {
    try {
      return inflate(stream.getContents());
    } catch { /**/
    }

    try {
      return inflate(stream.getContents(), { raw: true });
    } catch { /**/
    }

    return [];
  }

  return stream.getContents();
}

function mapImagePartyTypeToCategory(type: ImagePartyType): PartyCategory {
  switch (type) {
    case ImagePartyType.Vendor:
      return 'vendor';
    case ImagePartyType.Purchaser:
      return 'purchaser';
    case ImagePartyType.Agent:
      return 'agent';
    case ImagePartyType.Unknown:
    default:
      throw new Error(`mapImagePartyTypeToCategory - Unsupported image party type ${type}`);
  }
}

function getRadioOptionValue(field: PDFRadioGroup, widget: PDFWidgetAnnotation) {
  const opt = field.acroField.Opt();
  const onValue = widget.getOnValue();
  if (!opt) {
    console.error('radio group has no options');
    return undefined;
  }
  if (!onValue) {
    console.error('widget has no onValue');
    return;
  }

  if (opt instanceof PDFArray) {
    const indexRaw = onValue.decodeText();
    const index = parseInt(indexRaw, 10);
    if (isNaN(index) || !isFinite(index)) {
      console.error('could not parse onValue', onValue);
      return undefined;
    }
    const value = opt.get(index);
    if (value instanceof PDFString || value instanceof PDFHexString) {
      return value.decodeText();
    }

    console.error('could not determine option value', value);
    return undefined;
  } else {
    const singleOption = opt.decodeText();
    console.error('todo: not yet implemented properly', singleOption, onValue);
    return singleOption;
  }
}

function getFieldType(field: PDFField) {
  if (field instanceof PDFButton) return 'PDFButton';
  if (field instanceof PDFCheckBox) return 'PDFCheckBox';
  if (field instanceof PDFDropdown) return 'PDFDropdown';
  if (field instanceof PDFOptionList) return 'PDFOptionList';
  if (field instanceof PDFRadioGroup) return 'PDFRadioGroup';
  if (field instanceof PDFSignature) return 'PDFSignature';
  if (field instanceof PDFTextField) return 'PDFTextField';
  return 'PDFField';
}
