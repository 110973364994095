import { validate as isUUID } from 'uuid';
import { findIndex, find, uniq } from 'lodash';
import { Predicate } from '../../../predicate';
import { canonicalisers } from '../../../util/formatting';
import { getValidationValue, getValueByPath, hierarchyMap, normalisePathToStrArray } from '../../../util/pathHandling';
import { complexValidatorsDirectory } from './complex-validators';
import { expectedValueEvaluatorDirectory } from './expected-evaluator';
import {
  ComplexValidationContext,
  ComplexValidationReturnType
} from '@property-folders/contract/yjs-schema/model/complex-validator';
import { DocumentFieldType, ExpectedValuePathDefn, MultipleExpectedValuePathDefn, PathSegments, PathType, ValidationDefnType, atLeastObjDefn } from '@property-folders/contract/yjs-schema/model';
import { getArrayMemberId } from '../../../util/dataExtract';
import { composeErrorPathClassName } from '../../../util/formatting';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { customValidatorsDirectory } from './custom-point-validators';
import { EMAIL_REGEX } from '../../../data-and-text/regex';

export const NATURAL_NUMBER_REGEX = /^(0|([1-9][0-9]*))$/; // Yes I know 0 is a disputed natural number, but this is also the set of valid numerical indicies

export class ValidationRuntimeContext {
  public static enableLogging = false;
}

export const testEmail = (potentialEmail: any) => {
  if (typeof potentialEmail === 'string') {
    if (potentialEmail.includes(' ')) {
      return false;
    }
    return EMAIL_REGEX.test(potentialEmail);
  } else {
    return false;
  }
};

export const testPhone = (potentialPhone: any) => {
  if (typeof potentialPhone === 'string') {
    return canonicalisers.phone(potentialPhone).valid;
  } else {
    return false;
  }
};

type MapSubs = string|boolean|number|MapType|MapSubs[];

type MapType = {
  [nodeKey: string]: MapSubs | undefined
};

export enum ErrorSource {
  Unknown,
  Required,
  RequiredAtLeastMember,
  InvalidData,
  InvalidDefintion,
  ChildrenInvalid,
  DataTypeMismatch,
  ListMinimum,
  ListItemId,
  Complex,
  ChildrenInvalidSpecificFlag,
  BadValidationResult,
  Custom
}

type ErrorFocus = {
  focusTarget?: string // should be a class, if multiple, we'll focus on the first, we're already only focussing on the first instance
  errorPaths?: PathSegments[]
  errorSource: ErrorSource
  errorCode: string // error code is what goes into the errors array
};

type ValidationConstruct = {
  valid: boolean
  errors: string[]
  errorFocus: ErrorFocus[]
};
export type ValidationNode = {[nodeKey: string]: ValidationNode|any, _validationResult: ValidationConstruct};

function interpretPathForValidityAndValue (pathSpec: PathType, mutableValidationNode: ValidationNode, localNode: MapType) {
  let nPathSpec: string[] | undefined;
  if (typeof pathSpec === 'string') {
    nPathSpec = pathSpec.split('.');
  } else if (Array.isArray(pathSpec)) {
    const stringyList = pathSpec.filter(Predicate.isString);
    stringyList.length === pathSpec.length && (nPathSpec = pathSpec);
  }
  if (nPathSpec) {
    if (nPathSpec.length > 1 && nPathSpec[0] === '') {
      // Relative path
      nPathSpec = nPathSpec.slice(1);

      const validHere = getValidationValue(nPathSpec, mutableValidationNode, localNode)?._validationResult?.valid;
      const valueHere = getValueByPath(nPathSpec.join('.'), localNode, true);

      if (validHere && valueHere !== undefined && (typeof valueHere === 'boolean' || valueHere)) {
        return true;
      } else {
        return false;
      }
    } else {
      // The reason we currently only do relative paths is because we are
      // haven't yet finished calculating the entire tree, but we should have
      // completed the rest for this node, and the results for this should lie
      // within thisLevelRet
      console.error('_atLeastDefns do not currently support absolute paths. Relative paths are specified with an empty prefix ala \'.attribute.node\' or [\'\', \'attribute\', \'node\'] ');
      mutableValidationNode._validationResult.errors.push('badAtLeastDefn');
      mutableValidationNode._validationResult.errorFocus.push({ errorCode: 'badAtLeastDefn', errorSource: ErrorSource.InvalidDefintion });
      return false;
    }
  }
}

function pathToAbsolute(path: PathType, baseNodePath: PathSegments) {
  let absPath = normalisePathToStrArray(path);
  if (absPath[0] === '' || absPath[0] === '.') {
    absPath = [...baseNodePath, ...absPath.slice(1)];
  }
  if (absPath[0] === '..') {
    const workingAbsPath = [...absPath];
    const workingNodeParent = [...baseNodePath];
    while (workingAbsPath[0] === '..') {
      workingNodeParent.splice(workingNodeParent.length-1,1);
      workingAbsPath.splice(0,1);
    }
    absPath = [...workingNodeParent, ...workingAbsPath];
  }
  return absPath;
}
export function processExpectedValue(
  defnParam: ExpectedValuePathDefn|MultipleExpectedValuePathDefn,
  nodePath: PathSegments,
  fullDataTree: MapType,
  context?: ComplexValidationContext | undefined,

) {
  let modeOrTrueAndFalse = false;
  let notTheResult = false;
  let defns: ExpectedValuePathDefn[] = [];
  if ('expectations' in defnParam) {
    defns = defnParam.expectations;
    if (defnParam.modeOrTrueAndFalse) modeOrTrueAndFalse = defnParam.modeOrTrueAndFalse;
    notTheResult = !!defnParam?.modeNot;
  } else if (Array.isArray(defnParam)) {
    defns = defnParam;
  } else {
    defns = [defnParam];
  }
  const andMode = !modeOrTrueAndFalse;
  const orMode = !!modeOrTrueAndFalse;

  for (const defn of defns) {
    let result = false;
    const absPath = pathToAbsolute(defn.field, nodePath);

    const valueHere = getValueByPath(absPath, fullDataTree, true);

    if ('evaluationFunction' in defn) {

      const evaluation = expectedValueEvaluatorDirectory[defn.evaluationFunction]?.(valueHere, context, { nodePath });
      if (evaluation == null) {
        console.warn('Evaluation not found or undefined', defn.evaluationFunction);
      }
      result = !!evaluation;
    } else if ('oneOfExpectedValue' in defn) {
      result = defn.oneOfExpectedValue.includes(valueHere);
    } else if ('notOneOfExpectedValue' in defn) {
      result = !defn.notOneOfExpectedValue.includes(valueHere);
    } else if ('notExpectedValue' in defn) {
      result = valueHere !== defn.notExpectedValue;
    } else if ('empty' in defn && defn.empty) {
      result = valueHere === '' || valueHere == null;
    } else if ('empty' in defn && defn.empty === false ) {
      result = valueHere !== '' && valueHere != null;
    } else if ('matchesValueAt' in defn) {
      const otherValue = getValueByPath(pathToAbsolute(defn.matchesValueAt, nodePath), fullDataTree, true);
      result = valueHere === otherValue;
    } else if ('expectedValue' in defn) {
      result = valueHere === defn.expectedValue;
    } else {
      console.warn('Could not understand expectation, requiring');
      return true;
    }

    if (orMode && result) {
      return notTheResult ? false : true;
    }
    if (andMode && !result) {
      return notTheResult ? true : false;
    }
  }
  if (modeOrTrueAndFalse) {
    return notTheResult ? true : false;
  } else {
    return notTheResult ? false : true;
  }

}

// Currently only handles relative paths, ie '.path.segments'
function atLeastOneOf(atLeastDefn: atLeastObjDefn, thisLevelRet: ValidationNode, node: MapType): ErrorFocus | undefined {

  if (atLeastDefn && Array.isArray(atLeastDefn.fields)) {
    if (
      !atLeastDefn.ifFieldEquals
          || (getValueByPath(atLeastDefn.ifFieldEquals.field, node) === atLeastDefn.ifFieldEquals.expectedValue)
          || (typeof atLeastDefn.ifFieldEquals.expectedValue === 'function' && atLeastDefn.ifFieldEquals.expectedValue(getValueByPath(atLeastDefn.ifFieldEquals.field, node)))
    ) {
      const resultList = atLeastDefn.fields.map(pathSpec=>interpretPathForValidityAndValue(pathSpec, thisLevelRet, node));
      const anyValid = resultList.filter(a=>a).length > 0;
      if (!anyValid) {
        const errorPaths = atLeastDefn.fields.map(relpath=>{
          const pathSegs = Array.isArray(relpath)?relpath:relpath.split('.');
          const pathSegResult = [];
          let workingNode: any = node;
          for (const seg of pathSegs) {
            if (seg.startsWith('[')) {
              const memberId = getArrayMemberId(seg, workingNode);
              workingNode = find(workingNode, member=>member?.id === memberId);
              pathSegResult.push(`[${memberId}]`);
              continue;
            }
            workingNode = node?.[seg];
            pathSegResult.push(seg);
          }
          return pathSegResult;
        });
        return {
          errorCode: 'missingSomeType',
          errorSource: ErrorSource.RequiredAtLeastMember,
          errorPaths: errorPaths,
          focusTarget: atLeastDefn.focusTarget
        };
      }
      return;
    }
    // We should only get here if ifFieldEquals means there's no check. Which means it's valid
    return;

  } else {
    return { errorCode: 'badAtLeastDefn', errorSource: ErrorSource.InvalidDefintion };
  }

}

// Currently only handles relative paths, ie '.path.segments'
function atLeastOneOfArray(arrayOfRequirements: atLeastObjDefn[], thisLevelRet: ValidationNode, node: MapType): ErrorFocus[] {
  if (Array.isArray(arrayOfRequirements)) {
    return arrayOfRequirements.map(defn=>atLeastOneOf(defn, thisLevelRet, node)).filter(Predicate.isNotNullish) ?? [];
  } else {
    return [{ errorCode: 'badAtLeastDefn', errorSource: ErrorSource.InvalidDefintion }];
  }
}

export const simpleValidationMethods: Record<string, undefined | ((value: any, compositeDefinition?: Record<string, any>)=>boolean)> = {
  'required': (value: any, compositeDefinition) => {
    if (compositeDefinition?._type === 'boolean') {
      return typeof value === 'boolean';
    } else {
      return Boolean(value);
    }
  }
};

export const runValidationMethods = (value: any, validationTypes: string[], compositeDefinition?: Record<string, any>) => {
  const errorKeys: string[] = [];
  if (!value) {
    return { isValid: true, errorKeys, errorFocus: [] };
  }
  const checks = validationTypes.map(validation => {
    const validationMethod = simpleValidationMethods[validation];
    const canonicaliser = canonicalisers[validation];
    if (validationMethod) {
      if (compositeDefinition && validationMethod(value, compositeDefinition)) {
        return true;
      } else {
        errorKeys.push(validation);
        return false;
      }
    } else if (canonicaliser) {
      if (canonicaliser(value, { validationOnly: true }).valid) {
        return true;
      } else {
        errorKeys.push(validation);
        return false;
      }
    } else if (validation.startsWith('enum_')) {
      return true;
    } else {
      errorKeys.push('validationNotKnown');
      return false;
    }
  });
  return { isValid: checks.reduce((acc,cv)=>acc&&cv,true), errorKeys, errorFocus: errorKeys.map(st=>({ errorSource: ErrorSource.InvalidData, errorCode: st })) };
};

/**Either creates new validation tree, or updates an existing one inplace
 *
 * Because this updates inplace, it is recommended to use a framework such as immer to prevent
 * mutations of old state.
 *
 * This method should only produce side effects if the fullUpdatedPath and existingValidationTree params
 * are provided.
 *
 * When inserting or deleting elements with fullUpdatedPath, specify the index of the added item, or
 * removed item. If you're appending to an array, err, well I'm not really sure how to handle that.
 * I also hve a concern that a new map node with ID won't flag its ID field as new and won't
 * be adequately checked. The testing isn't showing this though, so not 100% sure what's going on.
 *
 * When in doubt, call this without the fullUpdatedPath and existingValidationTree to revalidate the
 * entire form.
 *
 * @param node The current node being tested
 * @param fieldsDefinitionAtNode
 * @param formRequirementsAtNode
 * @param thisPath
 * @param fullUpdatedPath
 * @param existingValidationTree The entire validation tree (not current node). Needed because we
 *  need to be able to update objects in place
 * @param complexValidatorErrorPaths Paths that have specially crafted errors to add to them as for whatever reason, they can't
 *  be calculated at their node (eg they access fields above themselves but the error needs to
 *  exist on the field itself). Technically nodes have access to the entire data tree, but the use
 *  case this was invented for also needs to update other field validations as their requirements
 *  are loosened due to a central value change. see @link vendorEmailRequiredIfPrimary
 * @returns
 */
const validateTree = (
  node: MapSubs | undefined,
  completeDataTree: MaterialisedPropertyData,
  fieldsDefinitionAtNode: DocumentFieldType,
  formRequirementsAtNode: ValidationDefnType,
  thisPath: string[] = [],
  fullUpdatedPath: string[] | undefined,
  existingValidationTree: ValidationNode | undefined,
  complexValidatorErrorPaths: {[stringPath:string]: string[]} | undefined,
  context: ComplexValidationContext | undefined
) => {
  let thisLevelRetPre: ValidationNode | {[nodeKey: string]: any} = {};
  const remainingPath = fullUpdatedPath?.slice(thisPath.length);
  let fullTreeRevalidationRequired = false;
  if (fullUpdatedPath && existingValidationTree) {
    // Invalidate results along this tree branch path only
    thisLevelRetPre = existingValidationTree;
    for (const segment of thisPath ?? []) {
      if (!(segment in thisLevelRetPre)) {
        thisLevelRetPre[segment] = {
          _validationResult: {
            valid: false,
            errors: [],
            errorFocus: []
          }
        };

      }
      thisLevelRetPre = thisLevelRetPre[segment];
    }
  } else {
    fullTreeRevalidationRequired = true;
  }

  // Fairly important that we create a new object here, as this is called from redux and we should
  // not be mutating existing state
  let thisLevelRet: ValidationNode = {
    ...thisLevelRetPre,
    _validationResult: {
      valid: false,
      errors: [],
      errorFocus: []
    }
  };
  if (complexValidatorErrorPaths && thisPath.join('.') in complexValidatorErrorPaths) {
    const codeRes = complexValidatorErrorPaths[thisPath.join('.')];
    thisLevelRet._validationResult.errors.push(...codeRes);
    thisLevelRet._validationResult.errorFocus.push(...codeRes.map(code=>({ errorCode: code, errorSource: ErrorSource.Complex })));
  }

  // Complex validators should be calculated before anything else, as they may change recursive validation behaviour
  // Checking for the presence of evaluated validator results means this is only calculated at the
  // top level.
  if (!complexValidatorErrorPaths && thisPath.length === 0 && Array.isArray(formRequirementsAtNode?._complexValidators)) {
    complexValidatorErrorPaths = {};
    const forceUpdateList = [];
    for (const validatorFuncDesc of formRequirementsAtNode._complexValidators) {
      const validatorFuncName = (validatorFuncDesc && typeof validatorFuncDesc === 'object' ? validatorFuncDesc.type : validatorFuncDesc) || '';
      const validatorParams = validatorFuncDesc && typeof validatorFuncDesc === 'object' ? validatorFuncDesc.params : undefined;
      const validatorFunction = complexValidatorsDirectory[validatorFuncName];
      if (!validatorFunction) {
        console.warn('Validator function name not linked', validatorFuncName, fullUpdatedPath);
        continue;
      }
      const { errorObj, forceUpdatesOn } = validatorFunction(completeDataTree, fullUpdatedPath, context, validatorParams) as ComplexValidationReturnType;
      forceUpdateList.push(...forceUpdatesOn);
      for (const errKey in errorObj) {
        if (errKey in complexValidatorErrorPaths) {
          complexValidatorErrorPaths[errKey].push(...errorObj[errKey]);
        } else {
          complexValidatorErrorPaths[errKey] = errorObj[errKey];
        }
      }
    }
    if (!fullTreeRevalidationRequired) {
      for (const forcePath of forceUpdateList) {
        // This is probably the only time we pass in the existing 'thisLevelRet' instead of the old
        // 'existingValidationTree' as we are rebuilding multiple parts of the tree, and we want to
        // collect all those changes. For other use cases, we're only updating one part of the tree.

        thisLevelRet = validateTree(completeDataTree, completeDataTree, fieldsDefinitionAtNode, formRequirementsAtNode ?? {}, [], forcePath.split('.'), thisLevelRet, complexValidatorErrorPaths, context);
      }

    }
  }

  if (typeof fieldsDefinitionAtNode._type !== 'string') {
    thisLevelRet._validationResult.valid = false;
    thisLevelRet._validationResult.errors.push('badFieldDefinition');
    thisLevelRet._validationResult.errorFocus.push({ errorCode: 'badFieldDefinition', errorSource: ErrorSource.InvalidDefintion });
  } else if (fieldsDefinitionAtNode._type === 'Map') {
    if ((node && typeof node === 'object' && !Array.isArray(node)) || node == null) {
      let allValid = true;
      for (const fieldKey in fieldsDefinitionAtNode) {
        if (!fieldKey.startsWith('_') || fieldKey === '_metaTree') {
          const switchToMeta = fieldKey === '_metaTree';
          if (switchToMeta && !context?.propertyMeta) {
            console.warn('No metadata tree was provided, but validation definition expects meta validation fields');
            continue;
          }
          const localCompleteTree = switchToMeta ? context.propertyMeta : completeDataTree;
          const localNextNode = switchToMeta ? context.propertyMeta : node?.[fieldKey];
          if (!fullTreeRevalidationRequired && remainingPath && remainingPath.length>0 && remainingPath[0] !== fieldKey) {
            // Result should already exist, so we should leave it
            // We want to also do the alternative if we are not in inplace update mode
          } else {
            const workingField = fieldsDefinitionAtNode[fieldKey];
            const workingFormField = formRequirementsAtNode?.[fieldKey];
            thisLevelRet[fieldKey] = validateTree(localNextNode, localCompleteTree, workingField, workingFormField ?? {}, [...thisPath, fieldKey], fullUpdatedPath, existingValidationTree, complexValidatorErrorPaths, context);
          }
          allValid = allValid && thisLevelRet[fieldKey]?._validationResult?.valid;
        }
      }
      const mergedDefn = { ...fieldsDefinitionAtNode, ...formRequirementsAtNode };
      for (const requirementKey in mergedDefn as ValidationDefnType & DocumentFieldType) {
        if (requirementKey.startsWith('_')) {
          const fieldSpecifics = mergedDefn[requirementKey];

          switch (requirementKey) {
            case '_atLeastDefns': {

              const errorNodes = atLeastOneOfArray(fieldSpecifics, thisLevelRet, node);
              thisLevelRet._validationResult.errors.push(...errorNodes.map(focus=>focus.errorCode));
              thisLevelRet._validationResult.errorFocus.push(...errorNodes);
              break;
            }
            case '_childErrorFlagFocus': {

              if (!allValid) {
                thisLevelRet._validationResult.errors.push('childrenInvalidFocus');
                thisLevelRet._validationResult.errorFocus.push({ errorCode: 'childrenInvalidFocus', errorSource: ErrorSource.ChildrenInvalidSpecificFlag, focusTarget: fieldSpecifics.focusTarget });
              }
              break;
            }
            default:
          }
        }
      }
      if (allValid) {
        thisLevelRet._validationResult.valid = thisLevelRet._validationResult.errors.length === 0;
      } else {
        thisLevelRet._validationResult.valid = false;
        thisLevelRet._validationResult.errors.push('childrenInvalid');
        thisLevelRet._validationResult.errorFocus.push({ errorCode: 'childrenInvalid', errorSource: ErrorSource.ChildrenInvalid });
      }
    } else {
      thisLevelRet._validationResult.valid = false;
      thisLevelRet._validationResult.errors.push('typeMismatch');
      thisLevelRet._validationResult.errorFocus.push({ errorCode: 'typeMismatch', errorSource: ErrorSource.DataTypeMismatch });
    }
  } else if (fieldsDefinitionAtNode._type === 'Array') {
    const workingField = fieldsDefinitionAtNode._children;
    const workingFormField = formRequirementsAtNode?._children;
    const mergedRequirements = { ...fieldsDefinitionAtNode, ...formRequirementsAtNode };
    let minimum = mergedRequirements._minimum;
    const shouldApplyMinimumIf = !minimum &&  mergedRequirements._minimumIf && processExpectedValue(mergedRequirements._minimumIf, thisPath, completeDataTree, context);

    if (mergedRequirements._minimumIf && shouldApplyMinimumIf) {
      minimum = formRequirementsAtNode._minimumIf?.count;
    }
    if (Array.isArray(node)) {
      let allValid = true;
      let indiciesLookFishyOnAddOrRemove = false;

      if (!fullTreeRevalidationRequired && remainingPath && remainingPath.length===1) {

        const validationKeys = Object.keys(thisLevelRet).filter(a=>!a.startsWith('_'));
        if (validationKeys.length !== node.length) {

          // Element count change detected. Now to check if all the validation nodes are IDs. If
          // they are not all numerical indicies, we may need to reindex then or otherwise
          // recalculate validation

          // Set reindexing to default until we're sure we're happy with things
          indiciesLookFishyOnAddOrRemove = true;
          try {
            if (validationKeys.filter(a=>!a.startsWith('[')).length===0) {

              validationKeys.filter(a=>!a.startsWith('[')).length===0;
              const keyIsUUID = validationKeys.map(seg=>isUUID(seg.slice(1,seg.length-1)));
              const allUUID = keyIsUUID.filter(a=>!a).length === 0;
              const allNumeric = validationKeys.filter(a=>!NATURAL_NUMBER_REGEX.test(a.slice(1,a.length-1))).length === 0;
              const sortedKeys = validationKeys.slice().map(a=>parseInt(a.slice(1,a.length-1)));
              sortedKeys.sort();
              if (node.length === validationKeys.length-1) {
                // Deletion
                if (allNumeric) {
                  // We should just be able to reindex the keys
                  if (sortedKeys.length === uniq(sortedKeys).length && sortedKeys[sortedKeys.length-1] === sortedKeys.length-1) {
                    // Verifying all values unique, and that the final index is than what the
                    // final index would be for a sequence. Should basically verify that the previous
                    // validation looks sane and we can probably trust the position data.
                    const removalIndexer = remainingPath[0];
                    const removalPositionIndex = parseInt(removalIndexer.slice(1,removalIndexer.length-1));
                    delete thisLevelRet[`[${removalPositionIndex}]`];
                    for (let i = removalPositionIndex+1; i < sortedKeys.length; i++) {
                      thisLevelRet[`[${i-1}]`] = thisLevelRet[`[${i}]`];
                      delete thisLevelRet[`[${i}]`];
                    }
                    indiciesLookFishyOnAddOrRemove = false;
                  }
                } else if (allUUID) {
                  // All indicies are UUIDs, so let's verify this UUID is no longer present, and then remove its validation
                  // entry
                  const matchingID = remainingPath[0].slice(1,remainingPath[0].length-1);
                  const indexFound = findIndex(node, n=>n.id===matchingID);
                  if (indexFound === -1) {
                    delete thisLevelRet[remainingPath[0]];
                    indiciesLookFishyOnAddOrRemove = false;
                  }
                }
              } else if (node.length === validationKeys.length+1) {
                // Insertion
                if (allNumeric) {
                  // We should just be able to reindex the keys
                  if (sortedKeys.length === uniq(sortedKeys).length && sortedKeys[sortedKeys.length-1] === sortedKeys.length-1) {
                    const insertionIndexer = remainingPath[0];
                    const insertionPositionIndex = parseInt(insertionIndexer.slice(1,insertionIndexer.length-1));
                    for (let i = sortedKeys.length; i > insertionPositionIndex; i--) {
                      thisLevelRet[`[${i}]`] = thisLevelRet[`[${i-1}]`];
                      delete thisLevelRet[`[${i-1}]`];
                    }
                    indiciesLookFishyOnAddOrRemove = false;
                  }
                } else if (allUUID) {
                  // Basically don't need to do anything for insertion, we should be inserting
                  // another UUID keyed entry
                  indiciesLookFishyOnAddOrRemove = false;
                }
              }

            }
          } catch (err) {
            console.error('Error working out indexing for array element addition or deletion, falling back to full reindex');
          }
        }
      }
      if (indiciesLookFishyOnAddOrRemove) {
        // Wiping all results, as indicies could be nonsensical
        console.warn('undesirable need to rebuild this part of the tree');
        for (const objKey in thisLevelRet) {
          delete thisLevelRet[objKey];
        }
        thisLevelRet._validationResult = {
          valid: false,
          errors: [],
          errorFocus: []
        };
      }
      for (const childNodeI in node) {
        const childNode: any = node[childNodeI];
        const pathKey = `[${childNode.id ?? childNodeI}]`;
        const indexesMatchAtLeast = childNodeI === `${remainingPath?.[0]}`;
        if (!indiciesLookFishyOnAddOrRemove && !fullTreeRevalidationRequired && remainingPath && remainingPath.length>0 && remainingPath[0] !== pathKey && !indexesMatchAtLeast) {
          // Much like map, leave the existing result as is
          // Upon element removal, all remaining entries should be skipped
        } else if (indiciesLookFishyOnAddOrRemove) {
          // Rebuild from here, can't trust the indicies upon deletion
          thisLevelRet[pathKey] = validateTree(childNode, completeDataTree, workingField, workingFormField ?? {}, [...thisPath, pathKey], undefined, undefined, complexValidatorErrorPaths, context);
        } else {
          thisLevelRet[pathKey] = validateTree(childNode, completeDataTree, workingField, workingFormField ?? {}, [...thisPath, pathKey], fullUpdatedPath, existingValidationTree, complexValidatorErrorPaths, context);
        }
        allValid = allValid && thisLevelRet[pathKey]?._validationResult?.valid;
      }
      const mergedDefn = { ...fieldsDefinitionAtNode, ...formRequirementsAtNode };
      for (const requirementKey in mergedDefn as ValidationDefnType & DocumentFieldType) {
        if (requirementKey.startsWith('_')) {
          const fieldSpecifics = mergedDefn[requirementKey];

          switch (requirementKey) {
            case '_childErrorFlagFocus': {
              if (!allValid) {
                thisLevelRet._validationResult.errors.push('childrenInvalidFocus');
                thisLevelRet._validationResult.errorFocus.push({ errorCode: 'childrenInvalidFocus', errorSource: ErrorSource.ChildrenInvalidSpecificFlag, focusTarget: fieldSpecifics.focusTarget });
              }
              break;
            }
            default:
          }
        }
      }
      if (allValid) {
        thisLevelRet._validationResult.valid = thisLevelRet._validationResult.errors.length === 0;
      } else {
        thisLevelRet._validationResult.valid = false;
        thisLevelRet._validationResult.errors.push('childrenInvalid');
        thisLevelRet._validationResult.errorFocus.push({ errorCode: 'childrenInvalid', errorSource: ErrorSource.ChildrenInvalid });
      }

      if (minimum && node.length < minimum) {
        thisLevelRet._validationResult.valid = false;
        thisLevelRet._validationResult.errors.push('minimumNotMet');
        thisLevelRet._validationResult.errorFocus.push({ errorCode: 'minimumNotMet', errorSource: ErrorSource.ListMinimum, focusTarget: mergedDefn._minimumIf?.focusTarget });
      }
    } else if (mergedRequirements._required && !node) { // This is a special case, where we want any array, which acts as a declaration of 'None Applicable'
      thisLevelRet._validationResult.valid = false;
      thisLevelRet._validationResult.errors.push('required');
      thisLevelRet._validationResult.errorFocus.push({ errorCode: 'required', errorSource: ErrorSource.Required });
    } else if (node === undefined && !minimum) {
      thisLevelRet._validationResult.valid = thisLevelRet._validationResult.errors.length === 0;
    } else {
      thisLevelRet._validationResult.valid = false;
      thisLevelRet._validationResult.errors.push('typeMismatch');
      thisLevelRet._validationResult.errorFocus.push({ errorCode: 'typeMismatch', errorSource: ErrorSource.DataTypeMismatch });
    }

  } else {
    let leafRequired = formRequirementsAtNode._required || fieldsDefinitionAtNode?._required;
    if (!leafRequired && formRequirementsAtNode._requiredIf) {
      leafRequired = processExpectedValue(formRequirementsAtNode._requiredIf, thisPath, completeDataTree, context);
    }

    let singleValueNode = node;
    if (fieldsDefinitionAtNode._type === 'number' && typeof singleValueNode === 'string' && fieldsDefinitionAtNode._subtype) {
      const canoni = canonicalisers[fieldsDefinitionAtNode._subtype];
      if (canoni) {
        singleValueNode = canoni(singleValueNode, { validationOnly: true }).canonical;
      }
    }
    if (singleValueNode || (fieldsDefinitionAtNode._type === 'boolean' && typeof singleValueNode === 'boolean') || (fieldsDefinitionAtNode._type === 'number' && typeof singleValueNode === 'number') ) {

      // Do more specific content checks
      if (fieldsDefinitionAtNode._type === 'UUID') {
        if (typeof singleValueNode === 'string' && isUUID(singleValueNode)) {
          thisLevelRet._validationResult.valid = thisLevelRet._validationResult.errors.length === 0;
        } else {
          thisLevelRet._validationResult.valid = false;
          thisLevelRet._validationResult.errors.push('notUUID');
          thisLevelRet._validationResult.errorFocus.push({ errorCode: 'notUUID', errorSource: ErrorSource.DataTypeMismatch });
        }
      } else if (fieldsDefinitionAtNode._type === 'boolean') {
        if (typeof singleValueNode === 'boolean') {
          thisLevelRet._validationResult.valid = thisLevelRet._validationResult.errors.length === 0;
        } else {
          thisLevelRet._validationResult.valid = false;
          thisLevelRet._validationResult.errors.push('notBoolean');
          thisLevelRet._validationResult.errorFocus.push({ errorCode: 'notBoolean', errorSource: ErrorSource.DataTypeMismatch });
        }
      } else if (fieldsDefinitionAtNode._type === 'string') {

        if (typeof singleValueNode === 'string') {
          const validationList: string[] = [];
          if (fieldsDefinitionAtNode._subtype) validationList.push(fieldsDefinitionAtNode._subtype);
          if (validationList.length > 0) {
            const { errorKeys, errorFocus } = runValidationMethods(singleValueNode, validationList);
            thisLevelRet._validationResult.errors.push(...errorKeys);
            thisLevelRet._validationResult.errorFocus.push(...errorFocus);
          }
          thisLevelRet._validationResult.valid = thisLevelRet._validationResult.errors.length === 0; // We've already checked it is non-empty

        } else {
          thisLevelRet._validationResult.valid = false;
          thisLevelRet._validationResult.errors.push('notString');
          thisLevelRet._validationResult.errorFocus.push({ errorCode: 'notString', errorSource: ErrorSource.DataTypeMismatch });
        }
      } else if (fieldsDefinitionAtNode._type === 'number') {

        if (typeof singleValueNode === 'number') {
          const validationList: string[] = [];
          if (fieldsDefinitionAtNode._subtype) validationList.push(fieldsDefinitionAtNode._subtype);
          if (validationList.length > 0) {
            const { errorKeys, errorFocus } = runValidationMethods(singleValueNode, validationList);
            thisLevelRet._validationResult.errors.push(...errorKeys);
            thisLevelRet._validationResult.errorFocus.push(...errorFocus);
          }
          thisLevelRet._validationResult.valid = thisLevelRet._validationResult.errors.length === 0; // We've already checked it is non-empty

        } else {
          thisLevelRet._validationResult.valid = false;
          thisLevelRet._validationResult.errors.push('notNumber');
          thisLevelRet._validationResult.errorFocus.push({ errorCode: 'notNumber', errorSource: ErrorSource.DataTypeMismatch });
        }
      }
    } else {
      if (leafRequired) {
        thisLevelRet._validationResult.valid = false;
        thisLevelRet._validationResult.errors.push('required');
        thisLevelRet._validationResult.errorFocus.push({ errorCode: 'required', errorSource: ErrorSource.Required });
      } else {
        thisLevelRet._validationResult.valid = thisLevelRet._validationResult.errors.length === 0;
      }
    }
    if (Array.isArray(formRequirementsAtNode._customValidation) && formRequirementsAtNode._customValidation.length) {
      for (const customValidationDefn of formRequirementsAtNode._customValidation) {

        if (customValidationDefn.enableIf) {
          const enable = processExpectedValue(customValidationDefn.enableIf, thisPath, completeDataTree);
          if (!enable) continue;
        }
        const validatorFuncName = customValidationDefn.type;
        const validatorFunction = customValidatorsDirectory[validatorFuncName];
        if (!validatorFunction) {
          console.warn('Validator function name not linked', validatorFuncName, thisPath);
          continue;
        }
        const errList = validatorFunction(completeDataTree, thisPath, customValidationDefn.params);
        if (!Array.isArray(errList)) {

          thisLevelRet._validationResult.valid = false;
          thisLevelRet._validationResult.errors.push('badValidationResult');
          thisLevelRet._validationResult.errorFocus.push({ errorCode: 'badValidationResult', errorSource: ErrorSource.BadValidationResult });
        }

        if (errList.length) {
          thisLevelRet._validationResult.valid = false;
          thisLevelRet._validationResult.errors.push(...errList);
          thisLevelRet._validationResult.errorFocus.push(...errList.map(errorCode=>({ errorCode, errorSource: ErrorSource.Custom   })));
        }

      }
    }
  }

  thisLevelRet._validationResult.errors = uniq(thisLevelRet._validationResult.errors);
  return thisLevelRet;
};

/**
 *
 * @param rootNode Does not deal with Y types, use toJSON
 * @param fieldsDefinition
 * @param formRequirements
 * @returns
 */
export const revalidateTransaction = (
  rootNode: Record<string, any>,
  fieldsDefinition: DocumentFieldType,
  formRequirements: ValidationDefnType | undefined,
  context: ComplexValidationContext | undefined) => {
  if (ValidationRuntimeContext.enableLogging) {
    console.log('revalidateTransaction', { fieldsDefinition, formRequirements });
  }

  const result = validateTree(
    rootNode,
    rootNode,
    fieldsDefinition,
    formRequirements ?? {},
    [],
    undefined,
    undefined,
    undefined,
    context);

  if (ValidationRuntimeContext.enableLogging) {
    console.log('revalidateTransaction result', result);
  }

  return result;
};

/**Updates validationImmer inplace
 *
 * @param rootNode
 * @param fieldsDefinition
 * @param formRequirements
 * @param validationImmer
 * @param updatedPath
 */
export const validateChange = (
  rootNode: Record<string, any>,
  fieldsDefinition: Record<string, any>,
  formRequirements: Record<string, any>,
  validationImmer: Record<string, any>,
  updatedPath: string[],
  context?: ComplexValidationContext | undefined
) => {

  return validateTree(rootNode, rootNode, fieldsDefinition, formRequirements ?? {}, [], updatedPath, validationImmer, undefined, context);
};

function findFocuses(validationSegment: ValidationNode, pathSoFar: PathSegments): string[] {
  const result: string[] = [];
  const localErrors = validationSegment._validationResult.errorFocus.filter(e=>e.errorSource !== ErrorSource.ChildrenInvalid);
  result.push(...localErrors.map(errSpec => composeErrorPathClassName(pathSoFar, errSpec.focusTarget)));

  for (const key in validationSegment) {
    if (key === '_validationResult') continue;
    const thisRes = findFocuses(validationSegment[key], [...pathSoFar, key]);
    if (thisRes) {
      result.push(...thisRes);
    }
  }
  return uniq(result).filter(Predicate.isNotNullish);
}

function buildErrorTree(validationSegment: ValidationNode, pathSoFar: PathSegments): PathSegments[] {
  const resultPaths: PathSegments[] = [];
  const localErrors = validationSegment._validationResult.errorFocus.filter(e=>e.errorSource !== ErrorSource.ChildrenInvalid);
  if (localErrors.length) {
    const first = localErrors[0];
    if (first.errorPaths) {
      const constructedPaths = first.errorPaths.map(path=>{
        if (path[0] === '') {
          return [...pathSoFar, ...path.slice(1)];
        }
        // At the time of writing, this is not yet a valid operation mode, so this is untested
        return path;
      });
      resultPaths.push(...constructedPaths);
    } else {
      resultPaths.push(pathSoFar);
    }
  }
  for (const key in validationSegment) {
    if (key === '_validationResult') continue;
    resultPaths.push(...buildErrorTree(validationSegment[key], [...pathSoFar, key]));
  }
  return resultPaths;
}

export function buildFocusAndErrorPaths(validationRoot: ValidationNode) {
  const focusErrList = findFocuses(validationRoot,[]);
  const focus = focusErrList[0];
  const errorPaths = hierarchyMap(buildErrorTree(validationRoot,[]));
  return { focus, errorPaths, focusErrList };
}
