import * as Y from 'yjs';
import { InstanceHistory, MaterialisedPropertyData } from '../property';
/*
The fieldGroups is an update postprocessor. When a path is updated, it's definitional path is
generated, then a lookup is done on that path to see if there's a post update function to perform.
The post update function is a side effect generating function, where the third parameter is the
immer 'draft' object. At the moment this only works for field updates, not element insertion or
deletion.

***
It is a post processor because the update should be applied first before this function is run
***

Path definition would look like collectionName.[].field, and can affect anything in the tree.
However, changing other fields would not re-invoke an update function.

It is intentional that a field can only have one update callback, and this is because we expect
that anything modified by the function, should also invoke this function. We do not want any fields
to belong to multiple groups. This is basically to ensure that we have only 1 input to output and
to avoid weird half updates because of undefined interactions between update functions. Constraints
for a group of fields should thus be consistently defined within the one update function. As stated
earlier, further updating fields from this function will not reinvoke handlers for any other field.
*/
export type FieldGroupFn = (
  fieldId: string,
  updatedPath: string,
  immerProxy: Y.Snapshot,
  variationsSnapshot: MaterialisedPropertyData | undefined,
  history: InstanceHistory | undefined,
  previousState: any
)=>void;

export const StandardFieldGroupFnMaps = {
  dueDateText: {
    'titleDivision.planDueDate': 'titleDivisionPlanDueDateText',
    'titleDivision.planDue12': 'titleDivisionPlanDueDateText',
    'dwelling.buildContractDueDate': 'dwellingBuildContractDueDateText',
    'dwelling.buildContractDue12': 'dwellingBuildContractDueDateText'
  }
};
