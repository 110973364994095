// this is copied from app/src/form-gen-util/constants.tsx
// it probably needs to be located somewhere a bit better...

import { ContractTerminationDepositReturn, NoneSetOrRange, PartyType, PoolComplyState, ProfessionalFeeMode, SigningAuthorityType, TitleInclusionState } from '@property-folders/contract';
import { QuadState } from '@property-folders/common/types/QuadState';
import { reaformsOrange } from '../../visual';

import type { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';

export const lotOptions = {
  'allotment': 'Allotment',
  'unit': 'Unit',
  'lot': 'Lot',
  'section': 'Section'
};

export const planOptions = {
  'deposited': 'Deposited Plan',
  'strata': 'Strata',
  'community': 'Community',
  'filed': 'Filed Plan'
};

export const titleTypeOptions = {
  'CT': 'Certificate of Title',
  'CR': 'Crown Record',
  'CL': 'Crown Lease'
};

export const minimumFontSize = 10;
export const checkboxFontSize = 16;

export const defaultFormBrandConfig: EntityBrandFormConfig = {
  lineColour: reaformsOrange,
  headingColour: reaformsOrange
};

export const commencementAgreementDateBoolOpts = { 'true': 'Date of Agreement', 'false': 'Other' };
export const portionOptionsBoolean = { 'true': 'Is whole of', 'false': 'Is portion of' };
export const portionOptionsExpanded = { [TitleInclusionState.whole]: 'Is whole of', [TitleInclusionState.portion]: 'Is portion of', [TitleInclusionState.none]: 'Is none of' };
export const advertRangeOptions = { 'range': 'Range', 'set': 'Set price', 'noprice': 'No price' };
export const auctionOnSiteOptions = { 'true': 'on site', 'false': 'At' };
export const agencyExclusiveOptions = { 'true': 'Sole Agency (exclusive)', 'false': 'General Agency (non-exclusive)' };
export const agencyAssocAuthOptions = { 'true': 'Agent is authorised', 'false': 'Agent is not authorised' };
export const previousNoticeOptions: {[key: string]:  string} = { 'yes': 'Yes', 'todo': 'Vendor to attend to', 'agenttodo': 'Vendor has signed a notice and Agent to send for Vendor' };
export function getPreviousNoticeOptions(prevAgentConfirm: boolean) {
  const noticeOptions = { ...previousNoticeOptions };

  if (!prevAgentConfirm) {
    delete noticeOptions.agenttodo;
  }

  return noticeOptions;
}
export const vendorTypeGuidance: {[optKey:string]: string}= {
  [PartyType.Individual]: 'Individual',
  [PartyType.Corporation]: 'Corporation',
  [PartyType.ExecutorNatural]: 'Executor (Individual) of Deceased Estate',
  [PartyType.ExecutorCompany]: 'Executor (Trustee Company) of Deceased Estate',
  [PartyType.AdministratorNatural]: 'Court Appointed Administrator (Individual) of Deceased Estate',
  [PartyType.AdministratorCompany]: 'Court Appointed Administrator (Trustee Company) of Deceased Estate',
  [PartyType.MortgageeNatural]: 'Mortgagee (Individual) in Possession',
  [PartyType.MortgageeCompany]: 'Mortgagee (Corporation) in Possession'
};

export const vendorTypeOptions: {name: string, label: string, groupheading?: boolean, groupentry?: boolean}[] = [
  { name: PartyType.Individual, label: 'Individual' },
  { name: PartyType.Corporation, label: 'Corporation' },
  { name: 'executorHeading', label: 'Executor of Deceased Estate', groupheading: true },
  { name: PartyType.ExecutorNatural, label: 'Executor - Individual', groupentry: true },
  { name: PartyType.ExecutorCompany, label: 'Executor - Trustee company', groupentry: true },
  { name: PartyType.ExecutorJoint, label: 'Executor - Joint', groupentry: true },
  { name: 'adminHeading', label: 'Court Appointed Administrator of Estate', groupheading: true },
  { name: PartyType.AdministratorNatural, label: 'Administrator - Individual', groupentry: true },
  { name: PartyType.AdministratorCompany, label: 'Administrator - Trustee Company', groupentry: true },
  { name: PartyType.AdministratorJoint, label: 'Administrator - Joint', groupentry: true },
  { name: 'mortgageHeading', label: 'Mortgagee in Possession', groupheading: true },
  { name: PartyType.MortgageeNatural, label: 'Mortgagee - Individual', groupentry: true },
  { name: PartyType.MortgageeCompany, label: 'Mortgagee - Trustee company', groupentry: true },
  { name: PartyType.MortgageeJoint, label: 'Mortgagee - Joint', groupentry: true }
];

export const purchaserTypeOptions: {name: string, label: string, groupheading?: boolean, groupentry?: boolean}[] = [
  { name: 'indi', label: 'Individual' },
  { name: 'corp', label: 'Corporation' }
];

export const signingAuthorityOptions: {[type:string]: {[authority:string]: string}}= {
  [PartyType.Individual]: {
    [SigningAuthorityType.self]: 'Self',
    [SigningAuthorityType.attorney]: 'Power of Attorney',
    [SigningAuthorityType.guardian]: 'Guardian',
    [SigningAuthorityType.attorneyJoint]: 'Power of Attorney - Joint',
    [SigningAuthorityType.guardianJoint]: 'Guardian - Joint'
  },
  [PartyType.Corporation]: {
    [SigningAuthorityType.authRep]: 'Authorised Representative',
    [SigningAuthorityType.directors2]: 'Two Directors',
    [SigningAuthorityType.sole]: 'Sole Director/Secretary',
    [SigningAuthorityType.directorSecretary]: 'Director and Secretary',
    [SigningAuthorityType.attorney]: 'Power of Attorney',
    [SigningAuthorityType.attorneyJoint]: 'Power of Attorney - Joint'
  },
  [PartyType.ExecutorNatural]: {
    [SigningAuthorityType.self]: 'Self',
    [SigningAuthorityType.attorney]: 'Executor\'s Power of Attorney',
    [SigningAuthorityType.attorneyJoint]: 'Executor\'s Power of Attorney - Joint'

  },
  [PartyType.ExecutorCompany]: {
    [SigningAuthorityType.authRep]: 'Authorised Representative',
    [SigningAuthorityType.directors2]: 'Two Directors',
    [SigningAuthorityType.sole]: 'Sole Director/Secretary',
    [SigningAuthorityType.directorSecretary]: 'Director and Secretary',
    [SigningAuthorityType.attorney]: 'Power of Attorney',
    [SigningAuthorityType.attorneyJoint]: 'Power of Attorney - Joint'
  },
  [PartyType.AdministratorNatural]: {
    [SigningAuthorityType.self]: 'Self',
    [SigningAuthorityType.attorney]: 'Administrator\'s Power of Attorney',
    [SigningAuthorityType.attorneyJoint]: 'Administrator\'s Power of Attorney - Joint'
  },
  [PartyType.AdministratorCompany]: {
    [SigningAuthorityType.authRep]: 'Authorised Representative',
    [SigningAuthorityType.directors2]: 'Two Directors',
    [SigningAuthorityType.sole]: 'Sole Director/Secretary',
    [SigningAuthorityType.directorSecretary]: 'Director and Secretary',
    [SigningAuthorityType.attorney]: 'Power of Attorney',
    [SigningAuthorityType.attorneyJoint]: 'Power of Attorney - Joint'
  },
  [PartyType.MortgageeNatural]: {
    [SigningAuthorityType.self]: 'Self',
    [SigningAuthorityType.attorney]: 'Power of Attorney',
    [SigningAuthorityType.guardian]: 'Guardian',
    [SigningAuthorityType.attorneyJoint]: 'Power of Attorney - Joint',
    [SigningAuthorityType.guardianJoint]: 'Guardian - Joint'
  },
  [PartyType.MortgageeCompany]: {
    [SigningAuthorityType.authRep]: 'Authorised Representative',
    [SigningAuthorityType.directors2]: 'Two Directors',
    [SigningAuthorityType.sole]: 'Sole Director/Secretary',
    [SigningAuthorityType.directorSecretary]: 'Director and Secretary',
    [SigningAuthorityType.attorney]: 'Power of Attorney',
    [SigningAuthorityType.attorneyJoint]: 'Power of Attorney - Joint'
  }
};

// future: consider moving more of the stuff in this file over to the common project
// some stuff was already moved to common/form-gen-util/formatting/constants to support string-composites

export const OPT_SECUR = 'Security system';
export const OPT_CCC = 'Consumer credit chattels';

export enum ChattelOptions {
  FixedFloorCoverings = 'Fixed floor coverings',
  LightFittings = 'Light fittings',
  WindowTreatments = 'Window treatments',
  CompliantSmokeAlarm = 'Compliant smoke alarm',
  SolarElectricalSystem = 'Solar electrical system',
  Dishwasher = 'Dishwasher',
  SecuritySystem = 'Security system',
  WallMountedTv = 'Wall mounted TV(s)',
  WaterFiltrationUnit = 'Water filtration unit and tap'
}

export const chattelsOptionsSaa=[
  ChattelOptions.FixedFloorCoverings,
  ChattelOptions.LightFittings,
  ChattelOptions.WindowTreatments,
  ChattelOptions.CompliantSmokeAlarm,
  ChattelOptions.SolarElectricalSystem,
  ChattelOptions.Dishwasher,
  ChattelOptions.SecuritySystem,
  ChattelOptions.WallMountedTv,
  ChattelOptions.WaterFiltrationUnit
].map(o=>typeof o === 'string' ? { name: o, label: o } : o);

// specifically excluding smoke alarm
export const chattelsOptionsContract=[
  ChattelOptions.FixedFloorCoverings,
  ChattelOptions.WindowTreatments,
  ChattelOptions.WallMountedTv,
  ChattelOptions.SolarElectricalSystem,
  ChattelOptions.SecuritySystem,
  ChattelOptions.Dishwasher,
  ChattelOptions.LightFittings,
  ChattelOptions.WaterFiltrationUnit
].map(o=>typeof o === 'string' ? { name: o, label: o } : o);

export const exclusionOptions=[
  'Vendor’s and/or occupier’s personal effects',
  'Landline telephones',
  'Wall mounted TV(s)',
  'Loose floor coverings',
  'Furniture',
  'Dishwasher',
  'Pot plants and all garden ornaments'
].map(o=>typeof o === 'string' ? { name: o, label: o } : o);

export const poolComplianceStateOpts = {
  [PoolComplyState.complyPre93]: { label: 'Complies - Built before 1 July 1993', order: 1 },
  [PoolComplyState.complyPost93]: { label: 'Complies - Built after 1 July 1993', order: 2 },
  [PoolComplyState.unknown]: { label: 'Unsure', order: 3 },
  [PoolComplyState.nonComply]: { label: 'Non compliant', order: 4 }
};

export const professionalFeeModeOpts = {
  [ProfessionalFeeMode.fixed]: 'Fixed Fee',
  [ProfessionalFeeMode.commission]: 'Commission'
};

export const professionalFixedFeeModeOpts = {

  [NoneSetOrRange.Set]: 'Set Amount',
  [NoneSetOrRange.Range]: 'Range, at Vendor\'s discretion'
};
export const professionalCommissionFeeModeOpts = {

  [NoneSetOrRange.Set]: 'Set %',
  [NoneSetOrRange.Range]: 'Range, at Vendor\'s discretion'
};

export const quadStateYesNoUnknownOpts: [QuadState, string][] = [
  [QuadState.True, 'Yes'],
  [QuadState.False, 'No'],
  [QuadState.AssertUnknown, 'Unknown']
];

export const boolYesNoOpts = {
  'true': 'Yes',
  'false': 'No'
};
export const boolApplicableOpts = {
  'true': 'Applicable',
  'false': 'Not applicable'
};
export const boolKnownOpts = {
  'true': 'Applicable',
  'false': 'None known'
};

export const rentalPeriodOpts = {
  'week': 'week',
  'fnight': 'fortnight',
  'month': 'month',
  'year': 'year'
};

export const tenancyTypeOpts = {
  'fixed': 'Fixed term',
  'periodic': 'Periodic'
};

export const cgtApplicationPersonOpts = {
  'vendor': 'Vendor to apply',
  'vendorAgent': 'Vendor’s Conveyancer or Accountant to apply'
};

export const poolComplyOpts = {
  na: 'Not applicable',
  comply: 'Complies',
  noncomply: 'Non-compliant, works required'
};

export const poolSectionOpts = {
  'true': 'Pool present',
  'false': 'Not applicable'
};

export const poolBefore93Opts = {
  'true': 'Before 1st July 1993',
  'false': 'After 1st July 1993'
};

export const pressBeginOpts = {
  'agency': 'Commencement of Agreement',
  'auction': 'After Auction (if applicable)',
  'other': 'Other'
};

export const contractTerminationDepositOpts = {
  [ContractTerminationDepositReturn.ReturnToPurchaser]: { label: 'The deposit is returned to Purchaser in full', order: 1 },
  [ContractTerminationDepositReturn.ReleaseToVendor]: { label: 'The deposit is released to Vendor in full', order: 2 },
  [ContractTerminationDepositReturn.Split]: { label: 'The deposit is released to the Vendor and Purchaser in the amounts set out below', order: 3 },
  [ContractTerminationDepositReturn.Other]: { label: 'Handling of the Deposit is specified in Other Terms', order: 4 }
};

export const expenditurePeriods = {
  'week': 'Week',
  'fnight': 'Fortnight',
  'month': 'Month',
  'inspect': 'Open inspection'
};

export const pressEndOpts = {
  'true': 'Expiry of Agreement',
  'false': 'Other'
};

export const proFeeManualCalcOpts = {
  'true': 'Entered below',
  'false': 'Fees and Commissions'
};

export const adminTemplateOpts = {
  'true': 'As below',
  'false': 'Set own terms'
};

export const gstWithholdOpts = {
  'false': 'No',
  'true': 'Vendor’s Representative to provide notice, if required'
};

export const adminFeeTerms = [
  'at the expiration of the Agency if no sale is effected',
  'if the Property is withdrawn from sale'
].map(o=>typeof o === 'string' ? { name: o, label: o } : o);

export const payMethodOpts = {
  'cheque': 'Cheque',
  'cc': 'Credit Card',
  'other': 'Other'
};

export const accRendOpts = {
  'month': 'Monthly',
  'fnight': 'Fortnightly',
  'week': 'Weekly'
};

export const adminFeeDefaultMessage = 'The Administration Fee as specified below is payable to recover file costs, administration and office overheads.';
export const auctionFeeDefaultMessage = 'The Auction Fee as specified below is payable to recover the costs of the auctioneer and administering the auction process.';
/**
 * Define legal jurisdictions which are to be used throughout (such as in Acts).
 */
export const LegalJurisdiction = {
  AustralianCapitalTerritory: 'ACT',
  Commonwealth: 'Cth',
  NewSouthWales: 'NSW',
  NorthernTerritory: 'NT',
  Queensland: 'Qld',
  SouthAustralia: 'SA',
  Tasmania: 'Tas',
  Victoria: 'Vic',
  WesternAustralia: 'WA'
} as const;

export type LegalJurisdictionType = typeof LegalJurisdiction;

export type LegalJurisdictionValue = LegalJurisdictionType[keyof LegalJurisdictionType];

/**
 * Define Acts for all legal jurisdictions.
 */
export const LegalActs = {
  [LegalJurisdiction.AustralianCapitalTerritory]: {},
  [LegalJurisdiction.Commonwealth]: {
    ANewTaxSystemGoodsAndServicesTaxAct1999: 'A New Tax System (Goods and Services Tax) Act 1999',
    CorporationsAct2001: 'Corporations Act 2001',
    ElectronicTransactionsAct1999: 'Electronic Transactions Act 1999',
    IncomeTaxAssessmentAct1997: 'Income Tax Assessment Act 1997',
    PersonalPropertySecuritiesAct2009: 'Personal Property Securities Act 2009',
    PrivacyAct1988: 'Privacy Act 1988',
    TaxationAdministrationAct1953: 'Taxation Administration Act 1953'
  },
  [LegalJurisdiction.NewSouthWales]: {},
  [LegalJurisdiction.NorthernTerritory]: {},
  [LegalJurisdiction.Queensland]: {},
  [LegalJurisdiction.SouthAustralia]: {
    CommercialArbitrationAct2011: 'Commercial Arbitration Act 2011',
    CommunityTitlesAct1996: 'Community Titles Act 1996',
    ConveyancersAct1994: 'Conveyancers Act 1994',
    ElectronicCommunicationsAct2000: 'Electronic Communications Act 2000',
    ElectronicNationalLawSouthAustraliaAct2013: 'Electronic National Law (South Australia) Act 2013',
    FencesAct1975: 'Fences Act 1975',
    HolidaysAct1910: 'Holidays Act 1910',
    LandAgentsAct1994: 'Land Agents Act 1994',
    LandAndBusinessSaleAndConveyancingAct1994: 'Land and Business (Sale and Conveyancing) Act 1994',
    LandAndBusinessSaleAndConveyancingRegulations2010: 'Land and Business (Sale and Conveyancing) Regulations 2010',
    LegalPractitionersAct1981: 'Legal Practitioners Act 1981',
    PlanningDevelopmentAndInfrastructureAct2016: 'Planning, Development and Infrastructure Act 2016',
    PlanningDevelopmentAndInfrastructureSwimmingPoolSafetyRegulations2019: 'Planning, Development and Infrastructure (Swimming Pool Safety) Regulations 2019',
    ResidentialTenanciesAct1995: 'Residential Tenancies Act 1995',
    StrataTitlesAct1988: 'Strata Titles Act 1988'
  },
  [LegalJurisdiction.Tasmania]: {},
  [LegalJurisdiction.Victoria]: {},
  [LegalJurisdiction.WesternAustralia]: {}
} as const;

export type LegalActsType = typeof LegalActs;

export const defaultSettlementDays = 30;
export const defaultConditionalDays = 14;