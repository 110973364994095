import { FormCode, NoneSetOrRange } from '../../property';
import { DocumentFieldType } from '../document-field';

// For some reason this doesn't like assigning values to these, so if I have a
// common definition of something, typescript will complain. Makes it harder to
// deduplicate...
export const propertyFolder: DocumentFieldType = {
  _type: 'Map',
  transactionType: { _type: 'string' },
  id: { _type: 'UUID', _required: true },
  saleAddrs: {
    _type: 'Array',
    _label: 'Properties',
    _children: {
      _type: 'Map',
      _label: 'Property',
      id: { _type: 'UUID', _required: true },
      streetAddr: { _type: 'string', _label: 'Street Address' },
      subStateAndPost: { _type: 'string', _subtype: 'stateSubPost' },
      suburb: { _type: 'string', _label: 'Suburb' }, // Not 100% sure we should be setting or using this, as this is only being set by a dropdown. If we make the address selector offline capable, maybe.
      lga: { _type: 'string', _label: 'LGA' },
      hundred: { _type: 'string', _label: 'Hundred' },
      irrigationArea: { _type: 'string', _label: 'Irrigation Area' },
      fromLssa: { _type: 'boolean' },
      additionalDesc: { _type: 'string', _label: 'Additional description of Property' },
      useAdditionalDescAsLegalDesc: { _type: 'boolean', _label: 'Use Additional Property Description as Legal Land Description' },
      streetAddr_parts: {
        _type: 'Map',
        Postcode: { _type: 'string' },
        Suburb: { _type: 'string' },
        State: { _type: 'string' },
        Latitude: { _type: 'string' },
        Longitude: { _type: 'string' },
        LotNumber: { _type: 'string' },
        UnitNumber: { _type: 'string' },
        LevelNumber: { _type: 'string' },
        StreetNumber: { _type: 'string' },
        StreetName: { _type: 'string' }
      }
    }
  },
  saleTitles: {
    _type: 'Array',
    _label: 'Titles',
    _children: {
      _type: 'Map',
      _label: 'Title',
      id: { _type: 'UUID', _required: true },
      isWhole: { _type: 'boolean', _label: 'Is whole' },
      title: { _type: 'string', _subtype: 'title', _label: 'Title' },
      linkedAddresses: { _type: 'Array', _children: { _type: 'string' } },
      fromLssa: { _type: 'boolean' },
      subTitles: {
        _type: 'Array',
        _children: {
          _type: 'Map',
          id: { _type: 'UUID', _required: true },
          portionType: { _type: 'number', _auditRenderer: 'enum_TitleInclusionState' },
          lot: { _type: 'string' },
          lotid: { _type: 'string' },
          plan: { _type: 'string' },
          planid: { _type: 'string' }
        }
      }
    }
  },
  _ignoredAddresses: {
    _type: 'Array',
    _label: 'Properties',
    _children: {
      _type: 'Map',
      _label: 'Property',
      id: { _type: 'UUID', _required: true }
    }
  },
  titleDivision: {
    _type: 'Map',
    _label: 'Portion of Land Details',
    depositChoice: { _type: 'string', _label: 'Deposit Choice' },
    hasDevelopmentAuthorisation: { _type: 'boolean', _label: 'Has Development Authorisation' },
    planDueDate: { _type: 'string', _subtype: 'date', _label: 'Plan due date' },
    planDue12: { _type: 'boolean', _label: 'Plan due 12 months after Contract Date' },
    autoClause: { _type: 'boolean', _label: 'Automatically manage portion of title clauses' },
    proposedLots: { _type: 'string', _label: 'Proposed Lots' },
    /**
     * @deprecated
     */
    proposedPlanRef: {
      _type: 'Map',
      ['family_'+FormCode.RSAA_SalesAgencyAgreement]: { _type: 'string' },
      ['family_'+FormCode.RSC_ContractOfSale]: { _type: 'string' }
    },
    plan: {
      _type: 'Map',
      _label: 'Plan',
      id: { _type: 'UUID', _variationIgnore: false },
      contentType: { _type: 'string' }
    }
  },
  dwelling: {
    _type: 'Map',
    _label: 'Dwelling Details relating to Portion of Land',
    vacantLand: { _type: 'boolean', _label: 'Vendor providing vacant land' },
    demolitionRequired: { _type: 'boolean', _label: 'Demolition Required' },
    purchaserWillBuild: { _type: 'boolean', _label: 'Purchaser will enter Building Contract' },
    purchaserBuilderName: { _type: 'string', _label: 'Purchaser Builder name' },
    buildContractDueDate: { _type: 'string', _subtype: 'date', _label: 'Building contract due date' },
    buildContractDue12: { _type: 'boolean', _label: 'Building contract due 12 months after Contract Date' },
    vendorWillBuild: { _type: 'boolean', _label: 'Vendor will build a new dwelling' },
    vendorHasPlanningConsent: { _type: 'boolean', _label: 'Vendor has Planning Consent' },
    vendorHasDevelopmentApproval: { _type: 'boolean', _label: 'Vendor has Development Approval' },
    vendorPlan: {
      _type: 'Map',
      _label: 'Dwelling plan',
      id: { _type: 'UUID', _variationIgnore: false },
      contentType: { _type: 'string' }
    },
    vendorSpecification: {
      _type: 'Map',
      _label: 'Dwelling specification',
      id: { _type: 'UUID', _variationIgnore: false },
      contentType: { _type: 'string' }
    },
    el: {
      _type: 'Map',
      _label: 'Eckermann Lawyers Additional Condition Quote',
      queued: { _required: false, _type: 'boolean', _label: 'Request Queued for after SAA execution' },
      requested: { _required: false, _type: 'number', _label: 'Request sent timestamp' }
    }
  },
  vendors: {
    _type: 'Array',
    _label: 'Vendors',
    _children: {
      _type: 'Map',
      _label: 'Vendor',
      id: { _type: 'UUID', _required: true },
      partyType: {
        _type: 'string',
        _label: 'Party Type',
        _auditRenderer: 'enum_PartyType',
        _readonlyOnLockedIdPath: ['..', 'id']
      },
      inTrust: { _type: 'boolean', _label: 'In Trust' },
      authority: { _type: 'string', _label: 'Authority', _auditRenderer: 'enum_SigningAuthorityType' },
      fullLegalName: {
        _type: 'string',
        _label: 'Full Legal Name',
        _validationRequirementFailedMessage: 'Specify full legal name of Vendor'
      },
      registeredOnTitle: { _type: 'boolean', _label: 'Registered on title' },
      onBehalfOf: { _type: 'string', _label: 'On Behalf of' },
      addressSingleLine: { _type: 'string', _label: 'Address' },
      primarySubcontact: { _type: 'number' },

      personName1: { _type: 'string', _label: 'Person 1 Name' },
      email1: { _type: 'string', _subtype: 'email', _label: 'Person 1 Email' },
      phone1: { _type: 'string', _subtype: 'phone', _label: 'Person 1 Phone' },
      personName2: { _type: 'string', _label: 'Person 2 Name' },
      email2: { _type: 'string', _subtype: 'email', _label: 'Person 2 Email' },
      phone2: { _type: 'string', _subtype: 'phone', _label: 'Person 2 Phone' },

      abn: { _type: 'string', _subtype: 'abnacn', _label: 'ABN' }, // This will allow ACNs as well as ABNs, which may not be appropriate for anything other than company. However, this definition can't be easily changed, we'd have to have a second field or something, and a special component... it's not worth handling at the moment.

      probateGranted: { _type: 'boolean', _label: 'Probate Granted' },
      probateByDate: { _type: 'string', _subtype: 'date', _label: 'Probate By Date' },
      addrSameAsSale: { _type: 'boolean', _label: 'Postal address same as Property' },
      legalRepresentatives: {
        _type: 'Array',
        _label: 'Legal Representatives',
        _children: {
          _type: 'Map',
          id: { _type: 'UUID', _required: true },
          name: {
            _type: 'string',
            _label: 'Full Legal Name of Representative'
          },
          email: { _type: 'string', _subtype: 'email', _label: 'Email of Representative' },
          phone: { _type: 'string', _subtype: 'phone', _label: 'Phone of Representative' },
          abn: { _type: 'string', _subtype: 'abnacn', _label: 'ABN of Representative' }
        }
      },
      namedExecutors: {
        _type: 'Array',
        _label: 'Multiple Executors',
        _children: {
          _type: 'Map',
          id: { _type: 'UUID', _required: true },
          partyType: {
            _type: 'string',
            _label: 'Party Type',
            _auditRenderer: 'enum_JointPartyType',
            _readonlyOnLockedIdPath: ['..', 'id']
          },
          authority: { _type: 'string', _label: 'Authority', _auditRenderer: 'enum_SigningAuthorityType' },
          fullLegalName: {
            _type: 'string',
            _label: 'Full Legal Name',
            _validationRequirementFailedMessage: 'Specify full legal name of Vendor'
          },

          addressSingleLine: { _type: 'string', _label: 'Address' },
          primarySubcontact: { _type: 'number' },
          primarySubcontactId: { _type: 'string' },

          personName1: { _type: 'string', _label: 'Person 1 Name' },
          email1: { _type: 'string', _subtype: 'email', _label: 'Person 1 Email' },
          phone1: { _type: 'string', _subtype: 'phone', _label: 'Person 1 Phone' },
          personName2: { _type: 'string', _label: 'Person 2 Name' },
          email2: { _type: 'string', _subtype: 'email', _label: 'Person 2 Email' },
          phone2: { _type: 'string', _subtype: 'phone', _label: 'Person 2 Phone' },

          abn: { _type: 'string', _subtype: 'abnacn', _label: 'ABN' }, // This will allow ACNs as well as ABNs, which may not be appropriate for anything other than company. However, this definition can't be easily changed, we'd have to have a second field or something, and a special component... it's not worth handling at the moment.

          legalRepresentatives: {
            _type: 'Array',
            _label: 'Legal Representatives',
            _children: {
              _type: 'Map',
              id: { _type: 'UUID', _required: true },
              name: {
                _type: 'string',
                _label: 'Full Legal Name of Representative'
              },
              email: { _type: 'string', _subtype: 'email', _label: 'Email of Representative' },
              phone: { _type: 'string', _subtype: 'phone', _label: 'Phone of Representative' },
              abn: { _type: 'string', _subtype: 'abnacn', _label: 'ABN of Representative' }
            }
          }
        }
      }
    }
  },
  purchasers: {
    _type: 'Array',
    _label: 'Purchasers',
    _children: {
      _type: 'Map',
      _label: 'Purchaser',
      id: { _type: 'UUID', _required: true },
      partyType: {
        _type: 'string',
        _label: 'Party Type',
        _auditRenderer: 'enum_PartyType',
        _readonlyOnLockedIdPath: ['..', 'id']
      },
      inTrust: { _type: 'boolean', _label: 'In Trust' },
      authority: { _type: 'string', _label: 'Authority', _auditRenderer: 'enum_SigningAuthorityType' },
      fullLegalName: {
        _type: 'string',
        _label: 'Name',
        _validationRequirementFailedMessage: 'Specify full legal name of Purchaser'
      },
      onBehalfOf: { _type: 'string', _label: 'On Behalf of' },
      addressSingleLine: { _type: 'string', _label: 'Address' },
      addrSameAsPrimary: { _type: 'boolean', _label: 'Postal address same as primary Purchaser' },
      primarySubcontact: { _type: 'number' },
      primarySubcontactId: { _type: 'string' },

      personName1: { _type: 'string', _label: 'Name' },
      email1: { _type: 'string', _subtype: 'email', _label: 'Email' },
      phone1: { _type: 'string', _subtype: 'phone', _label: 'Phone' },
      personName2: { _type: 'string', _label: 'Name' },
      email2: { _type: 'string', _subtype: 'email', _label: 'Email' },
      phone2: { _type: 'string', _subtype: 'phone', _label: 'Phone' },

      abn: { _type: 'string', _subtype: 'abnacn', _label: 'ABN' }, // This will allow ACNs as well as ABNs, which may not be appropriate for anything other than company. However, this definition can't be easily changed, we'd have to have a second field or something, and a special component... it's not worth handling at the moment.
      legalRepresentatives: {
        _type: 'Array',
        _label: 'Legal Representatives',
        _children: {
          _type: 'Map',
          id: { _type: 'UUID', _required: true },
          name: {
            _type: 'string',
            _label: 'Full Legal Name of Representative'
          },
          email: { _type: 'string', _subtype: 'email', _label: 'Email of Representative' },
          phone: { _type: 'string', _subtype: 'phone', _label: 'Phone of Representative' },
          abn: { _type: 'string', _subtype: 'abnacn', _label: 'ABN of Representative' }
        }
      }
    }
  },
  contractPrice: {
    _type: 'Map',
    _label: 'Price',
    taxableSupply: { _type: 'boolean', _label: 'Taxable supply' },
    inputTaxed: { _type: 'boolean', _label: 'Input taxed' },
    gstIncluded: { _type: 'boolean', _label: 'GST Included' },
    gstFreeReason: { _type: 'string', _label: 'GST-free reason' },
    marginApplied: { _type: 'boolean', _label: 'Margin applied' },
    purchasePrice: { _type: 'number', _subtype: 'aud', _label: 'Purchase Price' },
    purchaseGst: { _type: 'number', _subtype: 'aud', _label: 'Purchase GST' },
    deposit: { _type: 'number', _subtype: 'aud', _label: 'Deposit' },
    depositPayAt: { _type: 'string', _label: 'Deposit will be paid:' },
    depositDateOther: { _type: 'string', _subtype: 'date', _label: 'Deposit payment date if other' }
  },
  contractSettlement: {
    _type: 'Map',
    _label: 'Settlement',
    onDate: { _type: 'boolean', _label: 'Settlement Date' },
    date: { _type: 'string', _subtype: 'date', _label: 'date' },
    onCondition: { _type: 'boolean', _label: 'Business Days after Special Conditions' },
    onConditionDays: { _type: 'number', _subtype: 'days', _label: 'Business Days' },
    onContract: { _type: 'boolean', _label: 'Days after Contract signed' },
    onContractDays: { _type: 'number', _subtype: 'days', _label: 'Days' },
    afterCondition: { _type: 'string', _label: 'day condition' }
  },
  contractSchedule: {
    _type: 'Map',
    _label: 'Schedule',
    noForm1NoCoolMatters: { _type: 'string' }
  },
  contractSpecial: {
    _type: 'Map',
    _label: 'Special',
    financeRequired: { _type: 'boolean', _label: 'Will the sale be subject to the Purchaser obtaining finance?' },
    financePermitted: { _type: 'boolean', _label: 'Will the Vendor consider offers where the Sale is subject to the Purchaser obtaining finance?' },
    financeDeadline: { _type: 'string', _subtype: 'date', _label: 'Latest date for approval' },
    financeDeadlineTime: { _type: 'string', _subtype: 'timehm', _label: 'Latest time for approval' },
    financeAmount: { _type: 'number', _subtype: 'aud', _label: 'Amount of loan' },
    financeInterestRate: { _type: 'string', _subtype: 'percent', _label: 'Interest rate' },
    financeProperties: {
      _type: 'Array',
      _label: 'Property to be secured',
      _children: {
        _type: 'Map',
        _label: 'Property to be secured',
        addrSingleLine: { _type: 'string', _label: 'Address' }
      }
    },
    propertyNotPartOfSecurity: { _type: 'boolean', _label: 'The property will not be used as security, another address shall' },
    purchaserSaleRequired: { _type: 'boolean', _label: 'Is this contract subject to the sale of the Purchaser’s property?' },
    purchaserSalePermitted: { _type: 'boolean', _label: 'Will the Vendor consider offers where the Sale is subject to the sale of the Purchaser’s property?' },
    saleSettleAlsoRequired: { _type: 'boolean', _label: 'Has a contract been entered into for the sale of the Purchaser’s property?' },
    saleSettleUnconditional: { _type: 'boolean', _label: 'Is the contract for the sale of the Purchaser’s property unconditional?' },
    salePropertySingleLine: { _type: 'string', _label: 'Address of property to be sold' },
    salePropertyListedForSale: { _type: 'boolean', _label: 'Is the Purchaser’s property listed for sale?' },
    purchaserSaleContractDeadline: { _type: 'string', _subtype: 'date', _label: 'Date of contract entered into' },
    purchaserSaleSettlementDeadline: { _type: 'string', _subtype: 'date', _label: 'Purchaser sale deadline for settlement' },
    purchaserSaleMinimumPrice: { _type: 'number', _subtype: 'aud', _label: 'Purchaser sale minimum price of contract' }
  },
  offerAdditionalConditions: { _type: 'string', _label: 'Offer Additional Conditions' },
  primaryVendor: { _type: 'string' },
  primaryPurchaser: { _type: 'string' },
  headline: { _type: 'string', _label: 'Custom Headline' },
  authRep: {
    _type: 'Array',
    _label: 'Agent’s Authorised Representatives',
    _children: {
      _type: 'Map',
      _label: 'Entity',
      id: { _type: 'UUID', _required: true },
      linkedEntityId: { _type: 'number', _variationIgnore: true },
      company: { _type: 'string', _label: 'Company' },
      profileName: { _type: 'string', _label: 'Name' },
      rla: { _type: 'string', _label: 'RLA' },
      abn: { _type: 'string', _subtype: 'abnacnAnyValid', _label: 'ABN' },
      salesp: {
        _type: 'Array',
        _label: 'Representatives',
        _children: {
          _type: 'Map',
          _label: 'Representative',
          id: { _type: 'UUID', _required: true },
          linkedSalespersonId: { _type: 'number', _variationIgnore: true },
          name: { _type: 'string', _label: 'Name' },
          phone: { _type: 'string', _subtype: 'phone', _label: 'Phone' },
          email: { _type: 'string', _subtype: 'email', _label: 'Email' }
        }
      }
    }
  },
  agent: {
    _type: 'Array',
    _label: 'Agents',
    _children: {
      _type: 'Map',
      _label: 'Agent',
      id: { _type: 'UUID', _required: true },
      linkedEntityId: { _type: 'number', _variationIgnore: true },
      company: { _type: 'string', _label: 'Company' },
      profileName: { _type: 'string', _label: 'Name' },
      rla: { _type: 'string', _label: 'RLA' },
      abn: { _type: 'string', _subtype: 'abnacnAnyValid', _label: 'ABN' },
      salesp: {
        _type: 'Array',
        _label: 'Sales Representatives',
        _children: {
          _type: 'Map',
          _label: 'Sales Representative',
          id: { _type: 'UUID', _required: true },
          linkedSalespersonId: { _type: 'number', _variationIgnore: true },
          name: { _type: 'string', _label: 'Name' },
          phone: { _type: 'string', _subtype: 'phone', _label: 'Phone' },
          email: { _type: 'string', _subtype: 'email', _label: 'Email' }
        }
      }
    }
  },
  sale: {
    _type: 'Map',
    _label: 'Sale',
    agentEstPrc: { _type: 'number', _subtype: 'aud', _label: 'Agent’s Estimated Price' },
    vendorPrc: { _type: 'number', _subtype: 'aud', _label: 'Vendor’s Selling Price' },
    advertPrc: { _type: 'number', _subtype: 'aud', _label: 'Advertised price' },
    advertPrcUpper: { _type: 'number', _subtype: 'aud', _label: 'Advertised Upper' },
    advertRange: { _type: 'string', _label: 'Advertised range' },
    saleMethod: { _type: 'string', _label: 'Method of sale' },
    saleMethodOther: { _type: 'string', _label: 'Method of sale (other)' },
    settlement: { _type: 'number', _subtype: 'days', _label: 'Settlement', _extraSuffixFormView: ' after Contract date' }
  },
  auction: {
    _type: 'Map',
    _label: 'Auction',
    propDate: { _type: 'string', _subtype: 'date', _label: 'Proposed date' },
    propTime: { _type: 'string', _subtype: 'timehm', _label: 'Proposed time' },
    onSite: { _type: 'boolean', _label: 'shall be' },
    location: { _type: 'string', _label: 'Address' },
    timeTbc: { _type: 'boolean', _label: 'Date and Time to be confirmed' }
  },
  disableCompareAlreadyProvided: { _type: 'boolean' },
  comparableSales: {
    _type: 'Map',
    annexureRef: {
      // This probably won't need to be across multiple forms, but just in case
      _type: 'Map',
      ['family_'+FormCode.RSAA_SalesAgencyAgreement]: { _type: 'string' },
      ['family_'+FormCode.RSC_ContractOfSale]: { _type: 'string' }
    }
  },
  compareAddrs: {
    _type: 'Array',
    _label: 'Comparable sales',
    _children: {
      _type: 'Map',
      _label: 'Comparable sale',
      id: { _type: 'UUID', _required: true },
      addrLine: { _type: 'string', _label: 'Address' },
      soldPrc: { _type: 'number', _subtype: 'aud', _label: 'Sold price' },
      soldDate: { _type: 'string', _subtype: 'date', _label: 'Sold date' }
    }
  },
  agencyExclusive: { _type: 'boolean', _label: 'This agreement is a:' },
  agency: {
    _type: 'Map',
    _label: 'Agency',
    duration: { _type: 'number', _subtype: 'days', _label: 'Duration' },
    start: { _type: 'boolean', _label: 'Start Type' },
    startOther: { _type: 'string', _subtype: 'date', _label: 'Start date' }
  },
  otherAgents: {
    _type: 'Array',
    _label: 'Other General Agencies',
    _children: {
      _type: 'Map',
      _label: 'Other General Agency',
      id: { _type: 'UUID', _required: true },
      company: { _type: 'string', _label: 'Company' },
      rla: { _type: 'string', _label: 'RLA' },
      abn: { _type: 'string', _subtype: 'abnacn', _label: 'ABN' },
      salesp: {
        _type: 'Array',
        _label: 'Sales Representatives',
        _children: {
          _type: 'Map',
          _label: 'Sales Representative',
          id: { _type: 'UUID', _required: true },
          name: { _type: 'string', _label: 'Name' },
          phone: { _type: 'string', _subtype: 'phone', _label: 'Phone' },
          email: { _type: 'string', _subtype: 'email', _label: 'Email' }
        }
      }
    }
  },
  authAssoc: { _type: 'boolean', _label: 'Is the agent authorised to appoint other licensed agents?' },
  assocAgents: {
    _type: 'Array',
    _label: 'Associated agents',
    _children: {
      _type: 'Map',
      _label: 'Associated agent',
      id: { _type: 'UUID', _required: true },
      company: { _type: 'string', _label: 'Name' },
      rla: { _type: 'string', _label: 'RLA' },
      abn: { _type: 'string', _subtype: 'abnacn', _label: 'ABN' },
      salesp: {
        _type: 'Array',
        _label: 'Sales Representatives',
        _children: {
          _type: 'Map',
          _label: 'Sales Representative',
          id: { _type: 'UUID', _required: true },
          name: { _type: 'string', _label: 'Name' },
          phone: { _type: 'string', _subtype: 'phone', _label: 'Phone' },
          email: { _type: 'string', _subtype: 'email', _label: 'Email' }
        }
      }
    }
  },
  agentAuthority: { _type: 'boolean', _label: 'Is the Agent authorised to accept offers on behalf of the Vendor?' },
  prevAgentEnable: { _type: 'boolean', _label: 'Any previous agents?' },
  prevAgentConfirm: { _type: 'boolean', _label: 'Will the vendor confirm which previous agents?' },
  prevAgents: {
    _type: 'Array',
    _label: 'Previous agents',
    _children: {
      _type: 'Map',
      _label: 'Previous Agent',
      id: { _type: 'UUID', _required: true },
      company: { _type: 'string', _label: 'Company' },
      rla: { _type: 'string', _label: 'RLA' },
      abn: { _type: 'string', _subtype: 'abnacn', _label: 'ABN' },
      salesp: {
        _type: 'Array',
        _label: 'Sales Representatives',
        _children: {
          _type: 'Map',
          _label: 'Sales Representative',
          id: { _type: 'UUID', _required: true },
          name: { _type: 'string', _label: 'Name' },
          phone: { _type: 'string', _subtype: 'phone', _label: 'Phone' },
          email: { _type: 'string', _subtype: 'email', _label: 'Email' }
        }
      }
    }
  },
  prevAgentExpire: { _type: 'boolean', _label: 'Previous agency expired?' },
  prevAgentNotice: { _type: 'string', _label: 'Written termination to previous agent(s) given?' },
  chattels: {
    _type: 'Map',
    _label: 'Chattels',
    simple: {
      _type: 'Array',
      _subtype: 'StringSet',
      _label: 'Chattels',
      _children: { _type: 'string', _label: 'Chattel' }
    },
    cccDetail: { _type: 'string', _label: 'Details of Consumer Credit Chattels' },
    cccEnable: { _type: 'boolean', _label: 'Are there any Consumer Credit Chattels?' }
  },
  exclusions: {
    _type: 'Map',
    _label: 'Exclusions',
    simple: {
      _type: 'Array',
      _subtype: 'StringSet',
      _label: 'Exclusions',
      _children: { _type: 'string', _label: 'Exclusion' }
    }
  },
  encroach: {
    _type: 'string',
    _label: 'Known encroachments',
    _noPdfValueIf: { notExpectedValue: true, field: ['encroachEnable'] }
  },
  encroachEnable: { _type: 'boolean', _label: 'Known encroachments applicable?' },
  notiWorks: {
    _type: 'string',
    _label: 'Alterations erected without consent',
    _noPdfValueIf: { notExpectedValue: true, field: ['notiWorksEnable'] }
  },
  notiWorksEnable: { _type: 'boolean', _label: 'Alterations erected without consent?' },
  vendorWorks: {
    _type: 'string',
    _label: 'Vendor’s Works',
    _noPdfValueIf: { notExpectedValue: true, field: ['vendorWorksEnable'] }
  },
  vendorWorksEnable: { _type: 'boolean', _label: 'Vendor’s Works?' },
  specialTerms: { _type: 'string', _label: 'Special terms' },
  specialTermsEnable: { _type: 'boolean' },
  cgtEnable: { _type: 'boolean', _label: 'CGT withholding applicable?' },
  cgtPerson: { _type: 'string', _label: 'Who will be responsible for CGT clearance?', _noPdfValueIf: { notExpectedValue: true, field: ['cgtEnable'] } },
  gstWithholdEnable: { _type: 'boolean', _label: 'GST withholding applicable?' },
  pool: {
    _type: 'Map',
    _label: 'Pool',
    present: { _type: 'boolean', _label: 'Is there a pool or spa?' },
    complyState: { _type: 'number', _subtype: 'enum_PoolComplyState', _label: 'Is the pool/spa compliant?', _noPdfValueIf: { notExpectedValue: true, field: ['pool', 'present'] } },
    nonComplyWorks: { _type: 'string', _label: 'Required works', _noPdfValueIf: { notExpectedValue: true, field: ['pool', 'present'] } }
  },
  vendorGst: { _type: 'boolean', _label: 'Is the Vendor required to be registered for GST?' },
  saaGstUnknowns: {
    _type: 'Map',
    vendorGst: { _type: 'boolean', _label: 'Unknown if Vendor is required to be registered for GST' },
    inputTaxed: { _type: 'boolean', _label: 'Unknown if Property is input taxed' }
  },
  saaTenant: {
    _type: 'Map',
    _label: 'Tenant Legacy',
    tenantEnable: { _type: 'boolean', _label: 'Is there an existing tenancy agreement?' },
    migrationLinkingTenantId: { _type: 'string' }
  },
  tenantsCollect: { // Rather than just calling this tenants, giving it a more identifiable name will make it easier to perform a search on the string to find relevant instances
    _type: 'Array',
    _label: 'Tenants',
    _children: {
      _type: 'Map',
      _label: 'A Tenancy Agreement',
      id: { _type: 'UUID', _required: true },
      tenantName: {
        _type: 'string',
        _label: 'Name',
        _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] }
      },
      manageAgent: {
        _type: 'string',
        _label: 'Managing Agent',
        _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] }
      },
      start: {
        _type: 'string',
        _subtype: 'date',
        _label: 'Commencement of tenancy',
        _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] }
      },
      end: { _type: 'string', _subtype: 'date', _label: 'End of tenancy', _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] } },
      rentalValue: { _type: 'number', _subtype: 'aud', _label: 'Rent amount', _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] } },
      period: { _type: 'string', _label: 'Rent period', _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] } },
      bondEnable: { _type: 'boolean', _label: 'Bond with CBS', _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] } },
      bondAmt: { _type: 'number', _subtype: 'aud', _label: 'Bond amount', _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] } },
      adviseTenant: { _type: 'boolean', _label: 'Vendor to advise tenants upon sale?', _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] } },
      saleContTenant: { _type: 'boolean', _label: 'Sale subject to existing tenancy continuing?', _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] } },
      type: { _type: 'string', _label: 'Agreement type', _noPdfValueIf: { notExpectedValue: true, field: ['saaTenant', 'tenantEnable'] } }
    }
  },
  marketingTemplate: {
    _type: 'Map',
    enable: { _type: 'boolean', _label: 'Enable' },
    id: { _type: 'number', _label: 'Id' },
    name: { _type: 'string', _label: 'Name' },
    agentName: { _type: 'string', _label: 'Agent Name' },
    title: { _type: 'string', _label: 'Title' },
    subTitle: { _type: 'string', _label: 'Sub Title' }
  },
  marketingFees: {
    _type: 'Array',
    _label: 'Marketing Fees',
    _children: {
      _type: 'Map',
      _label: 'Marketing Fee',
      id: { _type: 'UUID', _required: true },
      itemDesc: { _type: 'string', _label: 'Description' },
      itemCost: { _type: 'number', _subtype: 'audWithNegative', _label: 'Cost' },
      enable: { _type: 'boolean', _label: '' },
      readonly: { _type: 'boolean', _label: '' },
      section: { _type: 'string', _label: 'Section' }
    }
  },
  marketingFeesOptions: {
    _type: 'Map',
    noFees: { _type: 'boolean', _label: 'No marketing fees to be charged' },
    externalFeeScheduleAnnexureRef: {
      _type: 'Map',
      ['family_'+FormCode.RSAA_SalesAgencyAgreement]: { _type: 'string', _label: 'Annexure reference for Marketing Fees in RSAA' }
    },
    denormalisedTotal: { _type: 'number', _subtype: 'audWithNegative', _label: 'Total' }
  },
  fixedSearchFees: {
    _label: 'Fixed Search Fees',
    _type: 'Map',
    epfFee: {
      _type: 'Map',
      _label: 'Eckermann Property Forms Fee',
      enable: { _type: 'boolean', _label: 'Using Eckermann Property Forms for Form 1 preparation?' }
    },
    councilSearch: {
      _type: 'Map',
      _label: 'Council and Statutory Search Fees',
      enable: { _type: 'boolean', _label: 'Charging for Statutory and Council Searches?' },
      itemCost: { _type: 'number', _subtype: 'aud', _label: 'Statutory and Council Search Cost' }
    }
  },
  searchFees: {
    _type: 'Array',
    _label: 'Search Fees',
    _children: {
      _type: 'Map',
      _label: 'Search Fee',
      id: { _type: 'UUID', _required: true },
      itemDesc: { _type: 'string', _label: 'Description' },
      itemCost: { _type: 'number', _subtype: 'aud', _label: 'Cost' }
    }
  },
  pressBudget: {
    _type: 'Map',
    _label: 'Press Budget',
    enable: { _type: 'boolean', _label: 'Will there be any periodic press?' },
    strike: { _type: 'boolean', _label: 'Strike' },
    additional: { _type: 'boolean', _label: 'Periodic press in addition to fees listed', _noPdfValueIf: { notExpectedValue: true, field: ['pressBudget', 'enable'] } },
    start: { _type: 'string', _label: 'When should this promotion begin?', _noPdfValueIf: { notExpectedValue: true, field: ['pressBudget', 'enable'] } },
    startOther: { _type: 'string', _label: 'Promotion beginning other description', _noPdfValueIf: { notExpectedValue: true, field: ['pressBudget', 'enable'] } },
    expenditure: { _type: 'number', _subtype: 'aud', _label: 'Approved expenditure', _noPdfValueIf: { notExpectedValue: true, field: ['pressBudget', 'enable'] } },
    expPeriod: { _type: 'string', _label: 'Expenditure period', _noPdfValueIf: { notExpectedValue: true, field: ['pressBudget', 'enable'] } },
    endAgency: { _type: 'boolean', _label: 'When should this promotion end?', _noPdfValueIf: { notExpectedValue: true, field: ['pressBudget', 'enable'] } },
    otherEnd: { _type: 'string', _subtype: 'date', _label: 'Other end date', _noPdfValueIf: { notExpectedValue: true, field: ['pressBudget', 'enable'] } }
  },
  benefits: {
    _type: 'Map',
    _label: 'Disclosure of Benefits',
    enable: { _type: 'boolean', _label: 'Are Benefits in addition to those described in General Condition 8 to be disclosed to the Vendor?' },
    template: {
      _type: 'Map',
      _label: 'Template',
      _required: false,
      id: { _type: 'UUID' },
      name: { _type: 'string' }
    },
    benefits: {
      _type: 'Array',
      _label: 'Benefits',
      _children: {
        _type: 'Map',
        _label: 'Benefit',
        id: { _type: 'UUID', _required: true },
        itemDesc: { _type: 'string', _label: 'Description' },
        itemSource: { _type: 'string', _label: 'Source' },
        itemCost: { _type: 'string', _label: 'Cost' },
        itemRecipient: { _type: 'string', _label: 'Recipient' }
      },
      _noPdfValueIf: { notExpectedValue: true, field: ['benefits', 'enable'] }
    }
  },
  professFee: {
    _type: 'Map',
    _label: 'Professional Fee',

    fixed: {
      _type: 'number',
      _subtype: 'aud',
      _label: 'Fixed amount set or lower value',
      _noPdfValueIf: {
        notOneOfExpectedValue: [
          NoneSetOrRange.Range,
          NoneSetOrRange.Set
        ],
        field: ['professFee', 'fixedMode']
      }
    },
    fixedUpper: {
      _type: 'number',
      _subtype: 'aud',
      _label: 'Fixed amount upper value',
      _noPdfValueIf: {
        notOneOfExpectedValue: [
          NoneSetOrRange.Range
        ],
        field: ['professFee', 'fixedMode']
      }
    },
    commis: {
      _type: 'string',
      _subtype: 'percent',
      _label: 'Commission set or lower value',
      _noPdfValueIf: {
        notOneOfExpectedValue: [
          NoneSetOrRange.Range,
          NoneSetOrRange.Set
        ],
        field: ['professFee', 'commissionMode']
      }
    },
    commisUpper: {
      _type: 'string',
      _subtype: 'percent',
      _label: 'Commission set or upper value',
      _noPdfValueIf: {
        notOneOfExpectedValue: [
          NoneSetOrRange.Range
        ],
        field: ['professFee', 'commissionMode']
      }
    },
    fixedMode: { _type: 'number', _subtype: 'enum_NoneSetOrRange', _label: 'Fixed Fee Mode' },
    commissionMode: { _type: 'number', _subtype: 'enum_NoneSetOrRange', _label: 'Commission Mode' }
  },
  uplift: {
    _type: 'Map',
    _label: 'Uplift',
    enable: { _type: 'boolean', _label: 'Will an uplift be used?' },
    mode: { _type: 'number', _subtype: 'enum_ProfessionalFeeMode', _label: 'How will the uplift be calculated?' },
    strike: { _type: 'boolean', _label: 'Strike' },
    thresh: { _type: 'number', _subtype: 'aud', _label: 'Sale price threshold for uplift' },
    fixed: { _type: 'number', _subtype: 'aud', _label: 'Fixed fee amount' },
    portion: { _type: 'string', _subtype: 'percent', _label: 'Percentage over threshold' },
    percentageOrFixed: { _type: 'boolean', _label: 'Calculation of uplift' }
  },
  adminFee: {
    _type: 'Map',
    _label: 'Admin Fee',
    enable: { _type: 'boolean', _label: 'Will an Administration Fee be charged?' },
    strike: { _type: 'boolean', _label: '' },
    templatePrice: { _type: 'string', _label: 'Templated price', _noPdfValueIf: { notExpectedValue: true, field: ['adminFee', 'enable'] } },
    setPrice: { _type: 'number', _subtype: 'aud', _label: 'Set price', _noPdfValueIf: { notExpectedValue: true, field: ['adminFee', 'enable'] } }
  },
  auctionFee: { _type: 'number', _subtype: 'aud', _label: 'Auction fee' },
  auctionFeeSwitches: {
    _type: 'Map',
    applicable: { _type: 'boolean', _label: 'Auction fee applicable' },
    feeDesc: { _type: 'string', '_label': 'Fee Description' },
    strike: { _type: 'boolean', _label: 'Auction fee struck from document' }
  },
  payTerm: {
    _type: 'Map',
    _label: 'Terms Of Payment',
    enable: { _type: 'boolean', _label: 'Should section be printed' },
    strike: { _type: 'boolean', _label: '' },
    marketingPaid: { _type: 'boolean', _label: 'Approved marketing budget paid in advance' },
    method: { _type: 'string', _label: 'Method of payment' },
    rendFreq: { _type: 'string', _label: 'Accounts rendered frequency' },
    actualTerms: { _type: 'string', _label: 'Payment terms' },
    surcharge: { _type: 'string', _subtype: 'percent', _label: 'Recovery surcharge' },
    methodOther: { _type: 'string', _label: 'Other payment method' }
  },
  contractOther: {
    _type: 'Map',
    _label: 'Contract Other',
    enable: { _type: 'boolean', _label: 'Enabled' },
    otherText: { _type: 'string', _label: 'Text' }
  },
  landType: { _type: 'number', _subtype: 'enum_LandType', _label: 'Land Type' },
  clauses: {
    _type: 'Array',
    _label: 'Clauses',
    _required: false,
    _listPreserveSigningOrder: true,
    _children: {
      _type: 'Map',
      _label: 'Clause',
      id: { _required: true, _type: 'UUID' },
      title: { _required: true, _type: 'string', _label: 'Title' },
      text: { _required: true, _type: 'string', _label: 'Text' }
    }
  },
  clausesByFamily: {
    _type: 'Array',
    _children: {
      _type: 'Map',
      id: { _required: true, _type: 'UUID' },
      formFamily: { _type: 'string' },
      clauses: {
        _type: 'Array',
        _label: 'Clauses',
        _required: false,
        _listPreserveSigningOrder: true,
        _children: {
          _type: 'Map',
          _label: 'Clause',
          id: { _required: true, _type: 'UUID' },
          title: { _required: true, _type: 'string', _label: 'Title' },
          text: { _required: true, _type: 'string', _label: 'Text' }
        }
      }
    }
  },
  contractTermination: {
    _type: 'Map',
    depositOption: { _type: 'number', _auditRenderer: 'enum_ContractTerminationDepositReturn' },
    splitVendorReleaseAmt: { _type: 'number', _subtype: 'aud' },
    splitPurchaserReturnAmt: { _type: 'number', _subtype: 'aud' },
    otherTerms: { _type: 'string' }
  },
  form1AndSearches: {
    _type: 'Map',
    whoPays: { _type: 'string' },
    whoSearches: { _type: 'string' },
    epfOrderingAutomatic: { _type: 'number' }
  },
  transactionFee: {
    _type: 'Map',
    enabled: { _type: 'boolean' },
    whoPays: { _type: 'string' }
  }
};

export const globalFieldGroups = {
  'tenantsCollect.[].tenantName': 'tenantBackporting',
  'tenantsCollect.[].manageAgent': 'tenantBackporting',
  'tenantsCollect.[].start': 'tenantBackporting',
  'tenantsCollect.[].end': 'tenantBackporting',
  'tenantsCollect.[].rentalValue': 'tenantBackporting',
  'tenantsCollect.[].period': 'tenantBackporting',
  'tenantsCollect.[].bondEnable': 'tenantBackporting',
  'tenantsCollect.[].bondAmt': 'tenantBackporting',
  'tenantsCollect.[].adviseTenant': 'tenantBackporting',
  'tenantsCollect.[].saleContTenant': 'tenantBackporting',
  'tenantsCollect.[].type': 'tenantBackporting'
};
