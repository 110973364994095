import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SessionInfo, SiteNotification, SiteNotificationResult } from '@property-folders/contract/rest/auth';
import { LinkBuilder } from '../util/LinkBuilder';
import {
  GetPurchaserPortalResult, PostPurchaserDocumentBody,
  PostPurchaserDocumentResponse,
  PostPurchaserPortalSettingsBody
} from '@property-folders/contract';
import { PurchaserPortalSettings } from '@property-folders/contract/yjs-schema/purchaser-portal';

export const restApiSlice= createApi({
  reducerPath: 'restApi',
  baseQuery: fetchBaseQuery({ baseUrl: LinkBuilder.restApiBase }),
  endpoints: builder => ({
    getSessionInfo: builder.query<SessionInfo, void>({
      query: () => '/auth/session/info'
    }),
    getSiteNotifications: builder.query<SiteNotification[], void>({
      query: () => '/site-notifications',
      transformResponse: (res: SiteNotificationResult[]) => {
        return res?.map(n => ({
          ...n,
          Start: new Date(n.Start).getTime(), //convert timezone
          End: new Date(n.End).getTime()
        }));
      }
    }),
    getPurchaserPortal: builder.query<GetPurchaserPortalResult, string | undefined>({
      query: (portalId) => `/portal/purchaser/${portalId}`,
      providesTags: ['PURCHASER_PORTAL']
    }),
    updatePurchaserPortalSettings: builder.mutation<PostPurchaserPortalSettingsBody, Partial<GetPurchaserPortalResult>>({
      query: ({ portalId, settings }) => ({
        url: `/portal/purchaser/${portalId}/settings`,
        method: 'POST',
        body: settings
      }),
      invalidatesTags: ['PURCHASER_PORTAL', 'PURCHASER_SETTINGS'],
      async onQueryStarted({ portalId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          restApiSlice.util.updateQueryData('getPurchaserPortal', portalId, (draft) => {
            draft.settings = patch.settings;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    getPurchaserSettings: builder.query<PurchaserPortalSettings, { portalId: string, purchaserId: string }>({
      query: ( { portalId, purchaserId }) => `/portal/purchaser/${portalId}/settings/${purchaserId}`,
      providesTags: ['PURCHASER_SETTINGS']
    }),
    updatePurchaserSettings: builder.mutation<PurchaserPortalSettings, {portalId: string, purchaserId: string, settings: PostPurchaserPortalSettingsBody}>({
      query: ({ portalId, purchaserId, settings }) => ({
        url: `/portal/purchaser/${portalId}/settings/${purchaserId}`,
        method: 'POST',
        body: settings
      }),
      invalidatesTags: ['PURCHASER_SETTINGS'],
      async onQueryStarted({ portalId, purchaserId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          restApiSlice.util.updateQueryData('getPurchaserSettings', { portalId, purchaserId }, (draft) => {
            draft = patch.settings;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    deletePurchaserPortalDocument: builder.mutation<void, {portalId: string, documentId: string}>({
      query: ({ portalId, documentId }) => ({
        url: `/portal/purchaser/${portalId}/documents/${documentId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['PURCHASER_PORTAL'],
      async onQueryStarted({ portalId, documentId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          restApiSlice.util.updateQueryData('getPurchaserPortal', portalId, (draft) => {
            draft.sharedDocuments = [...draft.sharedDocuments].filter(sd => sd.documentId !== documentId);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    postPurchaserPortalDocument: builder.mutation<PostPurchaserDocumentResponse, PostPurchaserDocumentBody & {portalId: string}>({
      query: ({ portalId, ...body  }) => ({
        url: `/portal/purchaser/${portalId}/documents/`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: ['PURCHASER_PORTAL']
    })
  })
});

export const {
  useGetSessionInfoQuery,
  useGetSiteNotificationsQuery,
  useGetPurchaserPortalQuery,
  useUpdatePurchaserPortalSettingsMutation,
  useDeletePurchaserPortalDocumentMutation,
  usePostPurchaserPortalDocumentMutation,
  useGetPurchaserSettingsQuery,
  useUpdatePurchaserSettingsMutation
} = restApiSlice;
