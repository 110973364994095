import { advertRestrict } from './advertRestrict';
import { partyResetActivity } from './vendorReset';
import { FieldGroupFn } from '@property-folders/contract/yjs-schema/model';
import { purchasePriceGst } from './purchasePriceGst';
import { splitPartySignerType } from './splitPartySignerType';
import { purchaserAddrSameAsPrimary } from './primaryPurchaserAddress';
import { saaSearchCostBind } from './saaSearchCostBind';
import { setOneFeeMode } from './setOneFeeMode';
import { swapFixedFeeRangeIfWrong, swapCommissionFeeRangeIfWrong } from './swap-ranges-greater';
import { tenantBackporting } from './tenantBackporting';
import { titleDivisionPlanDueDateText, dwellingBuildContractDueDateText } from './dueDateText';
import { softFormatAud } from './softFormatAud';

export const fieldGroupFnDirectory: {[functionName:string]: FieldGroupFn | undefined} = {
  advertRestrict,
  partyResetActivity,
  purchasePriceGst,
  splitPartySignerType,
  purchaserAddrSameAsPrimary,
  saaSearchCostBind,
  setOneFeeMode,
  swapFixedFeeRangeIfWrong,
  swapCommissionFeeRangeIfWrong,
  tenantBackporting,
  titleDivisionPlanDueDateText,
  dwellingBuildContractDueDateText,
  softFormatAud
};
