import { LinkBuilder } from '../util/LinkBuilder';
import { SessionInfo } from '@property-folders/contract/rest/auth';
import { WrappedFetch } from './wrappedFetch';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { StatusCodeError } from './statusCodeError';
import { calcExpiryMode, cleanupUser, ExpiryMode } from '../util/user-cleanup-common';
import { OfflineSession } from '../offline/offlineSession';
import { throttle } from 'lodash';

class InvalidSessionError extends Error {
  constructor() {
    super('Invalid Session');
  }
}

interface ITryGetSessionInfoResult {
  status: 'success' | 'invalid' | 'ignore-error';
  session?: SessionInfo;
}

interface ILoggingOnSessionInfo {
  session?: SessionInfo;
  expiryMode?: ExpiryMode;
}

export type PortalType =
  | 'purchaser';

type RestApiFn = (path: string) => string;

export class AuthApi {
  private static async getSessionInfo() {
    return await WrappedFetch.json<SessionInfo>(LinkBuilder.restApi('/auth/session/info'));
  }

  private static async getPortalSessionInfo(restApi: RestApiFn, token: string | undefined) {
    const qs = LinkBuilder.buildQueryString({
      token
    });
    return await WrappedFetch.json<SessionInfo>(restApi(`/auth/session/info?${qs}`));
  }

  private static async tryGetSessionInfo(): Promise<ITryGetSessionInfoResult> {
    try {
      return {
        status: 'success',
        session: await AuthApi.getSessionInfo()
      };
    } catch (err: unknown) {
      if (err instanceof StatusCodeError && err.status === 403) {
        return {
          status: 'invalid'
        };
      }
      return {
        status: 'ignore-error'
      };
    }
  }

  private static async tryGetPortalSessionInfo(restApi: RestApiFn, token: string | undefined): Promise<ITryGetSessionInfoResult> {
    try {
      return {
        status: 'success',
        session: await AuthApi.getPortalSessionInfo(restApi, token)
      };
    } catch (err: unknown) {
      if (err instanceof StatusCodeError && err.status === 403) {
        return {
          status: 'invalid'
        };
      }
      return {
        status: 'ignore-error'
      };
    }
  }

  private static async getLoggingOnSessionInfo(): Promise<ILoggingOnSessionInfo> {
    const offline = await OfflineSession.read();
    const online = await this.tryGetSessionInfo();

    if (online.status === 'invalid') {
      if (offline && 'agentId' in offline) {
        await cleanupUser(offline.agentId, offline.agentUuid);
      }
      await OfflineSession.clearCurrentSessionPointer();
      throw new InvalidSessionError();
    }

    if (online.status === 'ignore-error' || !online.session) {
      return {
        session: offline,
        expiryMode: calcExpiryMode(offline)
      };
    }

    if ('agentId' in online.session) {
      await OfflineSession.write(online.session);
    } else {
      await OfflineSession.clearCurrentSessionPointer();
    }
    return { session: online.session };
  }

  public static useLoggingOnSessionInfo() {
    const { data: offlineData } = useQuery(['session', 'get', 'offline'], OfflineSession.read);
    return useQuery(['session', 'get', 'logging-on'], async () => {
      return await this.getLoggingOnSessionInfo();
    }, {
      initialData: { session: offlineData },
      retry: (failCount, error) => {
        if (failCount >= 3) {
          return false;
        }
        // if the user's definitely not logged in, then don't retry!
        if (error instanceof InvalidSessionError) {
          return false;
        }
        return true;
      }
    });
  }

  public static useGetAgentSessionInfo() {
    return useQuery(['session', 'get', 'offline'], OfflineSession.read);
  }

  public static usePortalSessionInfo(portalType: PortalType, restApi: RestApiFn, token: string | undefined) {
    return useQuery(['session', 'get', portalType, token || ''], async () => {
      return await this.tryGetPortalSessionInfo(restApi, token);
    }, {
      retry: (failCount, error) => {
        if (failCount >= 3) {
          return false;
        }

        if (error instanceof InvalidSessionError) {
          return false;
        }

        return true;
      }
    });
  }

  public static invalidatePortalSessionInfo(client: QueryClient, portalType: PortalType) {
    return client.invalidateQueries(['session', 'get', portalType]);
  }

  public static useIsCurrentSessionSalesperson(linkedSalespersonId?: string | number) {
    const {
      data: sessionInfo
    } = AuthApi.useGetAgentSessionInfo();
    const agentId = sessionInfo && 'agentId' in sessionInfo
      ? sessionInfo.agentId
      : undefined;

    if (!agentId) {
      return undefined;
    }

    return linkedSalespersonId
      ? typeof linkedSalespersonId === 'string'
        // future: is this gonna be a uuid?
        ? agentId === parseInt(linkedSalespersonId, 10)
        : linkedSalespersonId === agentId
      : undefined;
  }

  public static potentiallyInvalidateSessionInfoQueries = throttle((queryClient: QueryClient) => {
    console.log('potentiallyInvalidateSessionInfoQueries');
    AuthApi.tryGetSessionInfo()
      .then(result => {
        if (result.status !== 'invalid') return;

        console.log('invalidating session info queries');
        queryClient.invalidateQueries({
          queryKey: ['session', 'get']
        });
      });
  }, 10000);
}
