import transparentPixel from '@property-folders/common/assets/transparent-pixel.js';
import logoSocietyOfAuctioneersAndAppraisersSaInc
  from '@property-folders/common/assets/logo-society-of-auctioneers-and-appraisers-sa-inc.js';
import * as PdfGen from '@property-folders/common/util/pdfgen';
import { documentSubTitle, fieldsSpacing, generateCheckboxText, itemSubsection } from '@property-folders/common/util/pdfgen';
import { sectionTitles as st } from '@property-folders/common/util/pdfgen/doc-constants/sales-agency-agreement';
import { partySection } from '@property-folders/common/util/pdfgen/sections/vendorSection';
import { agentSection } from '@property-folders/common/util/pdfgen/sections/agentSections';
import { saleMethodSection, saleSection } from '@property-folders/common/util/pdfgen/sections/saleSection';
import { particularsSection } from '@property-folders/common/util/pdfgen/sections/particularsSection';
import { feeSection } from '@property-folders/common/util/pdfgen/sections/feeSection';
import { professionalFeeSection } from '@property-folders/common/util/pdfgen/sections/professionalFeeSection';
import {
  generalConditionsForResidentialSalesAgencyAgreement
} from '@property-folders/common/util/pdfgen/sections/saaGeneralConditions';
import { clausesSection } from '@property-folders/common/util/pdfgen/sections/clausesSection';
import reaformsLogo from '@property-folders/common/assets/reaforms-logo.js';
import { AuthorityParty, FormCode, LandType, SigningPartySourceType } from '@property-folders/contract';
import { buildSignatureSection } from '@property-folders/common/util/pdf-worker/buildSignatureSection';
import { PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import { generateStatutoryFeeDescObject } from '@property-folders/common/util/pdfgen/display-transformations';
import { saaGeneralConditionsForm1 } from '../../sections/saaGeneralConditionsForm1';
import {
  StandardReaformsTransactionFeeCents
} from '@property-folders/contract/yjs-schema/model/prefill/residential';

export function residentialSalesAgreement({
  property,
  signers,
  formInstance,
  brand,
  objects,
  annexures,
  printTitle,
  memberEntities
}: PdfWorkerDocumentDefinition) {
  const signatureSection = buildSignatureSection(signers, formInstance);
  const title = (printTitle??'Sales Agency Agreement') + (property?.landType ?? 0 > 0 ? ` – ${Object.keys(LandType).find(k => LandType[k] === property?.landType ?? 0)}` : '');

  const partyMap = new Map<SigningPartySourceType, AuthorityParty[]>();
  partyMap.set(SigningPartySourceType.Vendor, property?.vendors ?? []);

  // The section titles are all upper case, but we want this text to be 'normal' as it is also
  // used in other places.
  let vendorPartyLabel = st.vendors.title.toLocaleLowerCase();
  vendorPartyLabel = vendorPartyLabel[0].toLocaleUpperCase() + vendorPartyLabel.slice(1);

  const pricing = generateStatutoryFeeDescObject();

  const {
    agent,
    authAssoc,
    assocAgents,
    agencyExclusive,
    agency,
    otherAgents,
    prevAgentEnable,
    prevAgentConfirm,
    prevAgents,
    prevAgentExpire,
    prevAgentNotice,
    agentAuthority
  } = property || {};
  let { form1AndSearches } = property || {};

  if (form1AndSearches == null) {
    form1AndSearches = {
      whoPays: 'vendor',
      whoSearches: undefined
    };
  } else if (form1AndSearches.whoPays == null) {
    form1AndSearches.whoPays = 'vendor';
  }
  const transactionFeeAmountCents = (property?.transactionFee?.transactionFeeAmountCents ?? StandardReaformsTransactionFeeCents) / 100;
  const transactionFeeAmount = transactionFeeAmountCents.toFixed(2);
  const shownTransactionFeeAmountCents = transactionFeeAmountCents - (property?.transactionFee?.searchCreditAmountCents ?? 0);
  const shownTransactionFeeAmount = shownTransactionFeeAmountCents.toFixed(2);
  const searchCreditsCents = property?.transactionFee?.searchCreditAmountCents ?? 0;
  const searchCredits = searchCreditsCents > 0 ? (searchCreditsCents / 100).toFixed(2) : '0';

  let form1AndSearchesOption: keyof typeof saaGeneralConditionsForm1.agent | keyof typeof saaGeneralConditionsForm1.vendor = 'Neither';
  switch (form1AndSearches.whoSearches) {
    case 'eckermanns':
      form1AndSearchesOption = 'Eckermanns'; break;
    case 'vendor':
      form1AndSearchesOption = 'Vendor'; break;
    case 'agent':
      form1AndSearchesOption = 'Agent'; break;
  }

  const form1AndSearchesText = saaGeneralConditionsForm1[form1AndSearches.whoPays][form1AndSearchesOption];
  return ({
    pageSize: 'A4',
    pageOrientation: 'portrait',
    images: {
      agencyLogo: objects?.agencyLogoImage || transparentPixel,
      societyAuctioneersLogo: logoSocietyOfAuctioneersAndAppraisersSaInc
    },
    content: [
      ...PdfGen.documentTitle(title, 1, brand, false),
      { text: 'The Vendor agrees to appoint the Agent, and the Agent accepts the appointment, on the terms set out in this Agreement.', margin: [0, 8, 0, 0], style: 'detailText' },
      documentSubTitle('SCHEDULE', { margin: [0,24,0,12] }),
      ...PdfGen.propertySection({
        itemNo: st.properties.num,
        addresses: Object.values(property?.saleAddrs ?? []),
        titles: property?.saleTitles ?? [],
        titleDivision: property?.titleDivision ?? {},
        annexures,
        divisionPlanOptional: true
      }),
      ...partySection(
        st.vendors.num,
        Object.values(property?.vendors ?? []),
        property?.saleAddrs,
        property?.primaryVendor,
        vendorPartyLabel,
        false,
        false,
        'vendors'
      ),
      ...agentSection(st.agent.num,
        agent || [],
        authAssoc,
        assocAgents,
        agencyExclusive,
        agency,
        otherAgents,
        prevAgentEnable,
        prevAgentConfirm,
        prevAgents,
        prevAgentExpire,
        prevAgentNotice,
        agentAuthority,
        { memberEntities }
      ),
      ...saleSection(st.sale.num,
        property?.sale,
        property?.compareAddrs,
        property?.disableCompareAlreadyProvided,
        { annexureRef: property?.comparableSales?.annexureRef?.['family_'+FormCode.RSAA_SalesAgencyAgreement], annexures }
      ),
      ...saleMethodSection(st.saleMethod.num, property?.sale?.saleMethod, property?.sale?.saleMethodOther, property?.auction),
      ...particularsSection(
        st.particulars.num,
        property?.chattels,
        property?.exclusions,
        property?.encroach,
        property?.notiWorks,
        property?.specialTerms,
        property?.cgtEnable,
        property?.cgtPerson,
        property?.gstWithholdEnable,
        property?.pool,
        property?.vendorGst,
        property?.saaTenant,
        property?.vendorWorksEnable,
        property?.vendorWorks,
        property?.encroachEnable,
        property?.notiWorksEnable,
        property?.specialTermsEnable,
        property?.contractPrice?.inputTaxed,
        {
          saaGstUnknowns: property?.saaGstUnknowns,
          tenantList: property?.tenantsCollect,
          settlement: property?.contractSettlement
        }
      ),
      ...feeSection(
        st.marketing.num,
        property?.marketingTemplate,
        property?.marketingFees,
        property?.pressBudget,
        property?.adminFee,
        property?.sale?.saleMethod,
        property?.auctionFee,
        property?.benefits,
        brand,

        undefined /*property?.transactionFee?.waiveFee
          ? undefined
          : property?.transactionFee?.whoPays === 'agent'
            ? '$0.00'
            : property?.transactionFee?.enabled && property.form1AndSearches?.whoSearches !== 'eckermanns'
              ? `$${shownTransactionFeeAmount}`
              : undefined*/, // nil
        { form1AndSearches, marketingFeesOptions: property?.marketingFeesOptions, annexures, formCode: FormCode.RSAA_SalesAgencyAgreement, auctionFeeSwitches: property?.auctionFeeSwitches },
        { isVariation: false }
      ),
      ...professionalFeeSection(
        st.professionalFee.num,
        property?.marketingFees,
        property?.pressBudget,
        property?.searchFees,
        property?.professFee,
        property?.uplift,
        property?.adminFee,
        property?.sale?.saleMethod,
        property?.auctionFee,
        property?.payTerm
      ),
      {
        text: '',
        style: 'sectionTitle'
      },
      ...clausesSection(9, property?.clauses ?? [], brand),
      ...generalConditionsForResidentialSalesAgencyAgreement(brand, {
        form1AndSearches: form1AndSearchesText,
        showTransactionFeeCreditNotice: property?.transactionFee?.whoPays === 'agent',
        transactionFeeAmount,
        hasSearchCredits: searchCreditsCents > 0,
        searchCreditAmount: searchCredits
      }),
      {
        unbreakable: false,
        stack: [
          { text: PdfGen.format('\n\n*EXECUTED* as an agreement') },
          ...signatureSection
        ],
        margin: [0,0,0,4*fieldsSpacing]
      },
      itemSubsection({
        subsectionTitle: 'Acknowledgement',
        subsectionContent: [{
          margin: [0, fieldsSpacing, 0, 0],
          text: 'The Vendor acknowledges receipt of the following from the Agent prior to entering into this Agreement:'
        },{
          stack: [
            { text: generateCheckboxText('Form R1 ‘Sales Agency Agreements - Rights & obligations of vendor’', true) },
            { text: generateCheckboxText('Details of comparable sales ', true) }
          ],
          margin: [10, 10, 0, 10]
        },{
          text: 'The Vendor agrees to receive a fully signed copy of this Agreement from the Agent within 48 hours of it being signed by the Vendor.'
        }]
      })
    ],
    pageMargins: (currentPage: number) => PdfGen.standardPageMargins(currentPage, false),
    footer: (currentPage: number, pageCount: number) => PdfGen.standardFooter(currentPage, title, false, pageCount),
    header: (currentPage: number) => PdfGen.standardHeader(currentPage, title, false, reaformsLogo, brand.agencyContact)
  });
}
