// https://auspost.com.au/content/dam/auspost_corp/media/documents/australia-post-addressing-standards-1999.pdf
export class AbbreviationExpander {
  static StreetType(abbreviation: string): string {
    switch (abbreviation) {
      case 'ALLY':
        return 'Alley';
      case 'ARC':
        return 'Arcade';
      case 'GR':
        return 'Grove';
      case 'HWY':
        return 'Highway';
      case 'AVE':
        return 'Avenue';
      case 'LANE':
        return 'Lane';
      case 'BVD':
        return 'Boulevard';
      case 'PDE':
        return 'Parade';
      case 'CL':
        return 'Close';
      case 'PL':
        return 'Place';
      case 'CT':
        return 'Court';
      case 'RD':
        return 'Road';
      case 'CRES':
        return 'Crescent';
      case 'SQ':
        return 'Square';
      case 'DR':
        return 'Drive';
      case 'ST':
        return 'Street';
      case 'ESP':
        return 'Esplanade';
      case 'TCE':
        return 'Terrace';
    }

    return abbreviation;
  }

  static SubUnitType(abbreviation: string): string {
    switch (abbreviation) {
      case 'APT':
        return 'Apartment';
      case 'SHOP':
        return 'Shop';
      case 'FY':
        return 'Factory';
      case 'SITE':
        return 'Site';
      case 'F':
        return 'Flat';
      case 'SL':
        return 'Stall';
      case 'MB':
        return 'Marine berth';
      case 'SE':
        return 'Suite';
      case 'OFF':
        return 'Office';
      case 'U':
        return 'Unit';
      case 'RM':
        return 'Room';
      case 'VLLA':
        return 'Villa';
      case 'SHED':
        return 'Shed';
      case 'WE':
        return 'Warehouse';
    }

    return abbreviation;
  }

  static FloorType(abbreviation: string): string {
    switch (abbreviation) {
      case 'B':
        return 'Basement';
      case 'FL':
        return 'Floor';
      case 'G':
        return 'Ground Floor';
      case 'L':
        return 'Level';
      case 'LG':
        return 'Lower Ground Floor';
      case 'M':
        return 'Mezzanine';
      case 'UG':
        return 'Upper Ground Floor';
    }

    return abbreviation;
  }

  static State(abbreviation: string): string {
    switch (abbreviation) {
      case 'ACT':
        return 'Australian Capital Territory';
      case 'NSW':
        return 'New South Wales';
      case 'NT':
        return 'Northern Territory';
      case 'QLD':
        return 'Queensland';
      case 'SA':
        return 'South Australia';
      case 'TAS':
        return 'Tasmania';
      case 'VIC':
        return 'Victoria';
      case 'WA':
        return 'Western Australia';
    }

    return abbreviation;
  }

  static PostalDeliveryType(abbreviation: string): string {
    switch (abbreviation) {
      case 'CARE PO':
        return 'Care of Post Office';
      case 'CMA':
        return 'Community Mail Agent.';
      case 'CMB':
        return 'Community Mail Bag';
      case 'CPA':
        return 'Community Postal Agent';
      case 'GPO BOX':
        return 'General Post Office Box';
      case 'LOCKED BAG':
        return 'Locked Bag';
      case 'MS':
        return 'Mail Service';
      case 'PO BOX':
        return 'Post Office Box';
      case 'RSD':
        return 'Roadside Delivery';
      case 'RMB':
        return 'Roadside Mail Box/Bag';
      case 'RMS':
        return 'Roadside Mail Service';
      case 'PRIVATE BAG':
        return 'Private Bag';
    }

    return abbreviation;
  }

}
