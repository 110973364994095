import transparentPixel from '@property-folders/common/assets/transparent-pixel.js';
import logoSocietyOfAuctioneersAndAppraisersSaInc from '@property-folders/common/assets/logo-society-of-auctioneers-and-appraisers-sa-inc.js';
import * as PdfGen from '@property-folders/common/util/pdfgen';
import { calculateFinalPrice2
} from '@property-folders/common/util/pdfgen/sections/contract-price';
import reaformsLogo from '@property-folders/common/assets/reaforms-logo.js';
import { AuthorityParty, SigningPartySourceType } from '@property-folders/contract';
import { Predicate } from '@property-folders/common/predicate';
import { PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import { buildSignatureSection } from '@property-folders/common/util/pdf-worker/buildSignatureSection';
import { generatePartiesSummaryString } from '@property-folders/common/util/pdfgen/sections/statement-of-parties';
import { generateSignedLineageHistoryString } from '@property-folders/common/util/pdfgen/sections/statement-of-previous-agreements';
import { terminationDepositSection } from '@property-folders/common/util/pdfgen/sections/termination-deposit';
import { formatBI } from '@property-folders/common/util/pdfgen/formatBI';
import { formatAct } from '../../formatters/clauses';
import { LegalJurisdiction } from '../../constants';

export function contractSaleTerminationMutual({ property, propertyRaw, signers, objects, brand, formInstance, clausesId, annexures, snapshotHistory, lastSignedSnapData }: PdfWorkerDocumentDefinition) {

  const useCoverPage = false;
  const title = 'Mutual Termination of Contract of Sale';
  const partyMap = new Map<SigningPartySourceType, AuthorityParty[]>();
  partyMap.set(SigningPartySourceType.Vendor, property?.vendors ?? []);
  partyMap.set(SigningPartySourceType.Purchaser, property?.purchasers ?? []);

  const signatureSection = buildSignatureSection(signers, formInstance);

  const previouslySignedString = generateSignedLineageHistoryString(snapshotHistory, lastSignedSnapData);

  const { inputTaxed } = property?.contractPrice ?? {};
  let { finalPriceDisplay } = calculateFinalPrice2(property?.vendorGst, property?.contractPrice);
  if (finalPriceDisplay && Predicate.boolFalse(inputTaxed)) finalPriceDisplay = finalPriceDisplay + ' (Including GST)';
  const names = generatePartiesSummaryString(signers);

  return ({
    pageSize: 'A4',
    pageOrientation: 'portrait',
    images: {
      agencyLogo: objects?.agencyLogoImage || transparentPixel,
      societyAuctioneersLogo: logoSocietyOfAuctioneersAndAppraisersSaInc
    },
    content: [
      ...PdfGen.documentTitle(title, 1, brand, false),
      {
        stack: PdfGen.spaceStackLinesSideEffect([
          { text: 'BETWEEN:', style: 'sectionDetailSubTitle' },
          { text: [names, { text: ' (‘' }, { text: 'Parties', bold: true }, { text: '’)' }] }
        ]),
        margin: [0,2*PdfGen.fieldsSpacing,0,2*PdfGen.fieldsSpacing]// documentTitle has no bottom margin, so we add top margin here here
      },
      {
        stack: PdfGen.spaceStackLinesSideEffect([
          { text: 'RECITALS:', style: 'sectionDetailSubTitle' },
          { text: [
            previouslySignedString??'',
            { text: '(‘' },
            { text: 'Contract', bold: true },
            { text: '’)' },
            '.'
          ] },
          { text: 'In consideration of each party forbearing rights under the Contract, the Vendor and the Purchaser agree to terminate the Contract subject to the terms below and the parties will not take any action or have any rights of action against the other whatsoever with respect to the terms of the Contract or any breaches arising from the Contract. ' }
        ]),
        margin: [0,0,0,2*PdfGen.fieldsSpacing]
      },
      terminationDepositSection(property?.contractTermination),
      {
        stack: PdfGen.spaceStackLinesSideEffect([
          { text: 'COUNTERPARTS', style: 'sectionDetailSubTitle' },
          { text: formatBI('This Document may be executed in any number of counterparts, and this has the same effect as if the signatures on the counterparts were on a single copy of this Document. Without limiting the foregoing, if the signatures on behalf of one party are on different counterparts, this shall be taken to be, and have the same effect as, signatures on the same counterpart and on a single copy of this Document.') },
          { text: 'Electronic signing and communications'.toLocaleUpperCase(), style: 'sectionDetailSubTitle' },
          { text: formatBI(`The Parties acknowledge and agree to some or all of them or their attorneys or representatives signing this Document and any other documents by digital and/or electronic signatures, together with authorising all communications relating to the sale of the Property (including the service of any notices under the Act) to be completed by electronic means via the party’s email address or mobile phone number specified in this Document or provided to the other party from time to time, in accordance with the ${formatAct(LegalJurisdiction.SouthAustralia, 'ElectronicCommunicationsAct2000')} and the ${formatAct(LegalJurisdiction.Commonwealth, 'ElectronicTransactionsAct1999')}.`) }
        ])
      },
      {
        unbreakable: false,
        stack: [
          { text: PdfGen.format('\n\n*EXECUTED* as an agreement') },
          ...signatureSection
        ]
      }
    ],
    pageMargins: (currentPage: number) => PdfGen.standardPageMargins(currentPage, useCoverPage),
    footer: (currentPage: number, pageCount: number) => PdfGen.standardFooter(currentPage, title, useCoverPage, pageCount),
    header: (currentPage: number) => PdfGen.standardHeader(currentPage, title, useCoverPage, reaformsLogo, brand.agencyContact)
  });

}
