import {
  AgencySalesperson,
  AuthorityParty,
  FormInstance,
  MaterialisedPropertyData,
  SigningAuthorityType,
  SigningPartySourceType,
  SigningPartySourceTypeToStringPrint,
  SigningSessionFieldType,
  sourceTypeGroup
} from '@property-folders/contract';
import { Content } from 'pdfmake/interfaces';
import { FileStorage } from '../../../offline/fileStorage';
import { v4 } from 'uuid';
import { Agent, FormCodeUnion, InstanceHistory, PartyType, SignerProxyType, SigningParty, SigningSessionField, SourceRepresentationLevel } from '@property-folders/contract/property';
import { buildSignatureSection } from '../../pdf-worker/buildSignatureSection';
import { Predicate } from '../../../predicate';
import { FormUtil } from '../../form';
import { isSubscriptionForm } from '../../../subscription-forms/isSubscriptionForm';
import { BelongingEntityMeta } from '../../../redux-reducers/entityMeta';

// needs SigningProxySnapshotAddon
export interface ISignatureSectionSigner {
  /**
   * If signing has not occurred,
   * then this id should be placed somewhere so the signing system knows where to inject a signature
   */
  placeholderId?: string;
  /**
   * source party ID, used to identify signers as part of the same party. Note that IDs may not match
   * between preview and final document. Further checks and likely adjustments will need to be made
   * to use it for this purpose
   */
  originalPartySourceId?: string
  /**
   * Source hierarchy, to decode the nested list of vendors
   */
  sourceHierarchy?: SourceRepresentationLevel[]
  /**
   * ID of the signing.party signer
   */
  partySignerId?: string
  /**
   * Phrase varies based on the name and capacity of the signer
   */
  signingPhrase?: string;
  /**
   * Name of signatory
   */
  signingPartyIdentifier: string;
  /**
   * Name of represented party, or self
   */
  partyIdentifier?: string
  /**
   * If signing has occurred, this is the image
   */
  image?: Blob | string;
  /**
   * If signing has occurred, this is when it occurred.
   * Should be ms since epoch, e.g. from Date.now()
   */
  timestamp?: number;
  /**
   * Identifies what sort of party they are, eg Agent, Vendor, Purchaser. Not symbolic, printed
   * directly
   */
  partyType?: string
  partyTypeEnum?: SigningPartySourceType
  partyTypeDataModel?: PartyType
  /**
   * Flag the party as being removed
   */
  removing?: boolean
  /**
   * Flag the party as existing in the most recent snapshot. A non nullish value indicates that a
   * snapshot has been used for calculation
   */
  added?: boolean
  authority?: SigningAuthorityType
  /**
   * If not self identified, explains signatory type. eg Director, Secretary, Attorney
   */
  signerPartyAuthorityDisplay?: string,
  /**
   * If we have a proxy type, this was the original signer name.
   * copied from signingPartyIdentifier
   */
  proxyForOriginalIdentifier: string,
  /**
   * The authority by which the proxy signed
   */
  proxyAuthority: SignerProxyType
}
type PartySigningExtension = {partyTypeLabel: string, partyTypeEnum: SigningPartySourceType};
export type AgentWithAgency = AgencySalesperson & {agency: Agent};

type SignersFormInstanceSubset = {
  signing: {
    parties?:SigningParty[],
    session?: {
      fields?: SigningSessionField[]
    }
  }
};

export class SignatureSection {
  public static build(signers: ISignatureSectionSigner[], previousInstance?: FormInstance): Content[] {
    return buildSignatureSection(signers, previousInstance);
  }

  public static async buildSignersFromSigningSession(instance: SignersFormInstanceSubset, property: MaterialisedPropertyData, previousInstance?: FormInstance) {
    const parties = instance.signing?.parties || [];
    const partySnapshots = new Map(parties.map(party => [party.id, {
      snapshot: party.snapshot,
      sourceHierarchy: party.source.representationHierarchy,
      type: party.source.type,
      removing: party.source.isRemoving,
      added: party.source.added,
      proxy: {
        name: party.proxyName,
        authority: party.proxyAuthority
      }
    }]));

    const fields = instance.signing?.session?.fields || [];
    const signatures = fields.filter(field => field.type === SigningSessionFieldType.Signature);

    const signers: ISignatureSectionSigner[] = [];
    for (const signature of signatures) {
      const { snapshot, type, removing, added, proxy, sourceHierarchy } = partySnapshots.get(signature.partyId) ?? {};

      if (!snapshot) {
        return [];
      }

      const proxyMode = !!(Predicate.proxyNotSelf(proxy?.authority) && proxy.name);
      const proxyAdd = proxyMode ? {
        proxyForOriginalIdentifier: snapshot.signingPartyIdentifier,
        proxyAuthority: proxy.authority
      } : {};

      const possiblySourceData = sourceTypeGroup.get(type??SigningPartySourceType.Error) === SigningPartySourceType.Vendor
        ? property?.vendors?.find(v => v.id === snapshot.originalPartySourceId)
        : sourceTypeGroup.get(type??SigningPartySourceType.Error) === SigningPartySourceType.Purchaser
          ? property?.purchasers?.find(v => v.id === snapshot.originalPartySourceId)
          : undefined;
      signers.push({
        ...proxyAdd,
        placeholderId: signature.id,
        originalPartySourceId: snapshot.originalPartySourceId,
        partySignerId: signature.partyId,
        sourceHierarchy,
        signingPartyIdentifier: proxyMode ? proxy.name : snapshot.signingPartyIdentifier,
        partyIdentifier: snapshot.partyIdentifier,
        signingPhrase: snapshot.filledSigningPhrase,
        image: signature.file?.id
          ? (await FileStorage.read(signature.file.id))?.data
          : undefined,
        timestamp: signature.timestamp,
        partyType: SigningPartySourceTypeToStringPrint(type??SigningPartySourceType.Error),
        partyTypeEnum: type,
        partyTypeDataModel: possiblySourceData?.partyType,
        removing,
        added,
        authority: possiblySourceData?.authority
      });
    }
    return signers;
  }

  public static async buildSignersForPreview({
    propertyData, previousInstance,formCode,instHistory, previousData, memberEntities
  }:{
    propertyData: MaterialisedPropertyData,
    previousData?: MaterialisedPropertyData | undefined,
    authorityParties: Map<SigningPartySourceType, AuthorityParty[]>,
    agents?: AgentWithAgency[],
    removedAuthorityParties?: Map<SigningPartySourceType, AuthorityParty[]>,
    removedAgents?: AgentWithAgency[],
    addedAuthorityParties?: Map<SigningPartySourceType, AuthorityParty[]>,
    previousInstance?: FormInstance,
    formCode: FormCodeUnion,
    instHistory?: InstanceHistory | undefined,
    memberEntities: BelongingEntityMeta
  }) {
    const fakeInstance: SignersFormInstanceSubset = { signing: {} };
    FormUtil.ensureSigningParties(fakeInstance.signing, formCode, { propertyData: propertyData, history: instHistory });

    const buildSigningField = FormUtil.buildSigningFieldForPartyNonCustomFactory(false);
    fakeInstance.signing.session = { fields: fakeInstance.signing.parties?.map(buildSigningField) };

    FormUtil.ensureSigningSession(
      fakeInstance.signing,
      {
        sessionId: v4(),
        baseFile: { contentType: 'application/octet-stream', id: v4() }, accompanyingFileIds: {}, initiator: { email: '', entity: { id: '', name: '' }, id: '', name: '' },
        memberEntities
      },
      { get: ()=>propertyData },
      {
        isSubscriptionForm,
        previousData
      }
    );

    const rVal = await this.buildSignersFromSigningSession(fakeInstance, propertyData, previousInstance);

    return await rVal;
  }
}
