import { Annexure, InstanceHistory } from '@property-folders/contract';
import {
  buildListHistory,
  determineReplacedRemovedLocked,
  processAnnexureHistory
} from '../dataExtract';
import { DataGeneration, DetermineReplacedRemovedLockedResult } from '../dataExtractTypes';

interface PreservedAnnexureOrderHistoryListResult {
  usingPrevious: boolean;
  annexures: DetermineReplacedRemovedLockedResult<Annexure>[];
  orderList: null | DetermineReplacedRemovedLockedResult<Annexure>[];
}

export function preservedAnnexureOrderHistoryList(annexuresForm: Annexure[], snapshotHistory: InstanceHistory | undefined): PreservedAnnexureOrderHistoryListResult {
  if (!snapshotHistory) {
    return { usingPrevious: false, annexures: annexuresForm.map(annex => ({ id: annex.id, data: annex, state: DataGeneration.Added })), orderList: null };
  }
  const listHistory = buildListHistory<Annexure>('', snapshotHistory, snapshotHistory.latestExpiry === true ? false : snapshotHistory.latestExpiry, processAnnexureHistory);
  const orderList = determineReplacedRemovedLocked('', listHistory, annexuresForm, DataGeneration.CarriedOver);

  if (!orderList) {
    return { usingPrevious: false, annexures: annexuresForm.map(annex => ({ id: annex.id, data: annex, state: DataGeneration.Added })), orderList: null };
  }
  return {
    usingPrevious: true,
    annexures: orderList.filter(item => [DataGeneration.CarriedOver, DataGeneration.Added, DataGeneration.Restored].includes(item.state)),
    orderList
  };
}
