import { Predicate } from '../../predicate';
import { ShortId } from './short-id';
import { SigningPortalInfo } from '@property-folders/services/lib/dal/ddb/signing';

export * from './short-id';

export const seoFriendlyDelimiter = '-';
const delimiterConsecutiveRunRegex = /-+/g;

export function seoFriendlySlug(uuid: string, nicetext?: string): string {
  const prefix = nicetext
    ? nicetext.split(/\s|[^a-zA-Z0-9]/)
      .filter(Predicate.isNotNull)
      .join(seoFriendlyDelimiter)
      .toLowerCase()
      // embrace the jank
      .substring(0, 100)
      .concat(seoFriendlyDelimiter)
      .replace(delimiterConsecutiveRunRegex, seoFriendlyDelimiter)
    : '';
  const shortId = ShortId.fromUuid(uuid);
  return prefix === seoFriendlyDelimiter
    ? shortId
    : `${prefix}${shortId}`;
}

export interface SeoFriendlySlugOptions {
  id: string,
  nicetext?: string
}

export function generateSigningLink(
  reaformsUrlBase: string,
  partyIsReaformsUser: boolean,
  remoteSessionId: string,
  property: SeoFriendlySlugOptions,
  document: SeoFriendlySlugOptions,
  party: SeoFriendlySlugOptions,
  reaformsPortalUrlBase: string,
  portal?: SigningPortalInfo
) {
  if (portal) {
    return `${reaformsPortalUrlBase}/${portal.portalType}/${seoFriendlySlug(portal.portalId, property.nicetext)}/sign/${remoteSessionId}`;
  }

  if (!partyIsReaformsUser) {
    return `${reaformsUrlBase}/sign/${remoteSessionId}`;
  }

  const propertySlug = seoFriendlySlug(property.id, property.nicetext);
  const documentSlug = seoFriendlySlug(document.id, document.nicetext);
  const partySlug = seoFriendlySlug(party.id, party.nicetext);
  return `${reaformsUrlBase}/properties/${propertySlug}/sign/${documentSlug}/as/${partySlug}`;
}
