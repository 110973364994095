import { QuadState } from '../types/QuadState';

export function quadStateFilter(mainBool: boolean | undefined, unknownBool: boolean | undefined) {
  return unknownBool
    ? QuadState.AssertUnknown
    : mainBool
      ? QuadState.True
      : mainBool === false
        ? QuadState.False
        : QuadState.NotSet;
}

export function quadStateBoolText(mainBool: boolean | undefined, unknownBool: boolean | undefined): string {
  if (unknownBool || typeof mainBool === 'undefined') return 'unknown';
  return mainBool
    ? 'yes'
    : 'no';
}
