import vendorEmailRequiredIfPrimary from './vendorEmailRequiredIfPrimary';
import { ComplexValidationFunction } from '@property-folders/contract/yjs-schema/model/complex-validator';
import { validateSigningRequirements, validateSigningRequirementsNoEmail } from './validate-signing-requirements';
import { settlementDatesGreaterEqThan } from './settlement-dates-greater-eq-than';

export const complexValidatorsDirectory: {[functionName:string]: ComplexValidationFunction | undefined} = {
  vendorEmailRequiredIfPrimary,
  validateSigningRequirements,
  validateSigningRequirementsNoEmail,
  settlementDatesGreaterEqThan
};
