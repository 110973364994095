import { Exclusions, Inclusions, PoolCompliance } from '@property-folders/contract';
import { fieldFocus, freeTextArea, itemSection, spaceStackLinesSideEffect } from '..';
import { chattelsSection, encroachSection, exclusionSection, gstWithholdingSection, notiWorksSection, poolSection, tenantSection, vendorWorksSection } from './particularsSection';
import { chattelsOptionsContract } from '../constants';
import { subsectionTitles } from '../doc-constants/sale-contract';

export function contractParticularsSection(
  itemNo: number,
  chattelObj: Inclusions | undefined,
  exclusionObj: Exclusions| undefined,
  tenant: object | undefined,
  encroach: string | undefined,
  notiWorks: string | undefined,
  vendorWorks: string | undefined,
  cgtEnable: boolean | undefined,
  gstWithholdEnable: boolean | undefined,
  poolCompliance: PoolCompliance | undefined,
  mattersAffectTitle: string | undefined,
  depositPayAt: string | undefined,
  encroachEnable: boolean | undefined,
  notiWorksEnable: boolean | undefined,
  vendorWorksEnable: boolean | undefined,
  moreData: {
    tenantList: TenantAgreement[] | undefined
  }
) {
  const sectionItems = [];

  sectionItems.push(...chattelsSection(chattelObj, chattelsOptionsContract, false, subsectionTitles.inclusions, true));
  sectionItems.push(...exclusionSection(exclusionObj, false, subsectionTitles.exclusions));
  sectionItems.push(...encroachSection({ enable: encroachEnable, content: encroach }));
  sectionItems.push(...notiWorksSection({ enable: notiWorksEnable, content: notiWorks }));
  sectionItems.push(...vendorWorksSection({ enable: vendorWorksEnable, content: vendorWorks }));
  sectionItems.push(...poolSection(poolCompliance?.present, poolCompliance?.complyState, poolCompliance?.nonComplyWorks, { contractMode: true }));
  sectionItems.push(...tenantSection(tenant, moreData.tenantList, true));

  sectionItems.push(...gstWithholdingSection({ gstWithholdEnable }, { contractMode: true }));

  sectionItems.push(freeTextArea({
    title: {
      stack: spaceStackLinesSideEffect([
        {
          text: [
            { text: 'Permitted Encumbrances', style: 'sectionSubTitle' }
          ]
        },
        { text: ['The Property is sold subject to the following Encumbrances (existing or intended to be created):'] }
      ])
    },
    content: mattersAffectTitle,
    linesIfEmpty: 3,
    fieldTitle: undefined,
    bookmark: ['subsection-title-matters', ...fieldFocus('contractSchedule.noForm1NoCoolMatters')]
  }));
  sectionItems.push( { text: 'including any Encumbrances detailed in the Form 1 as not being discharged at Settlement.' });

  return itemSection({ itemNo: itemNo, itemTitleParam: 'Property and Sale Particulars'.toUpperCase(), bookmark: 'bookmark_contractSchedule', stackContent: sectionItems });
}
