export const sectionTitles = {
  properties: { num: 1, title: 'PROPERTY' },
  vendors: { num: 2, title: 'VENDOR' },
  purchasers: { num: 3, title: 'PURCHASER' },
  gstSale: { num: 4, title: 'GST CONDITIONS OF SALE' },
  price: { num: 5, title: 'PURCHASE PRICE' },
  settlement: { num: 6, title: 'SETTLEMENT' },
  particulars: { num: 7, title: 'PROPERTY AND SALE PARTICULARS' },
  specialConditions: { title: 'Special Conditions' },
  otherNonClauseConditions: { title: 'Other Conditions' }
};

export const subsectionTitles = {
  inclusions: 'Inclusions',
  exclusions: 'Exclusions',
  tenant: 'Tenancies',
  encroach: 'Encroachments and fences not on boundaries',
  notiWorks: 'Alterations and improvements erected without consent',
  vendorWorks: 'Vendor’s Works',
  cgtWith: 'CGT withholding',
  gstWith: 'GST withholding at Settlement',
  pool: 'Pool compliance',
  titleMatters: 'Matters affecting title',
  financeSpecial: 'SC 1 FINANCE',
  purchaserPropertySaleSpecial: 'SC 2 SALE OF THE PURCHASERS PROPERTY'
};
