export const META_APPEND = 'formsMeta';

export enum PropertyRootKey {
  Meta = META_APPEND,
  Data = 'transactionRoot',
  FileTrack = 'transactionFileStateChannel'
}

// Used to distinguish those keys which are intended to not refer to
export enum MasterRootKey {
  Meta = META_APPEND,
  Data = 'transactionRoot'
}

export * from '../../property';
