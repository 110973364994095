import {
  DefaultTimeZone,
  FormInstance,
  MaterialisedPropertyData, saleMethodOptions,
  TransactionMetaData
} from '@property-folders/contract';
import { summariseAddressesOrTitles } from '../../../../yjs-schema/property';
import { getPrimaryParty } from '../../../dataExtract';
import { canonicalisers, formatTimestamp } from '../../../formatting';
import {
  coverPage,
  dateOfExpiryRelativeToCompleted,
  getDocumentTitle,
  standardFooter,
  standardHeader,
  standardPageMargins,
  timestampOfAgreement
} from '../../index';
import { Binder } from 'immer-yjs/src/immer-yjs';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import {
  propertyFolderMaskDataNotRelevant,
  transformPropertyFolderDataForDisplay
} from '../../display-transformations';
import { logoSocietyOfAuctioneersAndAppraisersSaInc, reaformsLogo, transparentPixel } from '../../logos';
import { FormTypes } from '../../../../yjs-schema/property/form';
import { GetterBinder } from '../../../../types/PDFDefinition';

export function getFormInstance(formCode: string, formId: string, meta: TransactionMetaData): FormInstance | undefined {
  const formDefn = FormTypes[formCode];
  const familyCode = formDefn?.formFamily;

  if (!familyCode) {
    return undefined;
  }

  const familyState = meta?.formStates?.[familyCode];
  if (!familyState?.instances?.length) {
    return undefined;
  }

  return familyState.instances.find(i => i.id === formId);
}

export function generateCoverPageDefinition({
  agencyLogoImage,
  title,
  propertyCover,
  property,
  agreementDateText,
  agency,
  agreementMs,
  timeZone,
  brand,
  useCoverPage
}: {
  agencyLogoImage: string,
  title: string,
  propertyCover: string,
  property: MaterialisedPropertyData,
  agreementDateText: string,
  agency: any,
  agreementMs: number,
  timeZone: string,
  brand: EntityBrandFormConfig,
  useCoverPage: boolean
}) {
  return ({
    pageSize: 'A4',
    pageOrientation: 'portrait',
    images: {
      agencyLogo: agencyLogoImage || transparentPixel,
      societyAuctioneersLogo: logoSocietyOfAuctioneersAndAppraisersSaInc
    },
    content: [
      coverPage(
        title,
        [
          { label: 'Property', value: propertyCover || '' },
          { label: 'Vendor (primary)', value: getPrimaryParty(property?.vendors, property?.primaryVendor)?.fullLegalName },
          { label: 'Salesperson', value: property?.agent?.[0]?.salesp?.[0]?.name },
          { label: 'Date of Agreement', value: agreementDateText },
          {
            label: 'Term', value: agency?.duration
              // days func actually accepts string | number, but compiler complains due to canonicalisers being a Record<string|func(string)>
              ? canonicalisers.days(agency.duration as string).display
              : ''
          },
          { label: 'Expiry', value: dateOfExpiryRelativeToCompleted(agreementMs, timeZone, agency?.duration) },
          {
            label: 'Method of Sale', value: property?.sale?.saleMethod === 'other'
              ? property?.sale?.saleMethodOther
              : property?.sale?.saleMethod
                ? saleMethodOptions[property.sale.saleMethod]
                : ''
          }
        ],
        brand
      )
    ],
    pageMargins: (currentPage: number) => standardPageMargins(currentPage, useCoverPage),
    footer: (currentPage: number, pageCount: number) => standardFooter(
      currentPage,
      title,
      useCoverPage,
      pageCount,
      false,
      `Controlled Fully signed on ${agreementDateText}`
    ),
    header: (currentPage: number) => standardHeader(currentPage, title, useCoverPage, reaformsLogo, brand.agencyContact)
  });
}

export function prepareData({
  brand,
  agencyLogoImage,
  dataBinder,
  metaBinder,
  formCode,
  formId,
  completedAtMs
}: {
  brand: EntityBrandFormConfig,
  agencyLogoImage: string,
  dataBinder?: Binder<MaterialisedPropertyData>,
  metaBinder?: Binder<TransactionMetaData>,
  formCode: string,
  formId: string,
  completedAtMs: number
}) {
  const useCoverPage = true;
  if (!(dataBinder && metaBinder)) {
    throw new Error('Cannot generate preview, data binders are not initialised');
  }

  const propertyRaw = propertyFolderMaskDataNotRelevant(dataBinder.get());
  const meta = metaBinder.get();
  const formMetadata = getFormInstance(formCode, formId, meta);

  if (!formMetadata) {
    throw new Error('Cannot generate preview for nonexistent form');
  }

  const property = transformPropertyFolderDataForDisplay(propertyRaw) as MaterialisedPropertyData;
  const title = getDocumentTitle('Sales Agency Agreement', property.landType);

  const propertyCover = summariseAddressesOrTitles(property);
  const timeZone = formMetadata.signing?.sessionInitiator?.timeZone || DefaultTimeZone;

  const agreementMs = timestampOfAgreement(completedAtMs, formMetadata.signing?.parties);
  const agreementDateText = formatTimestamp(agreementMs, timeZone, false);
  const { agency } = property || {};

  return {
    useCoverPage,
    property,
    title,
    propertyCover,
    timeZone,
    agreementMs,
    agreementDateText,
    agency,
    brand,
    agencyLogoImage
  };
}

export function saaCoverPage({
  dataBinder,
  metaBinder,
  formCode,
  formId,
  brand,
  completedAtMs,
  agencyLogoImage
}: {
  dataBinder: GetterBinder<MaterialisedPropertyData>,
  metaBinder: GetterBinder<TransactionMetaData>,
  formCode: string,
  formId: string,
  brand: EntityBrandFormConfig,
  completedAtMs?: number,
  agencyLogoImage?: string
}) {
  return generateCoverPageDefinition(
    prepareData({
      brand,
      agencyLogoImage,
      dataBinder,
      metaBinder,
      formCode,
      formId,
      completedAtMs
    })
  );
}
