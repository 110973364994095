import { Color, rgb } from 'pdf-lib';

export class ColourUtil {
  public static toRgbComponents(value: string): undefined | { r: number; g: number; b: number; } {
    if (value.startsWith('rgb')) {
      const match = /rgb\((\d+),(\d+),(\d+)\)/.exec(value);
      return match ? {
        r: parseInt(match[1], 10),
        g: parseInt(match[2], 10),
        b: parseInt(match[3], 10)
      }: undefined;
    }

    if (value.startsWith('#')) {
      if (value.length === 7) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(value);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : undefined;
      }

      if (value.length === 4) {
        const result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(value);
        return result ? {
          r: parseInt(result[1].repeat(2), 16),
          g: parseInt(result[2].repeat(2), 16),
          b: parseInt(result[3].repeat(2), 16)
        } : undefined;
      }
    }

    return undefined;
  }

  public static toPdfLibColor(value?: string): undefined | Color {
    if (!value) return undefined;
    const c = this.toRgbComponents(value);
    if (!c) return undefined;
    return rgb(c.r / 255, c.g / 255, c.b / 255);
  }
}
