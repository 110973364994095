import { PartyCategory } from '../../yjs-schema/property/form';

export interface ExtractedField {
  id: string;
  positions: {
    x: number;
    y: number;
    width: number;
    height: number;
    /**
     * index
     */
    page: number;
  }[];
  _debug?: Record<string, any>;
}

export interface PageDimension {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ImagePlacement {
  name: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

export enum ImagePartyType {
  Unknown = 0,
  Vendor = 1,
  Purchaser = 2,
  Agent = 3
}

export interface ImageEncodedSigningParty {
  type: PartyCategory,
  number: number
}

export interface ImagePagePlacement extends ImagePlacement{
  pageIndex: number;
  party?: ImageEncodedSigningParty;
}

export interface StaticTextPlacement {
  pageIndex: number,
  text: string,
  top: number,
  left: number,
  bottom: number,
  right: number,
  size: number
}
