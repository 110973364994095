import {
  SigningGeneralConfig,
  SigningParty,
  SigningPartyDeclineType,
  SigningPartySnapshot,
  SigningSessionFieldType, SigningSessionSubType
} from '../../property';
import { SigningMarketingData } from '../../sync';
import { z } from 'zod';

export interface ExpiredRemoteSigningSessionResult {
  status: 'expired';
  cssOverrides: Record<string,string>;
}

export interface GeneratingRemoteSigningSessionResult {
  status: 'generating';
}

export interface VoidRemoteSigningSessionResult {
  status: 'voided'
}

export interface ReplacedRemoteSigningSessionResult {
  status: 'replaced',
  id: string
}

export interface SmsVerificationRequiredSigningSessionResult {
  status: 'sms',
  phoneSuffix: string,
  requestCodeDisabledUntilMs?: number
}

export interface PendingRemoteSigningSessionPage {
  url: string;
  width: number;
  height: number;
}

export type FieldPosition = {
  /**
   * page index, not number
   */
  page: number;
  x: number;
  y: number;
  width: number;
  height: number;
};

export interface PendingRemoteSigningSessionFieldCustom {
  fontColour?: string;
  fontSize?: number;
  lineHeight?: number;
  fontFamily?: string;
  bg?: boolean;
  bgColour?: string;
  multiline?: boolean;
  radio?: {
    group: string;
  }
  check?: {
    group: string;
  },
  // initial value
  text?: string;
  required?: boolean;
}

export interface PendingRemoteSigningSessionField {
  /**
   * set if the party controls the field
   */
  id?: string;
  type: SigningSessionFieldType,
  subtype: SigningSessionSubType,
  partyInfo?: Partial<SigningPartySnapshot>;
  fieldPosition?: FieldPosition;
  imageUrl?: string;
  tsPosition?: FieldPosition;
  timestamp?: number;
  text?: string;
  witPosition?: FieldPosition;
  isWet?: boolean;
  // rough first cut, client-side will have to do some numeric scaling and defaulting
  custom?: PendingRemoteSigningSessionFieldCustom
}

export interface PendingRemoteSigningSessionAutoField {
  type: 'timestamp';
  position: FieldPosition;
  currentParty?: boolean;
  value?: number;
}

export interface PendingRemoteSigningSessionInitiator {
  id: number;
  name: string;
  timeZone: string;
  email: string;
  entity: {
    id: number;
    name: string;
    brand?: {
      button: {
        foregroundColour: string;
        backgroundColour: string;
      }
    },
    logoUrl?: string;
  }
}

export type RemoteSigningSessionDataField =
  | { type: 'ts-party'; value?: number; position: FieldPosition; };

export interface PendingRemoteSigningSessionResult {
  status: 'pending';
  cssOverrides: Record<string,string>;
  party: SigningParty;
  initiator: PendingRemoteSigningSessionInitiator;
  config: SigningGeneralConfig;
  pages: PendingRemoteSigningSessionPage[];
  fields: PendingRemoteSigningSessionField[];
  autos?: PendingRemoteSigningSessionAutoField[];
  dataFields?: RemoteSigningSessionDataField[];
  images: {
    signature?: string;
    initials?: string;
  };
  tandcAgreed?: boolean;
  voidable?: boolean;
  declineable?: boolean;
}

export interface SignedRemoteSigningSessionResult {
  status: 'signed';
  party: SigningParty;
  headline: string;
  marketingData?: SigningMarketingData;
  returnUrl?: string;
}

export interface DeclinedRemoteSessionResult {
  status: 'declined';
  initiator: PendingRemoteSigningSessionInitiator;
}

export type GetRemoteSigningSessionByIdResult =
  | ExpiredRemoteSigningSessionResult
  | SignedRemoteSigningSessionResult
  | PendingRemoteSigningSessionResult
  | DeclinedRemoteSessionResult
  | VoidRemoteSigningSessionResult
  | ReplacedRemoteSigningSessionResult
  | SmsVerificationRequiredSigningSessionResult
  | GeneratingRemoteSigningSessionResult;

export interface SigningImageDetail {
  data?: string;
  replace?: boolean;
}

export interface PostRemoteSigningSessionByIdParams {
  party: {
    fullName: string;
    initials: string;
    images: {
      signature?: SigningImageDetail;
      initials?: SigningImageDetail;
    }
  },
  fields: {
    id: string,
    timestamp: number,
    value?: string,
    text?: string
  }[]
}

export type PostRemoteSigningSessionByIdResult =
  | { status: 'completed', allSigned?: boolean, returnUrl?: string }
  | { status: 'expired', returnUrl?: string };

export interface GetRemoteSigningSessionInfoFromOtherIdsResult {
  remoteSessionId: string
}

export interface CurrentDocumentPreviewDocumentItem {
  url: string;
  fileName: string;
  parties: string[];
  isWet: boolean;
}

export interface CurrentDocumentPreviewResult {
  status: 'pending' | 'completed';
  // if pdf download is permitted, this will be set
  documents: CurrentDocumentPreviewDocumentItem[];
  counterpartMergedPdf?: string;
  historyPath?: string;
  certificatePath?: string;
  signalUrls?: {
    view: string;
    download: string;
  }
}

export interface ExpiredDocumentPreviewResult {
  status: 'expired';
}

export type GetDocumentPreviewByIdResult =
  | CurrentDocumentPreviewResult
  | ExpiredDocumentPreviewResult;

export const PutRemoteSigningSessionDeclineReasonRequestBodySchema = z.object({
  type: z.nativeEnum(SigningPartyDeclineType),
  reason: z.string().min(1)
});
export type PutRemoteSigningSessionDeclineReasonRequestBody = z.infer<typeof PutRemoteSigningSessionDeclineReasonRequestBodySchema>;
